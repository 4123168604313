import React from "react";
// Customizable Area Start
import { createTheme, styled, ThemeProvider } from "@material-ui/core/styles";
import OrderHistoryController, {
    configJSON,
    IOrderAttributes,
    Props,
} from "./OrderHistoryController.web";
import {
    Box,
    Typography,
    Button,
    Grid,
    Divider,
} from "@material-ui/core";
import WebHeader from "../../landingpage/src/WebHeader.web";
import WebFooter from "../../../components/src/Footer.web";
import BreadcrumbStepper from "../../../components/src/BreadcrumbStepper.web";
import GenericModal from "../../../components/src/GenericModal";
import { DialogWrapper } from "../../../blocks/customform/src/LegalInformation.web";

const theme = createTheme({
    overrides: {
        MuiTypography: {
            h1: {
                color: "#0F172A",
                fontFamily: "Lato",
                fontSize: "20px",
                fontWeight: 600,
                lineHeight: "28px",
            },
            h2: {
                color: "#1E293B",
                fontFamily: "Lato",
                fontSize: "20px",
                fontWeight: 600,
                lineHeight: "28px",
            },
            h3: {
                fontFamily: "Lato",
                fontSize: "20px",
                fontWeight: 600,
                color: "#000000",
                lineHeight: "28px",
            },
            h4: {
                fontFamily: "Lato",
                fontSize: "20px",
                fontWeight: 600,
                color: "#0F172A",
                lineHeight: "26px",
            },
            h6: {
                color: "#475569",
                fontFamily: "Lato",
                fontSize: "18px",
                fontWeight: 400,
                lineHeight: "26px",
            },
            subtitle1: {
                color: "#64748B",
                fontFamily: "Lato",
                fontSize: "14px",
                fontWeight: 500,
                lineHeight: "17px",
            },
            subtitle2: {
                color: "#0F172A",
                fontSize: "24px",
                fontFamily: "Lato",
                fontWeight: 700,
                lineHeight: "24px",
            },
            body1: {
                fontFamily: "Lato",
                fontSize: "16px",
                fontWeight: 600,
                lineHeight: "17px",
            },
            body2: {
                color: "#475569",
                fontSize: "18px",
                fontFamily: "Lato",
                fontWeight: 400,
                lineHeight: "26px",
            },
        },
        MuiButton: {
            text: {
                primary: {
                    color: "#9176C4",
                },
                secondary: {
                    color: "#DC2626",
                },
                textTransform: "none",
                textDecoration: "underline",
                textUnderlineOffset: "3px",
            }
        },
    },
});

const steps = [
    { label: 'Account', path: 'OrederHistory' },
    { label: 'My Orders', path: 'OrederHistory' },
];
// Customizable Area End

export default class OrderHistory extends OrderHistoryController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    renderOrderCard = (order: IOrderAttributes, index: number) => {
        const { selectedTab } = this.state;
        let product = order.product;

        return (
            <Box className="orderCard" key={order.id}>
                <Box className="orderDetails">
                    <Box my={2} className="textWrapper">
                        <Typography variant="subtitle1">{configJSON.orderIdLabel}</Typography>
                        <Typography variant="body1">{order.order_number}</Typography>
                    </Box>
                    <Box my={2} className="textWrapper">
                        <Typography variant="subtitle1">{configJSON.orderDateLabel}</Typography>
                        <Typography variant="body1">{this.getOrderDate(order.order_date)}</Typography>
                    </Box>
                    {selectedTab !== "cancelled" ? (
                        <Box my={2} className="textWrapper">
                            <Typography variant="subtitle1">{configJSON.expectedDeliveryLabel}</Typography>
                            <Typography variant="body1">{this.getOrderDate(order.schedule_time)}</Typography>
                        </Box>
                    ) : (
                        <Box className="textWrapper"></Box>
                    )}
                </Box>
                <Grid container spacing={2} className="productInfo">
                    <Grid item md={4} lg={3}>
                        <img
                            src={product.data.attributes.main_image}
                            alt="Product"
                            className="productImage"
                        />
                    </Grid>
                    <Grid item md={8} lg={9} className="productDescription">
                        <Grid container spacing={2}>
                            <Grid item md={8}>
                                <Typography variant="h4" style={{ marginBottom: "12px" }}>
                                    {product.data.attributes.product_name}
                                </Typography>
                                <Typography variant="body2" color="textSecondary" paragraph>
                                    {product.data.attributes.description}
                                </Typography>
                            </Grid>
                            <Grid item md={4} className="orderSummary">
                                <Box className="flexwrapBox">
                                    <Box className="orderSummary">
                                        <Typography variant="h2">{configJSON.totalCostLabel} ${order.total}</Typography>
                                    </Box>
                                    <Box className="orderSummary">
                                        <Typography variant="h2">{configJSON.quantityLabel} {order.quantity}</Typography>
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                        <Box className="actions">
                            <Button
                                variant="text"
                                color="primary"
                                onClick={() => this.handleProductNavigation(product.data.attributes.id, "ShowProduct")}
                            >
                                {configJSON.viewProductBtnText}
                            </Button>
                            {selectedTab !== "cancelled" && (
                                <>
                                    <Button
                                        variant="text"
                                        color="primary"
                                        onClick={() => this.showOrderDetails(index)}
                                        data-test-id="viewOrderDetailsBtn"
                                    >
                                        {configJSON.viewOrderDetailsBtnText}
                                    </Button>
                                    <Button 
                                        variant="text" 
                                        color="primary"
                                        data-test-id="invoiceBtn"
                                        onClick={() => this.handleInvoiceClick(index)}
                                    >
                                        {configJSON.invoiceBtnText}
                                    </Button>
                                    {selectedTab === "on_going" && (
                                        <Button
                                            variant="text"
                                            color="secondary"
                                            data-test-id="cancelOrderBtn"
                                            onClick={() => this.cancelOrder(order.id)}>
                                            {configJSON.cancelOrderBtnText}
                                        </Button>
                                    )}
                                    {selectedTab === "delivered" && (
                                        <Button
                                            variant="text"
                                            color="primary"
                                            onClick={() => this.handleProductNavigationcheckout(product.data.attributes.id, order)}
                                        >
                                            {configJSON.buyAgainBtnText}
                                        </Button>
                                    )}
                                </>
                            )}
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        );
    }
    // Customizable Area End

    render() {
        // Customizable Area Start
        const {
            activeTab,
            ordersData,
            openCancelDialog,
            openDetailsDialog,
            openInvoiceDialog,
            currentItemIndex,
        } = this.state;
        return (
            <>
                <ThemeProvider theme={theme}>
                    <Wrapper>
                        <WebHeader navigation={this.props.navigation} />
                        <Box className="container">
                            <Box mb={1}>
                                <BreadcrumbStepper steps={steps} onStepClick={(path) => this.handleNavigation(path)} />
                            </Box>
                            <Box mb={4}>
                                <Typography variant="h1" data-test-id="orderHistoryTitle">
                                    {configJSON.orderHistoryTitle}
                                </Typography>
                                <Typography variant="h6">
                                    {configJSON.orderHistorySubtitle}
                                </Typography>
                            </Box>
                            <Box className="tabs">
                                {["Ongoing", "Completed", "Cancelled"].map((tab) => (
                                    <Box
                                        key={tab}
                                        className={`tab ${activeTab === tab ? "active" : ""}`}
                                        onClick={() => this.handleTabClick(tab)}
                                        data-test-id={tab}
                                    >
                                        {tab}
                                    </Box>
                                ))}
                            </Box>
                            {ordersData.map((order, index) => (
                                this.renderOrderCard(order.attributes, index)
                            ))}
                        </Box>
                        <WebFooter handleNavigation={this.handleNavigation} />
                    </Wrapper>
                </ThemeProvider>
                <GenericModal open={openCancelDialog} data-test-id="confirmCancel" onClose={this.handleClose}>
                    <ThemeProvider theme={theme}>
                        <DialogWrapper>
                            <Box mb={4} className="alignCenter">
                                <Typography align="center" variant="h1" data-test-id="passwordUpdatedMsg">{configJSON.cancelDialogTitle}</Typography>
                                <Typography align="center" variant="h6" style={{ color: "#64748B", fontWeight: 400 }}>
                                    {configJSON.cancelDialogConfirmationText}
                                </Typography>
                            </Box>
                            <Box className="btnWrapper">
                                <Button
                                    fullWidth
                                    style={{ width: "100%", backgroundColor: "#DC2626" }}
                                    className="containedBtn"
                                    variant="contained"
                                    data-test-id="deleteBtn"
                                    onClick={() => this.handleCancelBtnClick()}
                                >
                                    {configJSON.cancelBtnText}
                                </Button>
                            </Box>
                        </DialogWrapper>
                    </ThemeProvider>
                </GenericModal>
                {ordersData.length > 0 && (
                    <>
                        <GenericModal open={openDetailsDialog} data-test-id="orderDetails" onClose={this.handleClose}>
                            <ThemeProvider theme={theme}>
                                <OrderDialogWrapper>
                                    <Box mb={4} className="alignCenter">
                                        <Typography align="center" variant="h1" className="dialogTitle" data-test-id="passwordUpdatedMsg">Order Details</Typography>
                                        <Box mt={3} className="detailsWrapper">
                                            <Box className="alignments">
                                                <Typography variant="h3">{configJSON.orderIdLabel}</Typography>
                                                <Typography variant="h6">{ordersData[currentItemIndex].attributes.order_number}</Typography>
                                            </Box>
                                            <Box className="alignments">
                                                <Typography variant="h3">{configJSON.orderDateLabel}</Typography>
                                                <Typography variant="h6">{this.getOrderDate(ordersData[currentItemIndex].attributes.order_date)}</Typography>
                                            </Box>
                                            <Box className="alignments">
                                                <Typography variant="h3">{configJSON.expectedDeliveryLabel}</Typography>
                                                <Typography variant="h6">{this.getOrderDate(ordersData[currentItemIndex].attributes.schedule_time)}</Typography>
                                            </Box>
                                            <Box className="alignments">
                                                <Typography variant="h3">{configJSON.totalCostLabel}</Typography>
                                                <Typography variant="h6">${ordersData[currentItemIndex].attributes.total}</Typography>
                                            </Box>
                                            <Divider />
                                            <Box>
                                                <Typography variant="h3">{configJSON.deliveryAddressLabel}</Typography>
                                                <Typography variant="h6">{this.getOrderAddress(ordersData[currentItemIndex].attributes.delivery_address)}</Typography>
                                            </Box>
                                        </Box>
                                    </Box>
                                </OrderDialogWrapper>
                            </ThemeProvider>
                        </GenericModal>
                        <GenericModal open={openInvoiceDialog} data-test-id="invoiceModal" onClose={this.handleClose}>
                            <ThemeProvider theme={theme}>
                                <DialogWrapper>
                                    <Box className="dialogContentWrapper">
                                        <Box className="dialogDescriptionWrapper">
                                            <Typography align="center" variant="h1" data-test-id="emailSentModalTitle">{configJSON.orderInvoiceTitle}</Typography>
                                        </Box>
                                        <Box className="dialogBtnsWrapper">
                                            <Button
                                                className="outlinedBtn"
                                                variant="outlined"
                                                onClick={() => this.handleProductNavigation(ordersData[currentItemIndex].attributes.id, "InvoiceBilling")}
                                                data-test-id="viewInvoiceBtn"
                                            >
                                                {configJSON.viewInvoiceBtnText}
                                            </Button>
                                            <Button
                                                className="containedBtn"
                                                variant="contained"
                                                onClick={() => this.downloadFile(ordersData[currentItemIndex].attributes.invoive_pdf)}
                                                data-test-id="downloadBtn"
                                            >
                                                {configJSON.downloadBtnText}
                                            </Button>
                                        </Box>
                                    </Box>
                                </DialogWrapper>
                            </ThemeProvider>
                        </GenericModal>
                    </>
                )}
            </>
        );
        // Customizable Area End
    }
}

// Customizable Area Start
const Wrapper = styled("div")(({ theme }) => ({
    "& .container": {
        display: "flex",
        flexDirection: "column",
        padding: "50px 72px",
        backgroundColor: "#F9FAFB",
        borderRadius: "8px",
    },
    "& .tabs": {
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        marginBottom: "30px",
        gap: "4px",
        width: "315px",
        borderRadius: "100px",
        padding: "4px",
        backgroundColor: "#F8FAFC",
    },
    "& .tab": {
        height: "40px",
        padding: "8px 16px",
        borderRadius: "24px",
        cursor: "pointer",
        fontFamily: "Lato",
        fontSize: "16px",
        lineHeight: "24px",
        fontWeight: 600,
        textAlign: "center",
        boxSizing: "border-box",
        color: "#64748B",
        transition: "all 0.3s ease-in-out",
        display: "flex",
        alignItems: "center",
        "&.active": {
            color: "#79B432",
            backgroundColor: "#FFFFFF",
        },
    },
    "& .orderCard": {
        display: "flex",
        flexDirection: "column",
        padding: "24px",
        marginBottom: "24px",
        backgroundColor: "#FFFFFF",
    },
    "& .orderDetails": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginBottom: "24px",
        maxWidth: "700px",
        flexWrap:"wrap",
    },
    "& .productInfo": {
        display: "flex",
        gap: "16px",
        marginBottom: "16px",
    },
    "& .productImage": {
        width: "100%",
        borderRadius: "8px",
        objectFit: "cover",
        aspectRatio: 1.3,
    },
    "& .productDescription": {
        flex: 1,
    },
    "& .actions": {
        display: "flex",
        gap: "16px",
        flexWrap: "wrap",
    },
    "& .textWrapper": {
        display: "flex",
        flexDirection: "column",
        gap: "12px",
    },
    "& .orderSummary": {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    "& .flexwrapBox": {
        display: "flex",
        justifyContent: "center",
        flexWrap: "wrap",
        gap: "48px",
    },
}));

const OrderDialogWrapper = styled("div")(() => ({
    "& .alignCenter": {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
    },
    "& .alignments": {
        display: "flex",
        justifyContent: "space-between",
        gap: "24px",
    },
    "& .detailsWrapper": {
        display: "flex",
        flexDirection: "column",
        gap: "12px",
    },
    "& .dialogTitle": {
        fontFamily: "Lato",
        fontSize: "28px",
        fontWeight: 700,
        color: "#0F172A",
        lineHeight: "36px",
    },
}));
// Customizable Area End
