Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "blogpostsmanagement";
exports.labelBodyText = "blogpostsmanagement Body";
exports.BlogpostEndPoint = "bx_block_blogpostsmanagement/blogposts";
exports.btnExampleTitle = "CLICK ME";
exports.getBlogsCatEndPoint = "bx_block_blogpostsmanagement2/blog_categories"
exports.getMoreReadBlogEndPoint = "bx_block_blogpostsmanagement2/blogs/most_read?blog_category_id="
exports.getRecentBlogEndPoint = "bx_block_blogpostsmanagement2/blogs?blog_category_id="
exports.blogsHeading = "Blogs"
exports.viewAll = "View All"
exports.mostRead = "Most Read"
exports.recent = "Recent"
exports.readMore = "Read More"
exports.noFound = "Blogs not found"
exports.getBlogsBannerEndPoint = "bx_block_blogpostsmanagement2/blog_banners"
exports.getBlogEndPoint = "bx_block_blogpostsmanagement2/blogs/"
exports.relatedBlogs = "Related Blogs"
// Customizable Area End