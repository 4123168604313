import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import React from "react";
import * as Yup from "yup";
import { FormikValues } from "formik";
import 'react-phone-input-2/lib/style.css';
import { sendAPIRequest } from "../../../components/src/Utils";
import { getStorageData ,setStorageData} from "../../../framework/src/Utilities";
import { ISelectOptions } from "../../../blocks/customform/src/LegalInformationController.web";
import { IStep } from "../../multipageforms/src/LegalDataEditController.web";
export interface IAttribute {
  name: string;
  title: string;
  field_type: string;
  is_enable: boolean;
  is_required: boolean;
  value: string | number;
}

export interface IAttributeValues {
  name: string;
  value: string;
}

export interface IUserProfileDetails {
  id: number; 
  profileImage: string | ArrayBuffer | null | undefined;
  firstName: string; 
  middleName: string; 
  lastName: string; 
  nationality: string; 
  gender: string; 
  genderName: string | null; 
  email: string;
  countryCode: string;
  phoneNumber: string; 
  dob: string; 
  age: number; 
  addressLine1: string; 
  addressLine2: string; 
  landmark: string; 
  city: string; 
  country: string; 
  postcode: string; 
  reason: string; 
  partnershipStatus: string; 
  addPartnerIsDelegate: boolean;
  isHealthy: string; 
  issue: string;
  partnerFirstName: string;
  partnerMiddleName: string;
  partnerLastName: string;
  partnerNationality: string;
  partnerGender: string | null;
  partnerAddressLine1: string;
  partnerAddressLine2: string;
  partnerLandmark: string;
  partnerCity: string;
  partnerCountry: string;
  partnerPostcode: string;
}
export interface ShowDetailsResponse {
 
    id: string;
    type: string;
    attributes: {
      id: number;
      user_id: string;
      uuid: string;
      first_name: string;
      middle_name: string;
      last_name: string;
      full_name: string;
      email: string;
      nationality: string;
      gender: string;
      gender_name: string | null;
      country_code: string;
      phone_number: string;
      full_phone_number: string;
      date_of_birth: string;
      age: number;
      address_line_1: string;
      address_line_2: string;
      landmark: string;
      city: string;
      country: string;
      post_code: string;
      full_address: string;
      partnership_status: string;
      add_partner_is_delegate: boolean;
      partner_detail: {
        first_name: string;
        middle_name: string;
        last_name: string;
        partner_name: string;
        email: string;
        nationality: string;
        gender: string | null;
        gender_name: string | null;
        address_line_1: string;
        address_line_2: string;
        landmark: string;
        city: string;
        country: string;
        post_code: string;
        partner_address: string;
      };
      fit_and_well: string;
      issue: string;
      reason: string;
      account_id: number;
      photo_url: string;
    };
  
}



export interface IUserProfile {
  first_name: string,
  middle_name: string,
  last_name: string,
  nationality: string,
  gender: string,
  gender_name: string,
  email: string,
  country_code: string,
  phone_number: string,
  date_of_birth: string,
  age: number,
  address_line_1: string,
  address_line_2: string,
  landmark: string,
  city: string,
  country: string,
  post_code: string,
  fit_and_well: "Yes" | "No",
  partnership_status: string,
  add_partner_is_delegate: boolean,
  issue: string,
  reason: string,
  photo: string,
}

export interface IUserPartner {
  first_name: string,
  middle_name: string,
  last_name: string,
  gender: string,
  nationality: string,
  address_line_1: string,
  address_line_2: string,
  landmark: string,
  city: string,
  country: string,
  post_code: string,
}

export interface IUserProfileFormData {
  id: number,
  full_name: string,
  email: string,
  nationality: string,
  gender: string,
  gender_name: string,
  full_phone_number: string,
  date_of_birth: string,
  age: number,
  full_address: string,
  partnership_status: string,
  add_partner_is_delegate: boolean,
  partner_detail: {
    partner_name: string,
    partner_address: string
  },
  fit_and_well: string,
  issue: string,
  reason: string,
  account_id: number,
  photo: string,
}

export interface IUserProfileAPIResponse {
  id: number,
  full_name: string,
  email: string,
  nationality: string,
  gender: string,
  gender_name: string,
  full_phone_number: string,
  date_of_birth: string,
  age: number,
  full_address: string,
  partnership_status: string,
  add_partner_is_delegate: boolean,
  partner_detail: {
    partner_name: string,
    partner_address: string
  },
  fit_and_well: string,
  issue: string,
  reason: string,
  account_id: number,
  photo_url: string,
}

interface CountryDropDownItem {
  value: number,
  name: string,
}

export const countries: CountryDropDownItem[] = [
  { value: 1, name: "Afghanistan" },
  { value: 2, name: "Albania" },
  { value: 3, name: "Algeria" },
  { value: 4, name: "Andorra" },
  { value: 5, name: "Angola" },
  { value: 6, name: "Antigua and Barbuda" },
  { value: 7, name: "Argentina" },
  { value: 8, name: "Armenia" },
  { value: 9, name: "Australia" },
  { value: 10, name: "Austria" },
  { value: 11, name: "Azerbaijan" },
  { value: 12, name: "Bahamas" },
  { value: 13, name: "Bahrain" },
  { value: 14, name: "Bangladesh" },
  { value: 15, name: "Barbados" },
  { value: 16, name: "Belarus" },
  { value: 17, name: "Belgium" },
  { value: 18, name: "Belize" },
  { value: 19, name: "Benin" },
  { value: 20, name: "Bhutan" },
  { value: 21, name: "Bolivia" },
  { value: 22, name: "Bosnia and Herzegovina" },
  { value: 23, name: "Botswana" },
  { value: 24, name: "Brazil" },
  { value: 25, name: "Brunei" },
  { value: 26, name: "Bulgaria" },
  { value: 27, name: "Burkina Faso" },
  { value: 28, name: "Burundi" },
  { value: 29, name: "Cabo Verde" },
  { value: 30, name: "Cambodia" },
  { value: 31, name: "Cameroon" },
  { value: 32, name: "Canada" },
  { value: 33, name: "Central African Republic" },
  { value: 34, name: "Chad" },
  { value: 35, name: "Chile" },
  { value: 36, name: "China" },
  { value: 37, name: "Colombia" },
  { value: 38, name: "Comoros" },
  { value: 39, name: "Congo (Congo-Brazzaville)" },
  { value: 40, name: "Costa Rica" },
  { value: 41, name: "Croatia" },
  { value: 42, name: "Cuba" },
  { value: 43, name: "Cyprus" },
  { value: 44, name: "Czechia (Czech Republic)" },
  { value: 45, name: "Democratic Republic of the Congo" },
  { value: 46, name: "Denmark" },
  { value: 47, name: "Djibouti" },
  { value: 48, name: "Dominica" },
  { value: 49, name: "Dominican Republic" },
  { value: 50, name: "Ecuador" },
  { value: 51, name: "Egypt" },
  { value: 52, name: "El Salvador" },
  { value: 53, name: "Equatorial Guinea" },
  { value: 54, name: "Eritrea" },
  { value: 55, name: "Estonia" },
  { value: 56, name: "Eswatini (fmr. 'Swaziland')" },
  { value: 57, name: "Ethiopia" },
  { value: 58, name: "Fiji" },
  { value: 59, name: "Finland" },
  { value: 60, name: "France" },
  { value: 61, name: "Gabon" },
  { value: 62, name: "Gambia" },
  { value: 63, name: "Georgia" },
  { value: 64, name: "Germany" },
  { value: 65, name: "Ghana" },
  { value: 66, name: "Greece" },
  { value: 67, name: "Grenada" },
  { value: 68, name: "Guatemala" },
  { value: 69, name: "Guinea" },
  { value: 70, name: "Guinea-Bissau" },
  { value: 71, name: "Guyana" },
  { value: 72, name: "Haiti" },
  { value: 73, name: "Holy See" },
  { value: 74, name: "Honduras" },
  { value: 75, name: "Hungary" },
  { value: 76, name: "Iceland" },
  { value: 77, name: "India" },
  { value: 78, name: "Indonesia" },
  { value: 79, name: "Iran" },
  { value: 80, name: "Iraq" },
  { value: 81, name: "Ireland" },
  { value: 82, name: "Israel" },
  { value: 83, name: "Italy" },
  { value: 84, name: "Jamaica" },
  { value: 85, name: "Japan" },
  { value: 86, name: "Jordan" },
  { value: 87, name: "Kazakhstan" },
  { value: 88, name: "Kenya" },
  { value: 89, name: "Kiribati" },
  { value: 90, name: "Kuwait" },
  { value: 91, name: "Kyrgyzstan" },
  { value: 92, name: "Laos" },
  { value: 93, name: "Latvia" },
  { value: 94, name: "Lebanon" },
  { value: 95, name: "Lesotho" },
  { value: 96, name: "Liberia" },
  { value: 97, name: "Libya" },
  { value: 98, name: "Liechtenstein" },
  { value: 99, name: "Lithuania" },
  { value: 100, name: "Luxembourg" },
  { value: 101, name: "Madagascar" },
  { value: 102, name: "Malawi" },
  { value: 103, name: "Malaysia" },
  { value: 104, name: "Maldives" },
  { value: 105, name: "Mali" },
  { value: 106, name: "Malta" },
  { value: 107, name: "Marshall Islands" },
  { value: 108, name: "Mauritania" },
  { value: 109, name: "Mauritius" },
  { value: 110, name: "Mexico" },
  { value: 111, name: "Micronesia" },
  { value: 112, name: "Moldova" },
  { value: 113, name: "Monaco" },
  { value: 114, name: "Mongolia" },
  { value: 115, name: "Montenegro" },
  { value: 116, name: "Morocco" },
  { value: 117, name: "Mozambique" },
  { value: 118, name: "Myanmar" },
  { value: 119, name: "Namibia" },
  { value: 120, name: "Nauru" },
  { value: 121, name: "Nepal" },
  { value: 122, name: "Netherlands" },
  { value: 123, name: "New Zealand" },
  { value: 124, name: "Nicaragua" },
  { value: 125, name: "Niger" },
  { value: 126, name: "Nigeria" },
  { value: 127, name: "North Korea" },
  { value: 128, name: "North Macedonia" },
  { value: 129, name: "Norway" },
  { value: 130, name: "Oman" },
  { value: 131, name: "Pakistan" },
  { value: 132, name: "Palau" },
  { value: 133, name: "Palestine State" },
  { value: 134, name: "Panama" },
  { value: 135, name: "Papua New Guinea" },
  { value: 136, name: "Paraguay" },
  { value: 137, name: "Peru" },
  { value: 138, name: "Philippines" },
  { value: 139, name: "Poland" },
  { value: 140, name: "Portugal" },
  { value: 141, name: "Qatar" },
  { value: 142, name: "Romania" },
  { value: 143, name: "Russia" },
  { value: 144, name: "Rwanda" },
  { value: 145, name: "Saint Kitts and Nevis" },
  { value: 146, name: "Saint Lucia" },
  { value: 147, name: "Saint Vincent and the Grenadines" },
  { value: 148, name: "Samoa" },
  { value: 149, name: "San Marino" },
  { value: 150, name: "Sao Tome and Principe" },
  { value: 151, name: "Saudi Arabia" },
  { value: 152, name: "Senegal" },
  { value: 153, name: "Serbia" },
  { value: 154, name: "Seychelles" },
  { value: 155, name: "Sierra Leone" },
  { value: 156, name: "Singapore" },
  { value: 157, name: "Slovakia" },
  { value: 158, name: "Slovenia" },
  { value: 159, name: "Solomon Islands" },
  { value: 160, name: "Somalia" },
  { value: 161, name: "South Africa" },
  { value: 162, name: "South Korea" },
  { value: 163, name: "South Sudan" },
  { value: 164, name: "Spain" },
  { value: 165, name: "Sri Lanka" },
  { value: 166, name: "Sudan" },
  { value: 167, name: "Suriname" },
  { value: 168, name: "Sweden" },
  { value: 169, name: "Switzerland" },
  { value: 170, name: "Syria" },
  { value: 171, name: "Tajikistan" },
  { value: 172, name: "Tanzania" },
  { value: 173, name: "Thailand" },
  { value: 174, name: "Timor-Leste" },
  { value: 175, name: "Togo" },
  { value: 176, name: "Tonga" },
  { value: 177, name: "Trinidad and Tobago" },
  { value: 178, name: "Tunisia" },
  { value: 179, name: "Turkey" },
  { value: 180, name: "Turkmenistan" },
  { value: 181, name: "Tuvalu" },
  { value: 182, name: "Uganda" },
  { value: 183, name: "Ukraine" },
  { value: 184, name: "United Arab Emirates" },
  { value: 185, name: "United Kingdom" },
  { value: 186, name: "United States of America" },
  { value: 187, name: "Uruguay" },
  { value: 188, name: "Uzbekistan" },
  { value: 189, name: "Vanuatu" },
  { value: 190, name: "Venezuela" },
  { value: 191, name: "Vietnam" },
  { value: 192, name: "Yemen" },
  { value: 193, name: "Zambia" },
  { value: 194, name: "Zimbabwe" },
  { value: 195, name: "Taiwan" }
];


type FieldType =
  | "string"
  | "file"
  | "textarea"
  | "text"
  | "date"
  | "datetime"
  | "integer"
  | "number"
  | "boolean"
  | "float"
  | "checkbox";
type FieldValue = string | number | boolean | Date | null;
export interface IField {
  name: string;
  title: string;
  field_type: FieldType;
  is_enable: boolean;
  is_required: boolean;
}

interface IProfileDataWeb {
  attributes?: { user_profile_data: { [key: string]: FieldValue } };
}

export interface IProfileData {
  [key: string]: FieldValue;
}

export interface IProfileValues {
  value: string;
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  profile: { [key: string]: FieldValue };
  fields: IField[];
  currentProfile?: { [key: string]: FieldValue };
  profileImageUrl: string;
  saveEnabled?: boolean;
  cancelEnabled?: boolean;

  txtInputValue: string;
  txtSavedValue: string;

  first_name: string;
  lastName: string;
  fullName: string;
  email: string;
  phoneNumber: string;
  city: string;
  address: string;
  attributesValues: IProfileValues[];
  countryOpen: boolean;
  postalCode: string;
  profilePicture: string;
  profileRole: string;
  aboutMe: string;
  gender: string;
  user_name: string;
  dateOfBirth: string;
  currentPassword: string;
  newPassword: string;
  reNewPassword: string;
  instagram: string;
  facebook: string;
  youtube: string;
  qrCode: string;
  profileId: string;
  user_type: string;
  imageModalVisible: boolean;
  userAge: number;
  biography: string;
  video: string;
  attributes: IAttribute[];

  passwordHelperText: string;
  enablePasswordField: boolean;
  enableReTypePasswordField: boolean;
  enableNewPasswordField: boolean;

  edtEmailEnabled: boolean;
  llDoChangePwdContainerVisible: boolean;
  llChangePwdDummyShowContainerVisible: boolean;
  isDatePickerVisible: boolean;

  edtMobileNoEnabled: boolean;
  countryCodeEnabled: boolean;

  saveButtonDisable: boolean;
  enableField: boolean;

  userDetails: IUserProfileDetails;
  profileImage: string | ArrayBuffer | null | undefined;
  switchChecked: boolean;
  openDialog: boolean;
  anchorEl: Element | null;
  errorMessage: string;
  successMsg: string;
  nationalities: ISelectOptions[];
  phoneNumberLength: number;
  stepsMyProfile: IStep[];
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class UserProfileEditController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  token: string = "";
  intervalId: number = 0;

  labelEmail: string = "";

  btnTextCancelPasswordChange: string = "";
  btnTextSaveChanges: string = "";
  labelHeader: string = "";
  btnTextChangePassword: string = "";

  apiCallMessageUpdateProfileRequestId: string = "";
  validationApiCallId: string = "";
  apiChangePhoneValidation: string = "";
  registrationAndLoginType: string = "";
  authToken: string = "";
  uniqueSessionRequesterId: string = "";
  userProfileGetApiCallId: string = "";
  callUpsertUserProfileApiId: string = "";
  callGetNationalitiesApiId: string = "";
  callShowProfileApiId:string ="";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      profile: {},
      profileImageUrl: "",
      fields: [],

      attributes: [],
      attributesValues: [],
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      first_name: "",
      lastName: "",
      fullName: "",
      email: "",
      city: "",
      address: "",
      postalCode: "",
      profilePicture: "",
      profileRole: "jobseeker",
      aboutMe: "",
      gender: "",
      user_name: "",
      dateOfBirth: "",
      biography: "",
      currentPassword: "",
      newPassword: "",
      reNewPassword: "",
      youtube: "",
      instagram: "",
      facebook: "",
      qrCode: "",
      profileId: "",
      countryOpen: false,
      user_type: "",
      imageModalVisible: false,
      userAge: 0,
      phoneNumber: "",
      passwordHelperText: "",
      enablePasswordField: true,
      enableReTypePasswordField: true,
      enableNewPasswordField: true,
      video: "",

      edtEmailEnabled: true,
      llDoChangePwdContainerVisible: false,
      llChangePwdDummyShowContainerVisible: false,
      isDatePickerVisible: false,

      edtMobileNoEnabled: true,
      countryCodeEnabled: true,
      saveButtonDisable: false,

      userDetails: {
        id: 0,
        profileImage: "",
        firstName: "",
        middleName: "",
        lastName: "",
        nationality: "",
        gender: "",
        genderName: "",
        email: "",
        countryCode: "",
        phoneNumber: "",
        dob: "",
        age: 0,
        addressLine1: "",
        addressLine2: "",
        landmark: "",
        city: "",
        country: "",
        postcode: "",
        reason: "",
        partnershipStatus: "",
        isHealthy: "No",
        issue: "",
        addPartnerIsDelegate: false,
        partnerFirstName: "",
        partnerMiddleName: "",
        partnerLastName: "",
        partnerNationality: "",
        partnerGender: "",
        partnerAddressLine1: "",
        partnerAddressLine2: "",
        partnerLandmark: "",
        partnerCity: "",
        partnerCountry: "",
        partnerPostcode: "",
      },
      profileImage: null,
      switchChecked: false,
      openDialog: false,
      anchorEl: null,
      errorMessage: "",
      successMsg: "",
      nationalities: [],
      phoneNumberLength: 10,
      stepsMyProfile: [
        { label: 'Account', path: 'HomePage' },
        { label: 'My Profile', path: 'MyProfile' },
        { label: 'Edit Profile', path: 'EditProfile' },
      ],
      // Customizable Area End
    };
    // Customizable Area Start
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    // Customizable Area Start
    this.getNationalitiesList();
    this.getShowProfileApi()
    // Customizable Area End
  }

  getToken = () => {
    const message: Message = new Message(getName(MessageEnum.SessionRequestMessage));
    this.send(message);
  };




  async receive(from: string, message: Message) {
    // Customizable Area Start
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJSON = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    this.apiSuccessCallBackController(apiRequestCallId, responseJSON);
    // Customizable Area End
  }

  // Customizable Area Start

  changeFormValue = (
    valueKey: string,
    value: string | Date | number | boolean | null,
    fieldType: FieldType
  ) => {
    if (value !== undefined) {
      if (fieldType === "float") value = parseFloat(value as string);
      else if (fieldType === "integer") value = parseInt(value as string);
      else if (fieldType === "date") value = (value as Date).toDateString();
      else if (fieldType === "boolean") value = value === "true";
      else if (fieldType === "checkbox") value = !!value;
    }
    this.setState({ profile: { ...this.state.profile, [valueKey]: value } });
  };

  onKeyPress = (event: React.KeyboardEvent, field: IField) => {
    if (field.field_type === "integer" && (event.key === "." || event.key === ",")) {
      event.preventDefault();
    }
  };

  //API integration
  apiSuccessCallBackController = (
    apiRequestCallId: string,
    responseJSON: Record<string, unknown>
  ) => {
    const successCallbackMap = {
      [this.callUpsertUserProfileApiId]: this.handleUpsertUserProfileApiResponse,
      [this.callGetNationalitiesApiId]: this.handleGetNationalitiesApiResponse,
      [this.callShowProfileApiId]: this.handlecallShowProfileApiIdResponse,
    }

    if (apiRequestCallId) {
      const successCallback: (responseJSON: Record<string, unknown>) => void = successCallbackMap[apiRequestCallId]
      !!successCallback && successCallback(responseJSON)
    }
  }

  handleErrorResponse = (responseJSON: Record<string, unknown>) => {
    const { errors: possibleErrors } = responseJSON;
    if (possibleErrors) {
      return true; // Indicates that there was an error
    }
    return false; // Indicates that there was no error
  }

  handleUpsertUserProfileApiResponse = async(responseJSON: any) => {
   await setStorageData('showUpdateedID',responseJSON.data.id); 
    if (this.handleErrorResponse(responseJSON)) return;
    this.setState({ errorMessage: '', openDialog: true });
  }
  handlecallShowProfileApiIdResponse =(responseJSON:any)=>{
    const response = responseJSON as {
      meta?: { message: string };
      data?: ShowDetailsResponse;
    };
   
    if (response.data) {
      const attributes = response.data.attributes;
      this.setState({
        userDetails: {
         ...this.state.userDetails,
          id:attributes.id,
          profileImage:attributes.photo_url,
          firstName: attributes.first_name,
          middleName: attributes.middle_name,
          lastName : attributes.last_name,
          nationality: attributes.nationality,
          gender: attributes.gender,
          genderName:attributes.gender_name,
          email:attributes.email,
          countryCode:attributes.country_code,
          phoneNumber:attributes.phone_number,
          dob:attributes.date_of_birth,
          age:attributes.age,
          addressLine1:attributes.address_line_1,
          addressLine2:attributes.address_line_2,
          landmark:attributes.landmark,
          city:attributes.city,
          country: attributes.country,
          postcode: attributes.post_code,
          reason: attributes.reason,
          partnershipStatus: attributes.partnership_status,
          isHealthy: attributes.fit_and_well,
          issue: attributes.issue,
          addPartnerIsDelegate: attributes.add_partner_is_delegate,
          partnerFirstName: attributes.partner_detail.first_name,
          partnerMiddleName: attributes.partner_detail.middle_name,
          partnerLastName: attributes.partner_detail.last_name,
          partnerNationality: attributes.partner_detail.nationality,
          partnerGender: attributes.partner_detail.gender,
          partnerAddressLine1: attributes.partner_detail.address_line_1,
          partnerAddressLine2: attributes.partner_detail.address_line_2,
          partnerLandmark: attributes.partner_detail.landmark,
          partnerCity: attributes.partner_detail.city,
          partnerCountry: attributes.partner_detail.country,
          partnerPostcode:attributes.partner_detail.post_code, 
        },
      })
    }
    
  }

  handleGetNationalitiesApiResponse = (responseJSON: Record<string, unknown>) => {
      const response = responseJSON as {
        nationalities?: string[];
      };
  
      if (response.nationalities) {
        let nationalities: ISelectOptions[] = [];
  
        response.nationalities.forEach((nationality, index) => {
          nationalities.push({
            value: index.toString(),
            name: nationality,
          });
        });
  
        this.setState({ nationalities });
      }
  };


  getNationalitiesList = async () => {
    this.callGetNationalitiesApiId = sendAPIRequest(
        configJSON.getNationalitiesApiEndPoint,
        {
          method: configJSON.httpGetMethod,
        }
      );
  };
  getShowProfileApi =async()=>{
    const token = await getStorageData("token");
    this.callShowProfileApiId = sendAPIRequest(
      configJSON.getUpsertUserProfileApiEndPoint,
      {
        method: configJSON.httpGetMethod,
        headers: {
          token,
        },
      }
    );
  }

  validationSchema = (phoneNumberLength: number) => Yup.object().shape({
    firstName: Yup.string().nullable().required("Please enter your first name"),
    lastName: Yup.string().nullable().required("Please enter your last name"),
    nationality: Yup.string().nullable().required("Please select your nationality"),
    gender: Yup.string().nullable().required("Please select your gender"),
    email: Yup.string().nullable().email("Please enter valid email ID").required("Please enter your email ID"),
    phoneNumber: Yup.string().nullable()
    .transform((value) => {
      return value.replace(/\D/g, '');  // Remove non-numeric characters
    })
      .matches(
        new RegExp(`^\\d{${phoneNumberLength}}$`),
        `Phone number must be ${phoneNumberLength} digits`
      )
      .required("Please enter a phone number"),
    dob: Yup.string().nullable().required("Please enter your date of birth"),
    age: Yup.string().nullable().required("Please enter your age"),
    addressLine1: Yup.string().nullable().required("Please enter your address line 1"),
    addressLine2: Yup.string().nullable().required("Please enter your address line 2"),
    city: Yup.string().nullable().required("Please enter your city"),
    country: Yup.string().nullable().required("Please select your country"),
    postcode: Yup.string().nullable().required("Please enter a valid post code"),
    reason: Yup.string().nullable().required("Please enter your reason"),
    partnershipStatus: Yup.string().required("Please select your partnership status"),
    partnerFirstName: Yup.string().when(['partnershipStatus', 'addPartnerIsDelegate'], {
      is: (partnershipStatus, addPartnerIsDelegate) => partnershipStatus === 'Married' && partnershipStatus.length > 0 && addPartnerIsDelegate === true,
      then: Yup.string().required('Please enter first name'),
      otherwise: Yup.string(),
    }),
  partnerLastName: Yup.string().when(['partnershipStatus', 'addPartnerIsDelegate'], {
    is: (partnershipStatus, addPartnerIsDelegate) => partnershipStatus === 'Married' && partnershipStatus.length > 0 && addPartnerIsDelegate === true, 
    then: Yup.string().required('Please enter last name'),
    otherwise: Yup.string(),
  }),
  partnerNationality: Yup.string().nullable().when(['partnershipStatus', 'addPartnerIsDelegate'], {
    is: (partnershipStatus, addPartnerIsDelegate) => partnershipStatus === 'Married' && partnershipStatus.length > 0 && addPartnerIsDelegate === true, 
    then: Yup.string().required('Please select nationality'),
    otherwise: Yup.string(),
  }),
  partnerGender: Yup.string().nullable().when(['partnershipStatus', 'addPartnerIsDelegate'], {
    is: (partnershipStatus, addPartnerIsDelegate) => partnershipStatus === 'Married' && partnershipStatus.length > 0 && addPartnerIsDelegate === true, 
    then: Yup.string().required('Please select gender'),
    otherwise: Yup.string(),
  }),
  partnerAddressLine1: Yup.string().nullable().when(['partnershipStatus', 'addPartnerIsDelegate'], {
    is: (partnershipStatus, addPartnerIsDelegate) => partnershipStatus === 'Married' && partnershipStatus.length > 0 && addPartnerIsDelegate === true, 
    then: Yup.string().required('Please enter House no./Flat no.'),
    otherwise: Yup.string(),
  }),
  partnerAddressLine2: Yup.string().nullable().when(['partnershipStatus', 'addPartnerIsDelegate'], {
    is: (partnershipStatus, addPartnerIsDelegate) => partnershipStatus === 'Married' && partnershipStatus.length > 0 && addPartnerIsDelegate === true, 
    then: Yup.string().required('Please enter street and locality'),
    otherwise: Yup.string(),
  }),
  partnerCountry: Yup.string().nullable().when(['partnershipStatus', 'addPartnerIsDelegate'], {
    is: (partnershipStatus, addPartnerIsDelegate) => partnershipStatus === 'Married' && partnershipStatus.length > 0 && addPartnerIsDelegate === true, 
    then: Yup.string().required('Please select country'),
    otherwise: Yup.string(),
  }),
  partnerPostcode: Yup.string().when(['partnershipStatus', 'addPartnerIsDelegate'], {
    is: (partnershipStatus, addPartnerIsDelegate) => partnershipStatus === 'Married' && partnershipStatus.length > 0 && addPartnerIsDelegate === true, 
    then: Yup.string().required('Please enter a valid post code'),
    otherwise: Yup.string(),
  }),
  });

  handleProfileFormSubmit = async (values: FormikValues) => {
    const token = await getStorageData("token");
    const formData = new FormData();

    const profile = {
      first_name: values.firstName,
      middle_name: values.middleName,
      last_name: values.lastName,
      nationality: values.nationality,
      gender: values.gender,
      gender_name: values.genderName,
      email: values.email,
      country_code: values.countryCode,
      phone_number: values.phoneNumber,
      date_of_birth: values.dob,
      age: values.age,
      address_line_1: values.addressLine1,
      address_line_2: values.addressLine2,
      landmark: values.landmark,
      city: values.city,
      country: values.country,
      post_code: values.postcode,
      fit_and_well: values.isHealthy,
      partnership_status: values.partnershipStatus,
      add_partner_is_delegate: values.partnershipStatus !== "Married" ? false : values.addPartnerIsDelegate,
      issue: values.issue,
      reason: values.reason,
      photo: values.profileImage,
    } as IUserProfile;

    Object.entries(profile).forEach(([keyName, value]) => {
      if (value) {
          formData.append(`profile[${keyName}]`, value);
      }
    });
    if (profile.photo) {
      if (typeof profile.photo === 'string' && profile.photo.startsWith('http')) {
          const agencyLogoFile = await this.convertUrlToFile(
            profile.photo,
              "agency_logo.jpg",
              "image/jpeg"
          );
          formData.append("profile[photo]", agencyLogoFile);
      } 
  }

    const partner = {
      first_name: values.partnerFirstName,
      middle_name: values.partnerMiddleName,
      last_name: values.partnerLastName,
      gender: values.partnerGender,
      nationality: values.partnerNationality,
      address_line_1: values.partnerAddressLine1,
      address_line_2: values.partnerAddressLine2,
      landmark: values.partnerLandmark,
      city: values.partnerCity,
      country: values.partnerCountry,
      post_code: values.partnerPostcode,
    } as IUserPartner;

    Object.entries(partner).forEach(([keyName, value]) => {
      formData.append(`partner[${keyName}]`, value);
    });

    this.callUpsertUserProfileApiId = sendAPIRequest(
      configJSON.getUpsertUserProfileApiEndPoint,
      {
        method: configJSON.httpPostMethod,
        headers: {
          token,
        },
        body: formData,
      }
    );
  };
   async convertUrlToFile(url:any, filename:any, mimeType:any) {
    const response = await fetch(url);
    const blob = await response.blob();
    return new File([blob], filename, { type: mimeType });
  }

  calenderIcon = () => {
    return (
      <div className="calenderIcon">
        <svg fill="none" width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path d="M8 2V5" stroke="#A190C2" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M16 2V5" stroke="#A190C2" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M3.5 9.08984H20.5" stroke="#A190C2" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M21 8.5V17C21 20 19.5 22 16 22H8C4.5 22 3 20 3 17V8.5C3 5.5 4.5 3.5 8 3.5H16C19.5 3.5 21 5.5 21 8.5Z" stroke="#A190C2" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M11.9955 13.6992H12.0045" stroke="#A190C2" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M8.29431 13.6992H8.30329" stroke="#A190C2" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M8.29431 16.6992H8.30329" stroke="#A190C2" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
      </svg>
      </div>
    );
  }

  handleSwitchChange = async (setFieldValue:
    {
      (field: string,
        value: any,
        shouldValidate?: boolean | undefined): void; (arg0: string, arg1: string): void;
    }) => {
    await new Promise<void>((resolve) => this.setState({ switchChecked: !this.state.switchChecked }, () => resolve()));
    setFieldValue("addPartnerIsDelegate", this.state.switchChecked);
  }




  handleDateChange = async (setFieldValue:
    {
      (field: string,
        value: any,
        shouldValidate?: boolean | undefined): void; (arg0: string, arg1: string): void;
    }, dob: Date) => {
      setFieldValue("dob",dob.toLocaleDateString("en-GB"), true);
      let age = await this.calculateAge(dob);
      setFieldValue("age", age);
  }

  calculateAge = async (birthDate: Date): Promise<number> => {
    const today = new Date();
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();

    // Adjust if birth month hasn't occurred yet this year
    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }

    return age;
  };

  handleCloseDialog = () => {
    this.setState({ openDialog: false },()=>{
      this.getShowProfileApi()
      window.history.back();
    });
  };
   handleBack = () => {
    window.history.back();
  };

  handleProfileImg = (event: React.ChangeEvent<HTMLInputElement>, 
    setFieldValue:
    {
      (field: string,
        value: any,
        shouldValidate?: boolean | undefined): void; (arg0: string, arg1: string): void;
    }) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      setFieldValue("profileImage", file);
      const reader = new FileReader();
      reader.onload = (event) => {
        this.setState({ profileImage: event.target?.result, anchorEl: null });
      };
      reader.readAsDataURL(file);
    }
  }

  handleProfilePicEditMenu = (event: React.MouseEvent<HTMLInputElement>) => {
    this.setState({ anchorEl: event.currentTarget });
  }

  handleProfilePicEditMenuClose = () => {
    this.setState({ anchorEl: null });
  }

  handleRemoveProfilePic = (
    setFieldValue:
    {
      (field: string,
        value: any,
        shouldValidate?: boolean | undefined): void; (arg0: string, arg1: string): void;
    }) => {
    setFieldValue("profileImage", null);
    this.setState({ profileImage: null });
  }

  handleAddDelegate = () => {
    this.handleNavigation("DelegateMembers");
  };

  handlePhoneInputChange = (
    value: string, 
    country: any, 
    setFieldValue:
    {
      (field: string,
        value: any,
        shouldValidate?: boolean | undefined): void; (arg0: string, arg1: string): void;
    }) => {
        setFieldValue("countryCode", country.dialCode);
        setFieldValue("phoneNumber", value.slice(country.dialCode.length));

        this.setState({ phoneNumberLength: country.format.match(/\./g).length - country.dialCode.length});
  };

  handleNavigation = (route: string) => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), route);
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }
  // Customizable Area End
}
