import React from "react";
// Customizable Area Start
import { styled, ThemeProvider } from "@material-ui/core/styles";
import DelegateMembersEditController, {
    configJSON,
    Props,
} from "./DelegateMembersEditController.web";
import {
    Box,
    Grid,
    Typography,
    Button,
    IconButton,
    Card, CardContent,
} from "@material-ui/core";

import WebFooter from "../../../components/src/Footer.web";
import WebHeader from "../../../blocks/landingpage/src/WebHeader.web";
import BreadcrumbStepper from "../../../components/src/BreadcrumbStepper.web";
import { userProfile } from "../src/assets"
import { theme } from "../../../blocks/multipageforms/src/LegalDataEdit.web";
// Customizable Area End

export default class DelegateMembersEdit extends DelegateMembersEditController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    render() {
        // Customizable Area Start
        const {
            stepsDelegate, ShowsDeleagteApiData, ShowsDeleagteApiData1
        } = this.state;

        return (
            <>
                <ThemeProvider theme={theme}>
                    <Wrapper>
                        <WebHeader navigation={this.props.navigation} />
                        <Box className="container">
                            <Box>
                                <BreadcrumbStepper data-test-id="breadcrumbStepper" steps={stepsDelegate} onStepClick={(path) => this.handleNavigation(path)} />
                            </Box>
                            {ShowsDeleagteApiData && ShowsDeleagteApiData1 ? (
                                <>
                                    <Card className="cardContain"
                                    >
                                        <CardContent>
                                            <Grid container className="notesInfoWrapper">
                                                <Grid item xs={12} className="pageTitle">
                                                    <div className="cardContainDive">
                                                        <Grid container alignItems="center" className="cardGridContainer">
                                                            <Grid item xs={12} sm={8} md={6} container direction="column">
                                                                <Grid container alignItems="center" justifyContent="flex-start">
                                                                    <img
                                                                        src={this.state.ShowsDeleagteApiData.photo_url || userProfile}
                                                                        alt="Profile"
                                                                        className="cardUserProfileContainer"
                                                                    />
                                                                    <Grid item className="cardUserProfileGridContainer">
                                                                        <Typography variant="h3" className="messageTextMember">
                                                                            {configJSON.DelegatedMemberone}
                                                                        </Typography>
                                                                        <Typography
                                                                            variant="h3" className="messageTexts"
                                                                        >
                                                                            {`${this.state.ShowsDeleagteApiData.first_name} ${this.state.ShowsDeleagteApiData.middle_name} ${this.state.ShowsDeleagteApiData.last_name}`}
                                                                        </Typography>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>

                                                            <Grid item xs={12} sm={4} md={2} container alignItems="center" justifyContent="center">
                                                                <Button
                                                                    data-test-id="editDetailsBtns"
                                                                    variant="contained"
                                                                    className="buttonSizeFull"
                                                                    type="button"
                                                                    onClick={() => this.handleDelegateOneBtnClick()}
                                                                    startIcon={
                                                                        <IconButton className="startIconStyle">
                                                                            <svg
                                                                                width="26"
                                                                                height="26"
                                                                                viewBox="0 0 24 24"
                                                                                fill="none"
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                            >
                                                                                <rect width="26" height="26" rx="12" />
                                                                                <path
                                                                                    d="M17.25 17.834H6.75C6.51083 17.834 6.3125 17.6357 6.3125 17.3965C6.3125 17.1573 6.51083 16.959 6.75 16.959H17.25C17.4892 16.959 17.6875 17.1573 17.6875 17.3965C17.6875 17.6357 17.4892 17.834 17.25 17.834Z"
                                                                                    fill="white"
                                                                                />
                                                                                <path
                                                                                    d="M16.095 7.02964C14.9633 5.89798 13.855 5.86881 12.6942 7.02964L11.9883 7.73548C11.93 7.79381 11.9067 7.88714 11.93 7.96881C12.3733 9.51464 13.61 10.7513 15.1558 11.1946C15.1792 11.2005 15.2025 11.2063 15.2258 11.2063C15.29 11.2063 15.3483 11.183 15.395 11.1363L16.095 10.4305C16.6725 9.85881 16.9525 9.30464 16.9525 8.74464C16.9583 8.16714 16.6783 7.60714 16.095 7.02964Z"
                                                                                    fill="white"
                                                                                />
                                                                                <path
                                                                                    d="M14.1058 11.726C13.9367 11.6443 13.7733 11.5626 13.6158 11.4693C13.4875 11.3935 13.365 11.3118 13.2425 11.2243C13.1433 11.1601 13.0267 11.0668 12.9158 10.9735C12.9042 10.9676 12.8633 10.9326 12.8167 10.886C12.6242 10.7226 12.4083 10.5126 12.2158 10.2793C12.1983 10.2676 12.1692 10.2268 12.1283 10.1743C12.07 10.1043 11.9708 9.98763 11.8833 9.85346C11.8133 9.76596 11.7317 9.63763 11.6558 9.5093C11.5625 9.3518 11.4808 9.1943 11.3992 9.03096C11.2921 8.80152 10.9909 8.73335 10.8119 8.9124L7.53167 12.1926C7.45583 12.2685 7.38583 12.4143 7.36833 12.5135L7.05333 14.7476C6.995 15.1443 7.10583 15.5176 7.35083 15.7685C7.56083 15.9726 7.8525 16.0835 8.1675 16.0835C8.2375 16.0835 8.3075 16.0776 8.3775 16.066L10.6175 15.751C10.7225 15.7335 10.8683 15.6635 10.9383 15.5876L14.224 12.302C14.3994 12.1265 14.3335 11.8246 14.1058 11.726Z"
                                                                                    fill="white"
                                                                                />
                                                                            </svg>
                                                                        </IconButton>
                                                                    }
                                                                >
                                                                    {configJSON.editDelegateBtn}
                                                                </Button>
                                                            </Grid>
                                                        </Grid>
                                                    </div>
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        className="pageTitlesCard"
                                                    ></Grid>
                                                </Grid>
                                            </Grid>

                                            <Grid container spacing={2} justifyContent="center">
                                                <Grid item xs={12} className="nationalitycard">
                                                    <Grid container className="nationalitycardConatiner">
                                                        <Grid item>
                                                            <Typography variant="h3" className="messageTextnationality">
                                                                {configJSON.nationality}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item>
                                                            <Typography variant="h3" className="messageText" style={{ fontWeight: 600 }}>
                                                                {this.state.ShowsDeleagteApiData.nationality}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={2} justifyContent="center">
                                                <Grid item xs={12} className="nationalitycard">
                                                    <Grid container justifyContent="space-between" alignItems="center" >
                                                        <Grid item className="messageTextStyleCarddata">
                                                            <Typography variant="h3" className="messageTextnationality">
                                                                {configJSON.genderLabel}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item>
                                                            <Typography variant="h3" className="messageText" style={{ fontWeight: 600 }}>
                                                                {this.state.ShowsDeleagteApiData.gender}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={2} justifyContent="center">
                                                <Grid item xs={12} className="nationalitycard">
                                                    <Grid container justifyContent="space-between" alignItems="center" >
                                                        <Grid item className="messageTextStyleCarddata">
                                                            <Typography variant="h3" className="messageTextnationality">
                                                                {configJSON.emailAddess}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item>
                                                            <Typography variant="h3" className="messageText" style={{ fontWeight: 600 }}>
                                                                {this.state.ShowsDeleagteApiData.email}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={2} justifyContent="center">
                                                <Grid item xs={12} className="nationalityFullnocard">
                                                    <Grid container justifyContent="space-between" alignItems="center" >
                                                        <Grid item className="messageTextStyle">
                                                            <Typography variant="h3" className="messageTextnationality">
                                                                {configJSON.phoneNo}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item>
                                                            <Typography variant="h3" className="messageText" style={{ fontWeight: 600 }}>
                                                                {this.state.ShowsDeleagteApiData.full_phone_number}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={2} justifyContent="center">
                                                <Grid item xs={12} className="dobcard">
                                                    <Grid container justifyContent="space-between" alignItems="center" >
                                                        <Grid item className="messageTextStyle">
                                                            <Typography variant="h3" className="messageTextnationality">
                                                                {configJSON.dateBirth}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item>
                                                            <Typography variant="h3" className="messageText" style={{ fontWeight: 600 }}>
                                                                {this.state.ShowsDeleagteApiData.date_of_birth}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>

                                            <Grid container spacing={2} justifyContent="center">
                                                <Grid item xs={12} className="addresscard">
                                                    <Grid container justifyContent="space-between" alignItems="center" >
                                                        <Grid item className="messageTextStyle">
                                                            <Typography variant="h3" className="messageTextnationality">
                                                                {configJSON.address}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item>
                                                            <Typography variant="h3" className="messageText" style={{ fontWeight: 600 }}>
                                                                {`${this.state.ShowsDeleagteApiData.address_line_1}, ${this.state.ShowsDeleagteApiData.address_line_2},
                                             ${this.state.ShowsDeleagteApiData.landmark},${this.state.ShowsDeleagteApiData.city},
                                             ${this.state.ShowsDeleagteApiData.country},${this.state.ShowsDeleagteApiData.post_code}`}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                    </Card>
                                    <Card
                                        className="cardContainSecond"
                                    >
                                        <CardContent>
                                            <Grid container className="notesInfoWrapper">
                                                <Grid item xs={12} className="pageTitle">
                                                    <div className="cardContainSecondDives">
                                                        <Grid container alignItems="center" className="cardContainSecondDivesContainer" >
                                                            <Grid item xs={12} sm={8} md={6} container direction="column">
                                                                <Grid container alignItems="center" justifyContent="flex-start">
                                                                    <img
                                                                        src={this.state.ShowsDeleagteApiData1.photo_url || userProfile}
                                                                        alt="Profile"
                                                                        className="cardContainImages"
                                                                    />
                                                                    <Grid item className="cardContainMainCard">
                                                                        <Typography variant="h3" className="messageTextMember" style={{ fontWeight: 600 }}>
                                                                            {configJSON.DelegatedMembertwo}
                                                                        </Typography>
                                                                        <Typography
                                                                            variant="h3" className="messageTexts"
                                                                        >
                                                                            {`${this.state.ShowsDeleagteApiData1.first_name} ${this.state.ShowsDeleagteApiData1.middle_name} ${this.state.ShowsDeleagteApiData1.last_name}`}
                                                                        </Typography>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>

                                                            <Grid item xs={12} sm={4} md={2} container alignItems="center" justifyContent="center">
                                                                <Button
                                                                    data-test-id="editDetailsBtn"
                                                                    variant="contained"
                                                                    className="buttonSizeFulls"
                                                                    type="button"
                                                                    onClick={() => this.handleDelegateTwoBtnClick()}
                                                                    startIcon={
                                                                        <IconButton className="startIconImage" >
                                                                            <svg
                                                                                width="26"
                                                                                height="26"
                                                                                viewBox="0 0 24 24"
                                                                                fill="none"
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                            >
                                                                                <rect width="26" height="26" rx="12" />
                                                                                <path
                                                                                    d="M17.25 17.834H6.75C6.51083 17.834 6.3125 17.6357 6.3125 17.3965C6.3125 17.1573 6.51083 16.959 6.75 16.959H17.25C17.4892 16.959 17.6875 17.1573 17.6875 17.3965C17.6875 17.6357 17.4892 17.834 17.25 17.834Z"
                                                                                    fill="white"
                                                                                />
                                                                                <path
                                                                                    d="M16.095 7.02964C14.9633 5.89798 13.855 5.86881 12.6942 7.02964L11.9883 7.73548C11.93 7.79381 11.9067 7.88714 11.93 7.96881C12.3733 9.51464 13.61 10.7513 15.1558 11.1946C15.1792 11.2005 15.2025 11.2063 15.2258 11.2063C15.29 11.2063 15.3483 11.183 15.395 11.1363L16.095 10.4305C16.6725 9.85881 16.9525 9.30464 16.9525 8.74464C16.9583 8.16714 16.6783 7.60714 16.095 7.02964Z"
                                                                                    fill="white"
                                                                                />
                                                                                <path
                                                                                    d="M14.1058 11.726C13.9367 11.6443 13.7733 11.5626 13.6158 11.4693C13.4875 11.3935 13.365 11.3118 13.2425 11.2243C13.1433 11.1601 13.0267 11.0668 12.9158 10.9735C12.9042 10.9676 12.8633 10.9326 12.8167 10.886C12.6242 10.7226 12.4083 10.5126 12.2158 10.2793C12.1983 10.2676 12.1692 10.2268 12.1283 10.1743C12.07 10.1043 11.9708 9.98763 11.8833 9.85346C11.8133 9.76596 11.7317 9.63763 11.6558 9.5093C11.5625 9.3518 11.4808 9.1943 11.3992 9.03096C11.2921 8.80152 10.9909 8.73335 10.8119 8.9124L7.53167 12.1926C7.45583 12.2685 7.38583 12.4143 7.36833 12.5135L7.05333 14.7476C6.995 15.1443 7.10583 15.5176 7.35083 15.7685C7.56083 15.9726 7.8525 16.0835 8.1675 16.0835C8.2375 16.0835 8.3075 16.0776 8.3775 16.066L10.6175 15.751C10.7225 15.7335 10.8683 15.6635 10.9383 15.5876L14.224 12.302C14.3994 12.1265 14.3335 11.8246 14.1058 11.726Z"
                                                                                    fill="white"
                                                                                />
                                                                            </svg>
                                                                        </IconButton>
                                                                    }
                                                                >
                                                                    {configJSON.editDelegateBtn}
                                                                </Button>
                                                            </Grid>
                                                        </Grid>
                                                    </div>
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        className="pageTitlesCardOne"
                                                    ></Grid>
                                                </Grid>
                                            </Grid>

                                            <Grid container spacing={2} justifyContent="center">
                                                <Grid item xs={12} className="gridConatineNationality">
                                                    <Grid container justifyContent="space-between" alignItems="center">
                                                        <Grid item>
                                                            <Typography variant="h3" className="messageTextContainCardOneTest" >
                                                                {configJSON.nationality}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item>
                                                            <Typography variant="h3" className="messageText" style={{ fontWeight: 600 }}>
                                                                {this.state.ShowsDeleagteApiData1.nationality}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={2} justifyContent="center">
                                                <Grid item xs={12} className="gridConatineNationality">
                                                    <Grid container justifyContent="space-between" alignItems="center" >
                                                        <Grid item className="messageTextStyle">
                                                            <Typography variant="h3" className="messageTextContainCardOneTest">
                                                                {configJSON.genderLabel}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item>
                                                            <Typography variant="h3" className="messageText" style={{ fontWeight: 600 }}>
                                                                {this.state.ShowsDeleagteApiData1.gender}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={2} justifyContent="center">
                                                <Grid item xs={12} className="gridConatineNationality">
                                                    <Grid container justifyContent="space-between" alignItems="center" >
                                                        <Grid item className="messageTextStyle">
                                                            <Typography variant="h3" className="messageTextContainCardOneTest" >
                                                                {configJSON.emailAddess}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item>
                                                            <Typography variant="h3" className="messageText" style={{ fontWeight: 600 }}>
                                                                {this.state.ShowsDeleagteApiData1.email}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={2} justifyContent="center">
                                                <Grid item xs={12} className="gridConatineNationality">
                                                    <Grid container justifyContent="space-between" alignItems="center" >
                                                        <Grid item className="messageTextStyle">
                                                            <Typography variant="h3" className="messageTextContainCardOneTest">
                                                                {configJSON.phoneNo}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item>
                                                            <Typography variant="h3" className="messageText" style={{ fontWeight: 600 }}>
                                                                {this.state.ShowsDeleagteApiData1.full_phone_number}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={2} justifyContent="center">
                                                <Grid item xs={12} className="gridConatineNationality">
                                                    <Grid container justifyContent="space-between" alignItems="center" >
                                                        <Grid item className="messageTextStyle">
                                                            <Typography variant="h3" className="messageTextContainCardOneTest" >
                                                                {configJSON.dateBirth}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item>
                                                            <Typography variant="h3" className="messageText" style={{ fontWeight: 600 }}>
                                                                {this.state.ShowsDeleagteApiData1.date_of_birth}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>

                                            <Grid container spacing={2} justifyContent="center">
                                                <Grid item xs={12} className="gridConatineNationality">
                                                    <Grid container justifyContent="space-between" alignItems="center" >
                                                        <Grid item className="messageTextStyle">
                                                            <Typography variant="h3" className="messageTextContainCardOneTest" >
                                                                {configJSON.address}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item>
                                                            <Typography variant="h3" className="messageText" style={{ fontWeight: 600 }}>
                                                                {`${this.state.ShowsDeleagteApiData1.address_line_1}, ${this.state.ShowsDeleagteApiData1.address_line_2},
                                         ${this.state.ShowsDeleagteApiData1.landmark},${this.state.ShowsDeleagteApiData1.city},
                                         ${this.state.ShowsDeleagteApiData1.country},${this.state.ShowsDeleagteApiData1.post_code}`}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                    </Card>
                                </>
                            ) : ShowsDeleagteApiData ? (
                                <>
                                    <Card
                                        className="cardShowsDeleagteApiData"
                                    >
                                        <CardContent>
                                            <Grid container className="notesInfoWrapper">
                                                <Grid item xs={12} className="pageTitle">
                                                    <div className="cardShowsDeleagteApiDataCard">
                                                        <Grid container alignItems="center" className="cardShowsDeleagteApiDataContainer" >
                                                            <Grid item xs={12} sm={8} md={6} container direction="column">
                                                                <Grid container alignItems="center" justifyContent="flex-start">
                                                                    <img
                                                                        src={this.state.ShowsDeleagteApiData.photo_url || userProfile}
                                                                        alt="Profile"
                                                                        className="cardShowsDeleagteApiDataCardProfile"
                                                                    />
                                                                    <Grid item style={{ marginLeft: '20px' }}>
                                                                        <Typography variant="h3" className="messageTextMember" style={{ fontWeight: 600 }}>
                                                                            {configJSON.DelegatedMemberone}
                                                                        </Typography>
                                                                        <Typography
                                                                            variant="h3" className="messageTexts"
                                                                        >
                                                                            {`${this.state.ShowsDeleagteApiData.first_name} ${this.state.ShowsDeleagteApiData.middle_name} ${this.state.ShowsDeleagteApiData.last_name}`}
                                                                        </Typography>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>

                                                            <Grid item xs={12} sm={4} md={2} container alignItems="center" justifyContent="center">
                                                                <Button
                                                                    data-test-id="editDetailsBtn"
                                                                    variant="contained"
                                                                    className="buttonSizecardData1"
                                                                    type="button"
                                                                    onClick={() => this.handleDelegateOneBtnClick()}
                                                                    startIcon={
                                                                        <IconButton className="startIconImage">
                                                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                <rect width="24" height="24" rx="12" />
                                                                                <path d="M17.25 17.834H6.75C6.51083 17.834 6.3125 17.6357 6.3125 17.3965C6.3125 17.1573 6.51083 16.959 6.75 16.959H17.25C17.4892 16.959 17.6875 17.1573 17.6875 17.3965C17.6875 17.6357 17.4892 17.834 17.25 17.834Z" fill="white" />
                                                                                <path d="M16.095 7.02964C14.9633 5.89798 13.855 5.86881 12.6942 7.02964L11.9883 7.73548C11.93 7.79381 11.9067 7.88714 11.93 7.96881C12.3733 9.51464 13.61 10.7513 15.1558 11.1946C15.1792 11.2005 15.2025 11.2063 15.2258 11.2063C15.29 11.2063 15.3483 11.183 15.395 11.1363L16.095 10.4305C16.6725 9.85881 16.9525 9.30464 16.9525 8.74464C16.9583 8.16714 16.6783 7.60714 16.095 7.02964Z" fill="white" />
                                                                                <path d="M14.1058 11.726C13.9367 11.6443 13.7733 11.5626 13.6158 11.4693C13.4875 11.3935 13.365 11.3118 13.2425 11.2243C13.1433 11.1601 13.0267 11.0668 12.9158 10.9735C12.9042 10.9676 12.8633 10.9326 12.8167 10.886C12.6242 10.7226 12.4083 10.5126 12.2158 10.2793C12.1983 10.2676 12.1692 10.2268 12.1283 10.1743C12.07 10.1043 11.9708 9.98763 11.8833 9.85346C11.8133 9.76596 11.7317 9.63763 11.6558 9.5093C11.5625 9.3518 11.4808 9.1943 11.3992 9.03096C11.2921 8.80152 10.9909 8.73335 10.8119 8.9124L7.53167 12.1926C7.45583 12.2685 7.38583 12.4143 7.36833 12.5135L7.05333 14.7476C6.995 15.1443 7.10583 15.5176 7.35083 15.7685C7.56083 15.9726 7.8525 16.0835 8.1675 16.0835C8.2375 16.0835 8.3075 16.0776 8.3775 16.066L10.6175 15.751C10.7225 15.7335 10.8683 15.6635 10.9383 15.5876L14.224 12.302C14.3994 12.1265 14.3335 11.8246 14.1058 11.726Z" fill="white" />
                                                                            </svg>
                                                                        </IconButton>
                                                                    }
                                                                >
                                                                    {configJSON.editDelegateBtn}
                                                                </Button>
                                                            </Grid>
                                                        </Grid>
                                                    </div>
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        className="pageTitlesCardThre"
                                                    ></Grid>
                                                </Grid>
                                            </Grid>

                                            <Grid container spacing={2} justifyContent="center">
                                                <Grid item xs={12} className="pageTitlesCardThreContain" >
                                                    <Grid container justifyContent="space-between" alignItems="center">
                                                        <Grid item>
                                                            <Typography variant="h3" className="pageTitlesCardThreMessageText" >
                                                                {configJSON.nationality}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item>
                                                            <Typography variant="h3" className="messageText" style={{ fontWeight: 600 }}>
                                                                {this.state.ShowsDeleagteApiData.nationality}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={2} justifyContent="center">
                                                <Grid item xs={12} className="pageTitlesCardThreContain">
                                                    <Grid container justifyContent="space-between" alignItems="center" >
                                                        <Grid item className="messageTextStyle">
                                                            <Typography variant="h3" className="pageTitlesCardThreMessageText">
                                                                {configJSON.genderLabel}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item>
                                                            <Typography variant="h3" className="messageText" style={{ fontWeight: 600 }}>
                                                                {this.state.ShowsDeleagteApiData.gender}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={2} justifyContent="center">
                                                <Grid item xs={12} className="pageTitlesCardThreContain">
                                                    <Grid container justifyContent="space-between" alignItems="center" >
                                                        <Grid item className="messageTextStyle">
                                                            <Typography variant="h3" className="pageTitlesCardThreMessageText">
                                                                {configJSON.emailAddess}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item>
                                                            <Typography variant="h3" className="messageText" style={{ fontWeight: 600 }}>
                                                                {this.state.ShowsDeleagteApiData.email}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={2} justifyContent="center">
                                                <Grid item xs={12} className="pageTitlesCardThreContain">
                                                    <Grid container justifyContent="space-between" alignItems="center" >
                                                        <Grid item className="messageTextStyle">
                                                            <Typography variant="h3" className="pageTitlesCardThreMessageText">
                                                                {configJSON.phoneNo}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item>
                                                            <Typography variant="h3" className="messageText" style={{ fontWeight: 600 }}>
                                                                {this.state.ShowsDeleagteApiData.full_phone_number}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={2} justifyContent="center">
                                                <Grid item xs={12} className="pageTitlesCardThreContain">
                                                    <Grid container justifyContent="space-between" alignItems="center" >
                                                        <Grid item className="messageTextStyle">
                                                            <Typography variant="h3" className="pageTitlesCardThreMessageText">
                                                                {configJSON.dateBirth}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item>
                                                            <Typography variant="h3" className="messageText" style={{ fontWeight: 600 }}>
                                                                {this.state.ShowsDeleagteApiData.date_of_birth}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>

                                            <Grid container spacing={2} justifyContent="center">
                                                <Grid item xs={12} className="pageTitlesCardThreContain">
                                                    <Grid container justifyContent="space-between" alignItems="center" >
                                                        <Grid item className="messageTextStyle">
                                                            <Typography variant="h3" className="pageTitlesCardThreMessageText">
                                                                {configJSON.address}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item>
                                                            <Typography variant="h3" className="messageText" style={{ fontWeight: 600 }}>
                                                                {`${this.state.ShowsDeleagteApiData.address_line_1}, ${this.state.ShowsDeleagteApiData.address_line_2},
                                                 ${this.state.ShowsDeleagteApiData.landmark},${this.state.ShowsDeleagteApiData.city},
                                                 ${this.state.ShowsDeleagteApiData.country},${this.state.ShowsDeleagteApiData.post_code}`}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                    </Card>
                                </>
                            ) : ShowsDeleagteApiData1 ? (
                                <>
                                    <Card style={{
                                        border: '0px solid #E0E0E0',
                                        borderRadius: '8px',
                                        opacity: 0.8,
                                        marginTop: "16px"
                                    }}>
                                        <CardContent>
                                            <Grid container className="notesInfoWrapper">
                                                <Grid item xs={12} className="pageTitle">
                                                    <div style={{ marginLeft: '20px', marginRight: '20px' }}>
                                                        <Grid container alignItems="center" style={{ justifyContent: 'space-between' }}>
                                                            <Grid item xs={12} sm={8} md={6} container direction="column">
                                                                <Grid container alignItems="center" justifyContent="flex-start">
                                                                    <img
                                                                        src={this.state.ShowsDeleagteApiData1.photo_url || userProfile}
                                                                        alt="Profile"
                                                                        style={{
                                                                            borderRadius: '50%',
                                                                            width: '62px',
                                                                            height: '62px',
                                                                        }}
                                                                    />
                                                                    <Grid item style={{ marginLeft: '20px' }}>
                                                                        <Typography variant="h3" className="messageTextMember" style={{ fontWeight: 600 }}>
                                                                            {configJSON.DelegatedMembertwo}
                                                                        </Typography>
                                                                        <Typography
                                                                            variant="h3" className="messageTexts" style={{ fontWeight: 600, marginTop: '10px' }}
                                                                        >
                                                                            {`${this.state.ShowsDeleagteApiData1.first_name} ${this.state.ShowsDeleagteApiData1.middle_name} ${this.state.ShowsDeleagteApiData1.last_name}`}
                                                                        </Typography>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>

                                                            <Grid item xs={12} sm={4} md={2} container alignItems="center" justifyContent="center">
                                                                <Button
                                                                    data-test-id="editDetailsBtn1"
                                                                    variant="contained"
                                                                    className="buttonSize"
                                                                    style={{
                                                                        backgroundColor: '#A190C2',
                                                                    }}
                                                                    type="button"
                                                                    onClick={() => this.handleDelegateTwoBtnClick()}
                                                                    startIcon={
                                                                        <IconButton className="startIconImage">
                                                                            <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                <rect width="23" height="23" rx="12" />
                                                                                <path d="M17.25 17.834H6.75C6.51083 17.834 6.3125 17.6357 6.3125 17.3965C6.3125 17.1573 6.51083 16.959 6.75 16.959H17.25C17.4892 16.959 17.6875 17.1573 17.6875 17.3965C17.6875 17.6357 17.4892 17.834 17.25 17.834Z" fill="white" />
                                                                                <path d="M16.095 7.02964C14.9633 5.89798 13.855 5.86881 12.6942 7.02964L11.9883 7.73548C11.93 7.79381 11.9067 7.88714 11.93 7.96881C12.3733 9.51464 13.61 10.7513 15.1558 11.1946C15.1792 11.2005 15.2025 11.2063 15.2258 11.2063C15.29 11.2063 15.3483 11.183 15.395 11.1363L16.095 10.4305C16.6725 9.85881 16.9525 9.30464 16.9525 8.74464C16.9583 8.16714 16.6783 7.60714 16.095 7.02964Z" fill="white" />
                                                                                <path d="M14.1058 11.726C13.9367 11.6443 13.7733 11.5626 13.6158 11.4693C13.4875 11.3935 13.365 11.3118 13.2425 11.2243C13.1433 11.1601 13.0267 11.0668 12.9158 10.9735C12.9042 10.9676 12.8633 10.9326 12.8167 10.886C12.6242 10.7226 12.4083 10.5126 12.2158 10.2793C12.1983 10.2676 12.1692 10.2268 12.1283 10.1743C12.07 10.1043 11.9708 9.98763 11.8833 9.85346C11.8133 9.76596 11.7317 9.63763 11.6558 9.5093C11.5625 9.3518 11.4808 9.1943 11.3992 9.03096C11.2921 8.80152 10.9909 8.73335 10.8119 8.9124L7.53167 12.1926C7.45583 12.2685 7.38583 12.4143 7.36833 12.5135L7.05333 14.7476C6.995 15.1443 7.10583 15.5176 7.35083 15.7685C7.56083 15.9726 7.8525 16.0835 8.1675 16.0835C8.2375 16.0835 8.3075 16.0776 8.3775 16.066L10.6175 15.751C10.7225 15.7335 10.8683 15.6635 10.9383 15.5876L14.224 12.302C14.3994 12.1265 14.3335 11.8246 14.1058 11.726Z" fill="white" />
                                                                            </svg>
                                                                        </IconButton>
                                                                    }
                                                                >
                                                                    {configJSON.editDelegateBtn}
                                                                </Button>
                                                            </Grid>
                                                        </Grid>
                                                    </div>
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        className="pageTitles"
                                                    ></Grid>
                                                </Grid>
                                            </Grid>

                                            <Grid container spacing={2} justifyContent="center">
                                                <Grid item xs={12} style={{ marginLeft: '20px', marginRight: '20px' }}>
                                                    <Grid container justifyContent="space-between" alignItems="center">
                                                        <Grid item>
                                                            <Typography variant="h3" className="messageText" style={{ fontWeight: 600, color: "#64748B", }}>
                                                                {configJSON.nationality}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item>
                                                            <Typography variant="h3" className="messageText" style={{ fontWeight: 600 }}>
                                                                {this.state.ShowsDeleagteApiData1.nationality}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={2} justifyContent="center">
                                                <Grid item xs={12} style={{ marginLeft: '20px', marginRight: '20px' }}>
                                                    <Grid container justifyContent="space-between" alignItems="center" >
                                                        <Grid item className="messageTextStyle">
                                                            <Typography variant="h3" className="messageText" style={{ fontWeight: 600, color: "#64748B", }}>
                                                                {configJSON.genderLabel}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item>
                                                            <Typography variant="h3" className="messageText" style={{ fontWeight: 600 }}>
                                                                {this.state.ShowsDeleagteApiData1.gender}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={2} justifyContent="center">
                                                <Grid item xs={12} style={{ marginLeft: '20px', marginRight: '20px' }}>
                                                    <Grid container justifyContent="space-between" alignItems="center" >
                                                        <Grid item className="messageTextStyleCarddata">
                                                            <Typography variant="h3" className="messageText" style={{ fontWeight: 600, color: "#64748B", }}>
                                                                {configJSON.emailAddess}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item>
                                                            <Typography variant="h3" className="messageText" style={{ fontWeight: 600 }}>
                                                                {this.state.ShowsDeleagteApiData1.email}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={2} justifyContent="center">
                                                <Grid item xs={12} style={{ marginLeft: '20px', marginRight: '20px' }}>
                                                    <Grid container justifyContent="space-between" alignItems="center" >
                                                        <Grid item className="messageTextStyle">
                                                            <Typography variant="h3" className="messageText" style={{ fontWeight: 600, color: "#64748B", }}>
                                                                {configJSON.phoneNo}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item>
                                                            <Typography variant="h3" className="messageText" style={{ fontWeight: 600 }}>
                                                                {this.state.ShowsDeleagteApiData1.full_phone_number}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={2} justifyContent="center">
                                                <Grid item xs={12} style={{ marginLeft: '20px', marginRight: '20px' }}>
                                                    <Grid container justifyContent="space-between" alignItems="center" >
                                                        <Grid item className="messageTextStyleCarddata">
                                                            <Typography variant="h3" className="messageText" style={{ fontWeight: 600, color: "#64748B", }}>
                                                                {configJSON.dateBirth}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item>
                                                            <Typography variant="h3" className="messageText" style={{ fontWeight: 600 }}>
                                                                {this.state.ShowsDeleagteApiData1.date_of_birth}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>

                                            <Grid container spacing={2} justifyContent="center">
                                                <Grid item xs={12} style={{ marginLeft: '20px', marginRight: '20px' }}>
                                                    <Grid container justifyContent="space-between" alignItems="center" >
                                                        <Grid item className="messageTextStyleCarddata">
                                                            <Typography variant="h3" className="messageText" style={{ fontWeight: 600, color: "#64748B", }}>
                                                                {configJSON.address}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item>
                                                            <Typography variant="h3" className="messageText" style={{ fontWeight: 600 }}>
                                                                {`${this.state.ShowsDeleagteApiData1.address_line_1}, ${this.state.ShowsDeleagteApiData1.address_line_2},
                                                 ${this.state.ShowsDeleagteApiData1.landmark},${this.state.ShowsDeleagteApiData1.city},
                                                 ${this.state.ShowsDeleagteApiData1.country},${this.state.ShowsDeleagteApiData1.post_code}`}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                    </Card>
                                </>
                            ) : (
                                <Typography variant="h6" style={{ textAlign: 'center', backgroundColor:"red" }}>
                                    No delegate data available.
                                </Typography>
                            )}


                        </Box>
                        <WebFooter handleNavigation={this.handleNavigation} />
                    </Wrapper>
                </ThemeProvider>
            </>
        );
        // Customizable Area End
    }
}


// Customizable Area Start
const Wrapper = styled("div")(({ theme }) => ({
    "& .container": {
        padding: "50px 72px",
    },

    "& .notesInfoWrapper": {
        width: '100%',
    },
    "& .buttonSize": {
        width: "250px",
        height: "48px",
    },
    "& .buttonSizecardData1": {
        width: "250px",
        height: "48px",
        backgroundColor: '#A190C2',
    },
    "& .buttonSizecardIcon": {
        padding: '0'
    },

    "& .buttonSizeFull": {
        width: "250px",
        height: "48px",
        backgroundColor: '#A190C2',
    },
    "& .buttonSizeFulls": {
        width: "250px",
        height: "48px",
        backgroundColor: '#A190C2',
    },
    "& .startIconImage": {
        padding: '0'
    },

    "& .form": {
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
    },
    "& .pageTitle": {
        margin: "32px 0",
        display: "flex",
        flexDirection: "column",
        gap: "8px",
    },
    "& .pageTitlesCardThre": {
        display: "flex",
        flexDirection: "column",
        marginLeft: '20px',
        marginRight: '20px',
        marginTop: '5px',
        borderBottom: '2px dotted rgb(71, 85, 105)',
    },
    "& .pageTitlesCardThreContain": {
        marginLeft: '20px', marginRight: '20px'
    },
    "& .pageTitlesCardThreMessageText": {
        fontWeight: 600, color: "#64748B"
    },

    "& .pageTitles": {
        display: "flex",
        flexDirection: "column",
        marginLeft: '20px',
        marginRight: '20px',
        marginTop: '5px',
        borderBottom: '2px dotted rgb(71, 85, 105)',
    },
    "& .pageTitlesCard": {
        display: "flex",
        flexDirection: "column",
        marginLeft: '20px',
        marginRight: '20px',
        marginTop: '5px',
        borderBottom: '2px dotted rgb(71, 85, 105)',
    },
    "& .pageTitlesCardOne": {
        display: "flex",
        flexDirection: "column",
        marginLeft: '20px',
        marginRight: '20px',
        marginTop: '5px',
        borderBottom: '2px dotted rgb(71, 85, 105)',
    },

    "& .messageText": {
        fontSize: "16px",
        fontFamily: "Lato",
        color: "#000",
        fontWeight: 400,
        lineHeight: "18px",
    },

    "& .messageTextContainCardOneTest": {
        fontSize: "16px",
        fontFamily: "Lato",
        fontWeight: 600,
        lineHeight: "18px",
        color: "#64748B"
    },
    "& .cardShowsDeleagteApiData": {
        border: '0px solid #E0E0E0',
        borderRadius: '8px',
        opacity: 0.8,
    },
    "& .cardShowsDeleagteApiDataCard": {
        marginLeft: '20px', marginRight: '20px'
    },
    "& .cardShowsDeleagteApiDataContainer": {
        justifyContent: 'space-between'
    },
    "& .cardShowsDeleagteApiDataCardProfile": {
        borderRadius: '50%',
        width: '62px',
        height: '62px',
    },
    "& .cardShowsDeleagteApimessageTexts": {
        fontWeight: 600, marginTop: '10px'
    },

    "& .messageTextMemberText": {
        fontSize: "16px",
        fontFamily: "Lato",
        color: "#000",
        fontWeight: 600,
        lineHeight: "18px",
        marginTop: '10px'
    },
    "& .messageTextMember": {
        fontSize: "14px",
        fontFamily: "Lato",
        color: "#000",
        fontWeight: 600,
        lineHeight: "18px",
    },
    "& .messageTexts": {
        fontSize: "18px",
        fontFamily: "Lato",
        color: "#000",
        fontWeight: 600,
        lineHeight: "18px",
        marginTop: '10px'
    },
    "& .messageTextStyle": {
        paddingTop: "24px"
    },
    "& .messageTextStyleCarddata": {
        paddingTop: "24px"
    },
    "& .cardContain": {
        border: '0px solid #E0E0E0',
        borderRadius: '8px',
        opacity: 0.8,
    },
    "& .cardContainSecond": {
        border: '0px solid #E0E0E0',
        borderRadius: '8px',
        opacity: 0.8,
    },
    "& .cardContainSecondDives": {
        marginLeft: '20px', marginRight: '20px'
    },
    "& .cardContainSecondDivesContainer": {
        justifyContent: 'space-between'
    },
    "& .cardContainImages": {
        borderRadius: '50%',
        width: '62px',
        height: '62px',
    },
    "& .cardContainMainCard": {
        marginLeft: '20px'
    },
    "& .cardContainDive": {
        marginLeft: '20px', marginRight: '20px'
    },
    "& .cardGridContainer": {
        justifyContent: 'space-between'
    },
    "& .cardUserProfileContainer": {
        borderRadius: '50%',
        width: '62px',
        height: '62px',
    },
    "& .cardUserProfileGridContainer": {
        marginLeft: '20px'
    },
    "& .gridConatineNationality": {
        marginLeft: '20px', marginRight: '20px'
    },
    "& .startIconStyle": {
        padding: '0'
    },
    "& .nationalitycard": {
        marginLeft: '20px', marginRight: '20px'
    },
    "& .addresscard": {
        marginLeft: '20px', marginRight: '20px'
    },
    "& .dobcard": {
        marginLeft: '20px', marginRight: '20px'
    },
    "& .nationalityFullnocard": {
        marginLeft: '20px', marginRight: '20px'
    },

    "& .nationalitycardConatiner": {
        justifyContent: 'space-between', alignItems: 'center'
    },
    "& .messageTextnationality": {
        fontWeight: 600, color: "#64748B",
        fontSize: "16px",
        fontFamily: "Lato",
        lineHeight: "18px",
    },




}));
// Customizable Area End