import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { sendAPIRequest} from "../../../components/src/Utils";
import { getStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface Media {
  id: number
  file_name: string
  content_type: string
  file_size: string
  url: string
}

interface S {
  // Customizable Area Start
  mediaFiles: Media[]
  isVideo: boolean
  mediaBreadCrumb: {
    label: string;
    path: string
  }[],
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class GeoPhotoVideoController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getMediaAPICallID: string =""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      mediaFiles:[],
      isVideo:false,
      mediaBreadCrumb: [
        { label: 'Home', path: 'HomePage'}],
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    const requestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const response = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
    // Customizable Area Start
    if(requestCallId === this.getMediaAPICallID){
     if(response.videos || response.photos){
          let mediaFiles = this.state.isVideo ? response.videos : response.photos
            this.setState({mediaFiles})
        }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    this.getUserMedia()
  }

  getUserMedia=async ()=>{
    let isVideo = window.location.href.toLowerCase().includes("mapvideos")
    this.handleBreadcurmb(isVideo)
    const token = await getStorageData("token");
    let endPoint = isVideo ? configJSON.videoEndPoint : configJSON.photosEndPoint
    this.getMediaAPICallID = sendAPIRequest(
      endPoint,
      {
        method: configJSON.validationApiMethodType,
        headers: {
          token,
        }
      }
    );
  }

  handleBreadcurmb = (isVideo: boolean) =>{
    let breadcurm = isVideo ? { label: 'Videos', path: 'MapVideos'} : { label: 'Photos', path: 'MapPhotos'}
    this.setState((prevState) =>({
      mediaBreadCrumb : [...prevState.mediaBreadCrumb , breadcurm],
      isVideo
    }))
  }

  handleNavigation = (routeUrl: string) => {
    const navigationMessage = new Message(getName(MessageEnum.NavigationMessage));
    navigationMessage.addData(getName(MessageEnum.NavigationTargetMessage), routeUrl);
    navigationMessage.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(navigationMessage);
  };
  // Customizable Area End
}
