import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { sendAPIRequest } from "../../../components/src/Utils";
import { getStorageData } from "../../../framework/src/Utilities";
import { format } from 'date-fns';
import moment from "moment";
export interface ICheckoutApiResponse {
    id: string,
    object: string,
    after_expiration: string | null,
    allow_promotion_codes: string | null,
    amount_subtotal: number,
    amount_total: number,
    automatic_tax: {
        enabled: boolean,
        liability: string | null,
        status: string | null,
    },
    billing_address_collection: string | null,
    cancel_url: string,
    client_reference_id: string | null,
    client_secret: string | null,
    consent: string | null,
    consent_collection: string | null,
    created: number,
    currency: string,
    currency_conversion: string | null,
    custom_fields: [],
    custom_text: {
        after_submit: string | null,
        shipping_address: string | null,
        submit: string | null,
        terms_of_service_acceptance: string | null,
    },
    customer: string | null,
    customer_creation: string,
    customer_details: {
        address: string | null,
        email: string | null,
        name: string | null,
        phone: string | null,
        tax_exempt: string | null,
        tax_ids: string | null,
    },
    customer_email: string,
    expires_at: number,
    invoice: string | null,
    invoice_creation: string | null,
    livemode: boolean,
    locale: string | null,
    metadata: {},
    mode: string,
    payment_intent: string | null,
    payment_link: string | null,
    payment_method_collection: string,
    payment_method_configuration_details: string | null,
    payment_method_options: {
        card: {
            request_three_d_secure: string | null
        }
    },
    payment_method_types: [],
    payment_status: string,
    phone_number_collection: {
        enabled: boolean,
    },
    recovered_from: string | null,
    saved_payment_method_options: {
        allow_redisplay_filters: [],
        payment_method_remove: string | null,
        payment_method_save: string | null,
    },
    setup_intent: string | null,
    shipping_address_collection: string | null,
    shipping_cost: string | null,
    shipping_details: string | null,
    shipping_options: [],
    status: string,
    submit_type: string | null,
    subscription: string | null,
    success_url: string,
    total_details: {
        amount_discount: number,
        amount_shipping: number,
        amount_tax: number
    },
    ui_mode: string,
    url: string,
};

export interface ISubscriptionPlanDetails {
    id: number,
    plan_id: number,
    name: string,
    price: string,
    plan_type: string,
    currency_name: string,
    description: string,
    image_link: string,
    activated: boolean,
    valid_up_to: string,
    features: IFeatures,
  };

export interface IFeatures {
    max_delegates: number,
    max_wills: number,
    max_medical_documents: number,
    max_investment_documents: number,
    max_pets: number,
    max_closest_friends: number,
    max_group_memories: number,
    max_family_members: any,
    max_memories: number,
    max_memory_uploads: number,
    max_festival_memories: number,
    max_voice_notes: number,
    max_personal_letters: number,
    max_favourite_outfits: number,
    max_hobbies: number,
    max_achievements: number,
    max_hair_uploads: number,
    max_makeup_uploads: number,
    max_nails_uploads: number,
    max_map_pins: number,
    is_cv_upload: true,
    is_death_delegates_will_receive: true,
};
  
  export interface IGetSubscriptionPlanAPIResponse {
    id: string,
    type: string,
    attributes: ISubscriptionPlanDetails,
  }
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    subscriptionPlan: ISubscriptionPlanDetails;
    changeSubscription: boolean;
    cancelledSubscription: boolean;
    // Customizable Area End
}

interface SS {
    id: any;
}

export default class Subscription extends BlockComponent<
    Props,
    S,
    SS
> {

    // Customizable Area Start
    getSubscriptionPlanCallId: string = "";
    cancelSubscriptionPlanCallId: string = "";
    getPreviousSubscriptionPlanCallId: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.RestAPIRequestMessage),
            getName(MessageEnum.RestAPIResponceMessage)
        ];

        this.state = {
            subscriptionPlan: {
                id: 0,
                plan_id: 1,
                name: "-",
                price: "",
                plan_type: "-",
                currency_name: "",
                description: "",
                image_link: "",
                activated: true,
                valid_up_to: "",
                features: {
                    max_delegates: 2,
                    max_wills: 1,
                    max_medical_documents: 3,
                    max_investment_documents: 2,
                    max_pets: 2,
                    max_closest_friends: 3,
                    max_group_memories: 1,
                    max_family_members: 3,
                    max_memories: 3,
                    max_memory_uploads: 3,
                    max_festival_memories: 3,
                    max_voice_notes: 2,
                    max_personal_letters: 3,
                    max_favourite_outfits: 2,
                    max_hobbies: 2,
                    max_achievements: 2,
                    max_hair_uploads: 1,
                    max_makeup_uploads: 1,
                    max_nails_uploads: 1,
                    max_map_pins: 2,
                    is_cv_upload: true,
                    is_death_delegates_will_receive: true,
                  },
            },
            changeSubscription: false,
            cancelledSubscription: false,
        };
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
        const responseJSON = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
        this.apiSuccessCallBackController(apiRequestCallId, responseJSON);
        // Customizable Area End
    }

    async componentDidMount() {
        super.componentDidMount();
        // Customizable Area Start
        this.getSubscriptionPlanList();
        // Customizable Area End
    }

    // Customizable Area Start
    getSubscriptionPlanList = async () => {
        const token = await getStorageData("token");

        this.getSubscriptionPlanCallId = sendAPIRequest(
            configJSON.getSubscriptionPlanApiEndPoint,
            {
                method: configJSON.getApiMethodType,
                headers: {
                    token: token,
                },
            }
        )
    }

    apiSuccessCallBackController = (
        apiRequestCallId: string,
        responseJSON: Record<string, unknown>
    ) => {
        const successCallbackMap = {
            [this.getSubscriptionPlanCallId]: this.handleGetSubscriptionPlanApiResponse,
            [this.cancelSubscriptionPlanCallId]: this.handleCancelSubscriptionPlanApiResponse,
            [this.getPreviousSubscriptionPlanCallId]: this.handleGetPreviousSubscriptionPlanApiResponse,
        }

        if (apiRequestCallId) {
            const successCallback: (responseJSON: Record<string, unknown>) => void = successCallbackMap[apiRequestCallId]
            !!successCallback && successCallback(responseJSON)
        }
    }

    handleGetSubscriptionPlanApiResponse = (responseJSON: Record<string, unknown>) => {
        if (this.handleErrorResponse(responseJSON)) return;

        const response = responseJSON as {
            meta?: { message: string };
            data?: IGetSubscriptionPlanAPIResponse;
        };

        if (response.data) {
            const attributes = response.data.attributes;

            let subscriptionPlan = {
                id: attributes.id,
                plan_id: attributes.plan_id,
                name: attributes.name,
                price: attributes.price,
                plan_type: attributes.plan_type,
                currency_name: attributes.currency_name,
                description: attributes.description,
                image_link: attributes.image_link,
                activated: attributes.activated,
                valid_up_to: attributes.valid_up_to,
                features: attributes.features,
            }
            this.setState({ 
                subscriptionPlan,
                cancelledSubscription: !attributes.activated, 
            });
        }
        else{
            this.setState({ cancelledSubscription: true });
            this.getPreviousSubscriptionPlanData();
        }
    };

    handleErrorResponse = (responseJSON: Record<string, unknown>) => {
        const { errors: possibleErrors } = responseJSON;
        if (possibleErrors) {
            return true; // Indicates that there was an error
        }
        return false; // Indicates that there was no error
    };

    handleCancelSubscriptionClick = async() => {
        const token = await getStorageData("token");

        this.cancelSubscriptionPlanCallId = sendAPIRequest(
            `${configJSON.cancelSubscriptionPlanApiEndPoint}${this.state.subscriptionPlan.id}`,
            {
                method: configJSON.deleteApiMethodType,
                headers: {
                    token: token,
                },
            }
        )
    };

    handleChangeSubscriptionClick = () => {
        if(this.state.changeSubscription || this.state.cancelledSubscription){
            this.handleNavigation("Customisableusersubscriptions");
        }
        else{
            this.setState({ changeSubscription: true });
        }
    };

    handleCancelSubscriptionPlanApiResponse = (responseJSON: Record<string, unknown>) => {
        if (this.handleErrorResponse(responseJSON)) return;

        const response = responseJSON as {
            message: string;
        };

        if (response.message) {
            this.setState({ 
                cancelledSubscription: true, 
                changeSubscription: false 
            });
        }
    };

    getPreviousSubscriptionPlanData = async() => {
        const token = await getStorageData("token");

        this.getPreviousSubscriptionPlanCallId = sendAPIRequest(
            configJSON.getPreviousSubscriptionPlanApiEndPoint,
            {
                method: configJSON.getApiMethodType,
                headers: {
                    token: token,
                },
            }
        )
    };

    handleGetPreviousSubscriptionPlanApiResponse = (responseJSON: Record<string, unknown>) => {
        if (this.handleErrorResponse(responseJSON)) return;

        const response = responseJSON as {
            data: IGetSubscriptionPlanAPIResponse[];
        };

        if (response.data.length > 0) {
            const length = response.data.length;
            const attributes = response.data[length - 1].attributes;

            let subscriptionPlan = {
                id: attributes.id,
                plan_id: attributes.plan_id,
                name: attributes.name,
                price: attributes.price,
                plan_type: attributes.plan_type,
                currency_name: attributes.currency_name,
                description: attributes.description,
                image_link: attributes.image_link,
                activated: attributes.activated,
                valid_up_to: attributes.valid_up_to,
                features: attributes.features,
            }
            this.setState({ 
                subscriptionPlan, 
            });
        }
    }; 

    getBtnText = () => {
        if(this.state.cancelledSubscription ){
            return "Renew Subscription"
        }
        else if(this.state.changeSubscription){
            return "Upgrade Plan";
        }
        else {
            return "Change Subscription";
        }
    };

    getNextBillingDate = () => {
        if (this.state.subscriptionPlan.valid_up_to) {
            return moment(this.state.subscriptionPlan.valid_up_to).format("D MMMM yyyy");
        }
        else{
            return "-";
        }
    };

    handleNavigation = (route: string) => {
        const message = new Message(getName(MessageEnum.NavigationMessage));
        message.addData(getName(MessageEnum.NavigationTargetMessage), route);
        message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(message);
    };
    // Customizable Area End
}
