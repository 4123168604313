import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import * as Yup from "yup";

// Customizable Area Start
import React from "react";
import { getStorageData } from "../../../framework/src/Utilities";
import { sendAPIRequest } from "../../../components/src/Utils";



export interface IMoment {
    moment: string;
    description: string;
    files: File[]
};

export interface IProudMoment {
    proudMoment: IMoment[]
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    handleError: (error: string) => void
    handleSuccess: () => void
    handleNext: (path: string) => void
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    aboutMoment: IProudMoment
    isLoading: boolean
    // Customizable Area End
}

interface SS {
    id: any;
}

export default class AboutMomentController extends BlockComponent<
    Props,
    S,
    SS
> {
    maxFileSize: number = 15 * 1024 * 1024;

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
        ];

        this.state = {
            aboutMoment: {
                proudMoment: [{
                    moment: '',
                    description: '',
                    files: [],
                }]
            },
            isLoading: false
        };
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        runEngine.debugLog("Message Recived", message);
        const apiRequestCallId = message.getData(
            getName(MessageEnum.RestAPIResponceDataMessage)
        );
        const responseJSON = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        if (apiRequestCallId === this.callAboutMomentInfoApiId) {
            this.setState({ isLoading: false })
            if (responseJSON.data) {
                this.props.handleSuccess()
            }
            else {
                let proudError = responseJSON?.errors[0]
                if (proudError) {
                    let key = Object.keys(proudError)[0]
                    let message = key + " " + proudError[key]
                    this.props.handleError(message.replace("_", " "))
                }
            }
        }
        // Customizable Area End
    }

    // Customizable Area Start
    callAboutMomentInfoApiId: string = "";

    async componentDidMount() {
        super.componentDidMount();
    }


    handleAboutMomentInformationsFormSubmit = async (values: IProudMoment) => {
        const token = await getStorageData("token");
        const formData = new FormData();
        this.setState({ isLoading: true })
        values.proudMoment.forEach((_moment) => {
            Object.entries(_moment).forEach(([keyName, value]) => {
                if (value) {
                    if (keyName === 'files' && _moment.files.length > 0) {
                        _moment.files.forEach((file: File) => {
                            formData.append(`moments[][${keyName}][]`, file as Blob);
                        });
                    }
                    else {
                        formData.append(`moments[][${keyName}]`, value as string);
                    }
                }
            })
        });

        this.callAboutMomentInfoApiId = sendAPIRequest(
            configJSON.proudestMomentEndPoint,
            {
                method: configJSON.formAPiMethod,
                headers: {
                    token,
                },
                body: formData,
            }
        );
    };


    handleProudFileUpload = async (event: React.ChangeEvent<HTMLInputElement>, setFieldValue:
        {
            (field: string,
                value: any,
                shouldValidate?: boolean | undefined): void;
            (arg0: string, arg1: string): void;
        },
        index: number,
        prevFiles: File[]) => {
        if (event.target.files) {
            const proudFiles = Array.from(event.target.files);
            const oversizedFiles = proudFiles.filter(file => file.size > this.maxFileSize);
            if (oversizedFiles.length > 0) {
                this.props.handleError(configJSON.maxFileSizeError)
                return
            }
            let files = [...prevFiles, ...proudFiles]
            setFieldValue(`proudMoment.${index}.files`, files)
        }
    };
    // Customizable Area End
}
