import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import * as Yup from "yup";

// Customizable Area Start
import React from "react";
import { getStorageData } from "../../../framework/src/Utilities";
import { sendAPIRequest } from "../../../components/src/Utils";



export interface ITattoo{
     body_part: string, files: File[] 
}

export interface IPhysical {
    height_in_feet: string,
            height_in_inch: string,
            eye_colour: string,
            hair_colour: string,
            visible_mark: string,
            have_any_tattoo: boolean,
            tattoo_details_attributes: ITattoo[]
};
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  handleError: (error: string)=> void
  handleSuccess: () => void
  handleNext: (path: string) => void
  // Customizable Area End
}

interface S {
  // Customizable Area Start
physical: IPhysical
isLoading: boolean
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class AboutPhysicalController extends BlockComponent<
  Props,
  S,
  SS
> {
  maxFileSize : number = 15 * 1024 * 1024;

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
        physical: {
            height_in_feet: '',
            height_in_inch: '',
            eye_colour: '',
            hair_colour: '',
            visible_mark: '',
            have_any_tattoo: false,
            tattoo_details_attributes: [{ body_part: '', files: [] }],
          },
          isLoading: false
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJSON = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    this.apiSuccessCallBackController(apiRequestCallId, responseJSON);
    // Customizable Area End
  }

  // Customizable Area Start
  callGetDelegateMembersApiId: string = "";
  callAddVoiceNotesInfoApiId: string = "";

  async componentDidMount() {
    super.componentDidMount();
    this.getDelegateMembers();
  }

  apiSuccessCallBackController = (
    apiRequestCallId: string,
    responseJSON: Record<string, unknown>
  ) => {
    const successCallbackMap = {
      [this.callAddVoiceNotesInfoApiId]: this.handleAddVoiceNotesInfoApiResponse,
    };

    if (apiRequestCallId) {
      const successCallback: (responseJSON: Record<string, unknown>) => void =
        successCallbackMap[apiRequestCallId];
      !!successCallback && successCallback(responseJSON);
    }
  };

  handleErrorResponse = (responseJSON: Record<string, unknown>) => {
    const { errors: possibleErrors } = responseJSON;
    if (possibleErrors) {
      return true;
    }
    return false;
  };

  getDelegateMembers = async () => {
    const token = await getStorageData("token");

    this.callGetDelegateMembersApiId = sendAPIRequest(
        configJSON.getDelegateMembersApiEndPoint,
        {
          method: configJSON.fetchFormDataMethod,
          headers: {
            token,
          },
        }
      );
  };


 

  handleAddVoiceNotesInfoApiResponse = (responseJSON: Record<string, unknown>) => {
    if (this.handleErrorResponse(responseJSON)) { 
      return; 
    }
    this.setState({isLoading: false})
    if (responseJSON.data) {
    this.props.handleSuccess()
    }
  };

  handleAboutPhysicalInformationsFormSubmit = async (values: IPhysical) => {
    const token = await getStorageData("token");
    const formData = new FormData();
    this.setState({isLoading: true})
    Object.entries(values).forEach(([keyName, value]) => {
        if(keyName !== "tattoo_details_attributes"){
          formData.append(`physcial[${keyName}]`, value);
        }
      });
  
      values.tattoo_details_attributes.forEach((tattoo) => {
        Object.entries(tattoo).forEach(([keyName, value]) => {
          if (value) {
            if(keyName === 'files' && tattoo.files.length > 0){
                tattoo.files.forEach((file: File) => {
                    formData.append(`physcial[tattoo_details_attributes][][${keyName}][]`, file as Blob);
                  });
              }
            else{
              formData.append(`physcial[tattoo_details_attributes][][${keyName}]`, value as string);
            }
          }
        })
      });

    this.callAddVoiceNotesInfoApiId = sendAPIRequest(
      configJSON.physicalEndPoint,
      {
        method: configJSON.formAPiMethod,
        headers: {
          token,
        },
        body: formData,
      }
    );
  };

  validationSchema = Yup.object().shape({
    tattoo_details_attributes: Yup.mixed().when('have_any_tattoo', {
        is: true, 
        then: Yup.array().of(
          Yup.object().shape({
            body_part: Yup.string().nullable().required('Body part is required'),
          })
        ),
        otherwise: Yup.array().nullable(), 
      }),
  });

  handleSwitchChange = async (
    value: boolean,
    setFieldValue:
    {
      (field: string,
        value: any,
        shouldValidate?: boolean | undefined): void; (arg0: string, arg1: string): void;
    }) => {
     setFieldValue(`have_any_tattoo`, value);
  }

  handleFileUpload = async(event: React.ChangeEvent<HTMLInputElement>, setFieldValue:
    {
      (field: string,
        value: any,
        shouldValidate?: boolean | undefined): void; 
        (arg0: string, arg1: string): void;
    },
    index: number, 
prevFiles: File[]) => {
    if (event.target.files) {
      const newFiles = Array.from(event.target.files);
      const oversizedFiles = newFiles.filter(file => file.size > this.maxFileSize);    
    if (oversizedFiles.length > 0) {
      this.props.handleError(configJSON.maxFileSizeError)
      return 
    }
    let files= [...prevFiles, ...newFiles]
    setFieldValue(`tattoo_details_attributes.${index}.files`,files)
    }
  };

  // Customizable Area End
}
