import React from "react";
// Customizable Area Start
import AboutCVEditController, {
    configJSON,
    Props
} from "./AboutCVEditController.web";
import {
    Box,
    Grid,
    Button,
    FormLabel,
    RadioGroup,
    FormControlLabel,
    Typography
} from "@material-ui/core";
import { Field, Form, Formik } from "formik";
import CustomInput from "../../../components/src/CustomInput";
import ErrorMessage from "../../../components/src/ErrorMessage";
import MediaUpload from "../../../components/src/MediaUpload.web";
import { StyledRadio } from "../../../blocks/customform/src/LegalInformation.web";

// Customizable Area End

export default class AboutCVEdit extends AboutCVEditController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    renderDataNotAvailable = (value: string) => {
        if (!this.state.isEdit && value === "0") {
            return (
                <Grid item xs={12}>
                    <Typography variant="subtitle1" className="dataNotAvailable">
                        {configJSON.dataNotAvailable}
                    </Typography>
                </Grid>
            );
        }
        return null;
    };
    // Customizable Area End

    render() {
        // Customizable Area Start
        const {
            aboutCV,
            isEdit,
        } = this.state;
        return (
            <Box className="formWrapper">
                <Formik
                    initialValues={aboutCV}
                    validationSchema={this.validationSchema}
                    onSubmit={(values) => {
                        this.handleAboutCVDataFormSubmit(values)
                    }}
                    enableReinitialize={true}
                    data-test-id="AboutCVForm"
                >
                    {({ values, setFieldValue }) => (
                        <Form className="form">
                            <Box className="formSectionBackground">
                                <Grid container>
                                    {isEdit ? (
                                        <Grid item xs={12}>
                                            <FormLabel component="label" className="formRadioLabel">
                                                {configJSON.uploadCV}
                                            </FormLabel>
                                            <RadioGroup
                                                data-test-id="haveCV"
                                                aria-label="have_legal_representative"
                                                name={configJSON.name}
                                                value={values.have_cv}
                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                    setFieldValue("have_cv", e.target.value);
                                                }
                                                }
                                                className="radioGroup"
                                            >
                                                <FormControlLabel
                                                    value="1"
                                                    control={<StyledRadio />}
                                                    label="Yes"
                                                    className="radioLabel"
                                                />
                                                <FormControlLabel
                                                    value="0"
                                                    control={<StyledRadio />}
                                                    label="No"
                                                    className="radioLabel"
                                                />
                                            </RadioGroup>
                                        </Grid>
                                    ) : (
                                        <Grid item xs={12} style={{ paddingLeft: 0 }}>
                                            <Typography variant="h2">
                                                CV
                                            </Typography>
                                        </Grid>
                                    )}
                                    {this.renderDataNotAvailable(values.have_cv)}
                                    {
                                        values.have_cv === "1" &&
                                            <Grid item xs={12}>
                                                <MediaUpload
                                                    data-test-id="mediaUploadForCV"
                                                    files={values.files}
                                                    readonly={!isEdit}
                                                    title={!isEdit ? "Media" : ""}
                                                    onUpload={(event: React.ChangeEvent<HTMLInputElement>) => this.handleCVFile(event, setFieldValue, values.files)}
                                                />
                                                {!isEdit && values.files.length === 0 && (
                                                    <Box>
                                                        <Typography variant="subtitle1" className="dataNotAvailable">
                                                            {configJSON.NoMediaFound}
                                                        </Typography>
                                                    </Box>
                                                )}
                                            </Grid>
                                    }
                                    {isEdit ? (
                                        <Grid item xs={12}>
                                            <FormLabel component="label" className="formRadioLabel">
                                                {configJSON.addFavouritePosition}
                                            </FormLabel>
                                            <RadioGroup
                                                data-test-id="haveJob"
                                                aria-label="have_legal_representative"
                                                name={configJSON.name}
                                                value={values.have_job}
                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                    setFieldValue("have_job", e.target.value);
                                                }
                                                }
                                                className="radioGroup"
                                            >
                                                <FormControlLabel
                                                    value="1"
                                                    control={<StyledRadio />}
                                                    label="Yes"
                                                    className="radioLabel"
                                                />
                                                <FormControlLabel
                                                    value="0"
                                                    control={<StyledRadio />}
                                                    label="No"
                                                    className="radioLabel"
                                                />
                                            </RadioGroup>
                                        </Grid>
                                    ) : (
                                        <Grid item xs={12} style={{ paddingLeft: 0, marginTop: "16px" }}>
                                            <Typography variant="h2">
                                                Favourite Position/Job
                                            </Typography>
                                        </Grid>
                                    )}
                                    {this.renderDataNotAvailable(values.have_job)}
                                    {
                                        values.have_job === "1" &&
                                        <>
                                            <Grid item lg={3} md={4} xs={12} sm={6}>
                                                <FormLabel component="label">
                                                    {configJSON.favouritePositionJob}<span style={{ color: "red" }}>*</span>
                                                </FormLabel>
                                                <Grid item xs={12} >
                                                    <Field
                                                        data-test-id="favouritePositionJob"
                                                        className="inputField"
                                                        name={configJSON.favouritePositionJob}
                                                        as={CustomInput}
                                                        disabled={!isEdit}
                                                        size="small"
                                                        placeholder={configJSON.favouritePositionJobPlaceholder}
                                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                            setFieldValue(`job_title`, e.target.value)
                                                        }}
                                                        value={values.job_title}
                                                    />
                                                    <ErrorMessage name={`job_title`} />
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <FormLabel component="label">
                                                    {configJSON.favouritePositionDescription}
                                                </FormLabel>
                                                <Grid item xs={12} >
                                                    <Field
                                                        data-test-id="favouritePositionDescription"
                                                        className="multilineInput"
                                                        name={configJSON.favouritePositionDescription}
                                                        as={CustomInput}
                                                        disabled={!isEdit}
                                                        size="small"
                                                        multiline
                                                        placeholder={configJSON.favouritePositionDescriptionPlaceholder}
                                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                            setFieldValue(`job_description`, e.target.value)
                                                        }}
                                                        value={values.job_description}
                                                    />
                                                    <ErrorMessage name={`job_description`} />
                                                </Grid>
                                            </Grid>
                                        </>
                                    }
                                </Grid>
                            </Box>
                            <Box className="btnWrapper">
                                <Button
                                    variant="outlined"
                                    className="btnSize"
                                    onClick={() => this.props.handleNext("CustomForm")}
                                    data-test-id="skipForNow"
                                >
                                    {configJSON.backBtnText}
                                </Button>
                                <Button
                                    variant="contained"
                                    data-test-id="saveChangesBtn"
                                    type="submit"
                                    className="btnSize"
                                >
                                    {isEdit ? configJSON.saveChangesBtnText : configJSON.editDetailsBtnText}
                                </Button>
                            </Box>
                        </Form>
                    )}
                </Formik>
            </Box>
        );
        // Customizable Area End
    }
}


// Customizable Area Start
// Customizable Area End