import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";

// Customizable Area Start
import { sendAPIRequest } from "../../../components/src/Utils";
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";
import { toast } from "react-toastify";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export interface UserMedia{
  id: number
  file_name: string
  content_type: string
  file_size: string
  url: string
}

interface ResponseError{
  (key : string): string
}

interface ResponseJson{
  photos: UserMedia[]
  videos: UserMedia[]
  errors: ResponseError[]
}

interface S {
  // Customizable Area Start
  bannerImage: string;
  userPhotos: UserMedia[]
  userVideos: UserMedia[]
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class HomeController extends BlockComponent<
  Props,
  S,
  SS
> {
  getActiveSubscriptionDetails: string = ""
  getUserPhotosApiCallId: string = ""
  getUserVideosAPICallId: string = ""
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
        bannerImage: "",
        userPhotos: [],
        userVideos: []
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
  );
  const responseJSON = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
  );

  if (apiRequestCallId === this.getActiveSubscriptionDetails) {
      if (responseJSON.data) {
        await setStorageData("active_subscription", JSON.stringify(responseJSON.data.attributes));

      }
    }
    if(apiRequestCallId === this.getUserPhotosApiCallId){
        this.setPhotos(responseJSON)
    }
    if(apiRequestCallId === this.getUserVideosAPICallId){
      this.setVideos(responseJSON)
    }
    // Customizable Area End
  }

  // Customizable Area Start

  async componentDidMount() {
    super.componentDidMount();
    this.getActiveSubscriptionPlan()
    this.getPhotos()
    this.getVideos()
  }

  getPhotos = async () =>{
    const token = await getStorageData("token");
    if(token){
        this.getUserPhotosApiCallId = sendAPIRequest(
            configJSON.photosEndPoint,
            {
              method: configJSON.getApiRequest,
              headers: {
                  token,
                },
            }
        );
    }
  }

  getVideos = async ()=>{
    const token = await getStorageData("token");
    if(token){
        this.getUserVideosAPICallId = sendAPIRequest(
            configJSON.videoEndPoint,
            {
              method: configJSON.getApiRequest,
              headers: {
                  token,
                },
            }
        );
    }
  }

  setPhotos = (responseJSON: ResponseJson ) =>{
    if(responseJSON?.photos){
      this.setState({userPhotos: responseJSON.photos})
    }
    if(responseJSON?.errors){
      this.handleError(responseJSON?.errors)
    }

  }

  setVideos = (responseJSON: ResponseJson ) =>{
    if(responseJSON?.videos){
      this.setState({userVideos: responseJSON.videos})
    }
    if(responseJSON?.errors){
      this.handleError(responseJSON?.errors)
    }
  }

  handleError = (errors: ResponseError[]) =>{
    if (errors.length > 0) {
      errors.forEach(errorObj => {
          Object.entries(errorObj).forEach(([key, message]) => {
              toast.error(message)
          });
      });
  }
  }

  getActiveSubscriptionPlan = async () => {
    const token = await getStorageData("token");

    if(token){
        this.getActiveSubscriptionDetails = sendAPIRequest(
            configJSON.getSubscriptionPlanApiEndPoint,
            {
              method: configJSON.getApiRequest,
              headers: {
                  token,
                },
            }
        );
    }
  };


  handleNavigation = (route: string) => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), route);
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  };
  // Customizable Area End
}