import React from "react";
// Customizable Area Start
import { 
    Box, 
    Button, 
    Card, 
    CardContent, 
    Checkbox, 
    Container,
    FormLabel, 
    Grid, 
    ThemeProvider, 
    Typography, 
    createTheme, 
    styled 
} from "@material-ui/core";
import { checkIcon, failedIcon } from "./assets";
import WebFooter from "../../../components/src/Footer.web";
import WebHeader from "../../../blocks/landingpage/src/WebHeader.web";

const theme = createTheme({
    overrides: {
      MuiTypography: {
        h1: {
          color: "#0F172A",
          fontFamily: "Quattrocento",
          fontSize: "24px",
          fontWeight: 700,
          lineHeight: "32px",
        },
        h3: {
          color: "#0F172A",
          fontFamily: "Quattrocento",
          fontSize: "24px",
          fontWeight: 700,
          lineHeight: "32px",
        },
        h6: {
          color: "#64748B",
          fontFamily: "Lato",
          fontSize: "20px",
          fontWeight: 700,
          lineHeight: "28px",
        },
        subtitle1: {
          color: "#64748B",
          fontFamily: "Lato",
          fontSize: "16px",
          fontWeight: 400,
          lineHeight: "22px",
        },
        subtitle2: {
          color: "#64748B",
          fontFamily: "Lato",
          fontSize: "18px",
          fontWeight: 400,
          lineHeight: "26px",
        },
      },
      MuiButton: {
        contained: {
            width: "339px",
            height: "48px",
            fontFamily: "Lato",
            fontSize: '18px',
            fontWeight: 700,
            color: "#FFFFFF",
            backgroundColor: "#A190C2",
            borderRadius: "100px",
            textTransform: "none",  
        }
      },
    },
  });
  
import CustomisableusersubscriptionsController, {
    Props,
    configJSON,
    currencySymbolMap
  } from "./CustomisableusersubscriptionsController";
import GenericModal from "../../../components/src/GenericModal";
import { rightTickImg } from "../../../blocks/forgot-password/src/assets";
// Customizable Area End

export default class Customisableusersubscriptions extends CustomisableusersubscriptionsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { selectedPlan, subscriptionPlans, openDialog } = this.state;
    return (
      <>
        <ThemeProvider theme={theme}>
            <Wrapper>
            <WebHeader navigation={this.props.navigation} />
                <Container className="container">
                    <Box className="alignContent">
                        <Box className="subscriptionPlanTitleWrpper">
                            <Typography data-test-id="subscriptionPlanTitle" variant="h3" align="center" gutterBottom>
                                {configJSON.subscriptionPlan}
                            </Typography>
                            <Typography variant="subtitle1" align="center" gutterBottom>
                                {configJSON.subscriptionPlanDescription}
                            </Typography>
                        </Box>
                    </Box>
                    <Grid container spacing={6} justifyContent="center">
                        {subscriptionPlans.map((plan) => (
                            <Grid item className="planGridItem" xs={12} sm={6} md={4} key={plan.name}>
                                <Card
                                    data-test-id="planCard"
                                    className={`plan-card ${selectedPlan === plan.id ? 'selected' : ''}`}
                                    onClick={() => this.handlePlanSelect(plan.id)}
                                    elevation={selectedPlan === plan.id ? 10 : 1}
                                >
                                    <CardContent>
                                        <Box className="checkBoxWrapper">
                                            <img alt="plan-img" src={plan.image_link} className="planImg"/>
                                            <FormLabel>
                                                { selectedPlan === plan.id && (
                                                    <Checkbox
                                                    checked={selectedPlan === plan.id}
                                                    name="plan-checkbox"
                                                    style={{ color: "#9176C4", borderRadius: "6px" }}
                                                />
                                                )} 
                                            </FormLabel>
                                        </Box>
                                        <Typography variant="h6" gutterBottom>
                                            {plan.name}
                                        </Typography>
                                        <Typography gutterBottom>
                                            <span className="priceText">{currencySymbolMap[plan.currency_name]}{plan.price}</span> <span className="pricePerText">{plan.plan_type}</span>
                                        </Typography>
                                         <Box className="innerHtmlWrapper" dangerouslySetInnerHTML={{ __html: plan.description }} />
                                    </CardContent>
                                    <Box className="subscribeBtnWrapper">
                                        <Button 
                                          variant="contained" 
                                          data-test-id="subscribeBtn"
                                          disabled={plan.is_active_plan}
                                          onClick={() => this.handleSubscribeBtnClick()}
                                        >
                                         {!this.state.subscriptionHave ?  configJSON.subscribeBtnText: configJSON.upgradeBtnText}
                                           
                                        </Button>
                                    </Box>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                </Container>
                <WebFooter navigation={this.handleNavigation} />
            </Wrapper>
        </ThemeProvider>
        <GenericModal open={openDialog === "subscriptionDone"} dataTest="subscription-done" onClose={this.handleCloseDialog}>
          <ThemeProvider theme={theme}>
            <DialogWrapper>
              <Box className="alignCenter" mb={4}>
                <img alt="success" src={rightTickImg} className="dialogImgIcon" />
                <Typography 
                  align="center" 
                  variant="h1" 
                  data-test-id="paymentDoneMsg"
                >
                  {configJSON.subscriptionDone}
                </Typography>
                <Typography 
                  align="center" 
                  variant="subtitle2"
                >
                  {configJSON.subscriptionDoneDescription}
                </Typography>
              </Box>
              <Box className="btnWrapper">
                <Button
                  data-test-id="handleContinueBtnClick"
                  fullWidth
                  style={{ width: "100%" }}
                  className="containedBtn"
                  variant="contained"
                  onClick={() => this.handleContinueBtnClick()}
                >
                  {configJSON.continueBtnText}
                </Button>
              </Box>
            </DialogWrapper>
          </ThemeProvider>
        </GenericModal>
        <GenericModal open={openDialog === "subscriptionFailed"} dataTest="subscription-failed" onClose={this.handleCloseDialog}>
          <ThemeProvider theme={theme}>
            <DialogWrapper>
              <Box className="alignCenter" mb={4}>
                <img alt="failure" src={failedIcon} className="dialogImgIcon" />
                <Typography align="center" variant="h1" data-test-id="paymentFailedMsg">
                  {configJSON.subscriptionFailed}
                </Typography>
                <Typography align="center" variant="subtitle2">
                  {configJSON.subscriptionFailedDescription}
                </Typography>
              </Box>
              <Box className="btnWrapper">
                <Button
                  data-test-id="handleTryAgainBtnClick"
                  className="tryAgainBtn"
                  variant="contained"
                  onClick={() => this.handleTryAgainBtnClick()}
                >
                  {configJSON.tryAgainBtnText}
                </Button>
              </Box>
            </DialogWrapper>
          </ThemeProvider>
        </GenericModal>
        <GenericModal open={openDialog === "subscribeToPlan"} dataTest="subscribe-plan" onClose={this.handleCloseDialog}>
          <ThemeProvider theme={theme}>
            <DialogWrapper>
              <Box className="alignCenter" mb={4} style={{ gap: "28px" }}>
                <img alt="success" src={failedIcon} className="dialogImgIcon" />
                <Typography align="center" variant="subtitle2" className="subscribeToPlanText">
                  {configJSON.subscribeToPlanText}
                </Typography>
              </Box>
            </DialogWrapper>
          </ThemeProvider>
        </GenericModal>
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const Wrapper = styled("div")(({ theme }) => ({
    "& .container": {
        height: "auto",
        marginTop: "56px",
        marginBottom: "98px",
    },
    "& .alignContent": {
        display: "flex", 
        justifyContent: "center"
    },
    "& .subscriptionPlanTitleWrpper": {
        width: "327px",
        heigth: "86px",
        marginBottom: "44px",
    },
    "& .plan-card": {
        cursor: "pointer",
        transition: "all 0.3s ease",
        borderRadius: "12px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        height: "100%",
        maxWidth: "387px",
        boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 12px 0px",
        padding: "24px",
        marginBottom: "10px",
        boxSizing: "border-box",
      },
      "& .plan-card.selected": {
        border: "1px solid #9176C4",
      },
      "& .planImg": {
        width: "55px",
        height: "44px",
      },
      "& .checkBoxWrapper": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      },
      "& .planGridItem":{
        display: "flex",
        justifyContent: "center",
      },
      "& .priceText": {
        fontFamily: "Quattrocento",
        fontSize: "32px",
        fontWeight: 700,
        lineHeight: "28px",
        color: "#9176C4",
      },
      "& .pricePerText": {
        fontFamily: "Lato",
        fontSize: "16px",
        fontWeight: 500,
        lineHeight: "28px",
        color: "#64748B",
      },
      "& .subscribeBtnWrapper": {
        display: "flex",
        justifyContent: "center",
      },
      "& .innerHtmlWrapper>p": {
        fontFamily: "Lato",
        fontSize: "18px",
        fontWeight: 600,
        lineHeight: "26px",
        color: "#0F172A",
      },
      "& .innerHtmlWrapper>ul": {
        listStyle: "none",
        display: "flex",
        flexDirection: "column",
        gap: "16px",
        paddingLeft: "22px",
      },
      "& .innerHtmlWrapper>ul>li": {
        fontFamily: "Lato",
        fontSize: "16px",
        fontWeight: 500,
        lineHeight: "22px",
        color: "#334155",
        position: "relative",
        "&:before":{
            content: `url(${checkIcon})`,
            position: "absolute",
            left: "-25px",
            top: "8%",
            width: "16px",
            height: "16px",
        }
      },
  }));

  const DialogWrapper = styled('div')(({ theme }) => ({
    "& .btnWrapper": {
        display: "flex",
        gap: "8px",
        justifyContent: "center",
    },
    "& .alignCenter": {
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        gap: "8px",
        width: "369px",
    },
    "& .containedBtn": {
        width: "192px",
        heigh: "56px",
        backgroundColor: "#A190C2",
        color: "#FFFFFF",
        borderRadius: "100px",
        textTransform: "none",
        fontFamily: "Lato",
        fontSize: "16px",
        fontWeight: 600,
        padding: "16px",
        "&:hover": {
          backgroundColor: "#9176C4",
        },
    },
    "& .dialogImgIcon": {
        width: "72px",
        height: "72px",
    },
    "& .tryAgainBtn": {
      width: "273px",
      height: "56px",
      "&:hover": {
        backgroundColor: "#9176C4",
      }
    },
    "& .subscribeToPlanText": {
      fontFamily: "Open Sans", 
      fontWeight: 600, 
      color: "#0F172A",
    },
    [theme.breakpoints.down('xs')]: {
        "& .btnWrapper": {
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
        }
    }
}));
// Customizable Area End
