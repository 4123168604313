import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { sendAPIRequest } from "../../../components/src/Utils";
import { Blog, Breadcrumb } from "./BlogListController.web";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;

  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
// Customizable Area End

interface S {
  // Customizable Area Start
  blogDetail: Blog | null
  relatedBlog: Blog[]
  detailsBreadCrumb: Breadcrumb[]
  // Customizable Area End
}

interface SS {
  id: string | number;
  // Customizable Area Start
  // Customizable Area End
}


export default class BlogDetailsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getBlogApiCallID: string = ""
  getRecentBlogApiCallID: string = ""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      blogDetail: null,
      relatedBlog: [],
      detailsBreadCrumb: [{
        label: 'Blogs',
        path: 'BlogList'
      },
      {
        label: 'Blog Details',
        path: 'BlogDetails'
      }]
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (this.getBlogApiCallID === apiRequestCallId) {
        if (responseJson?.data) {
          this.setState({blogDetail:responseJson.data})
        }
      }
      if (this.getRecentBlogApiCallID === apiRequestCallId) {
        if (responseJson?.data) {
          this.setState({relatedBlog:responseJson.data})
        }
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount(){
    super.componentDidMount()
    let blogID = this.props.navigation.getParam("navigationBarTitleText")
    this.getBlogDetails(blogID)
    this.getRelatedBlogList(blogID)
  }
  async componentDidUpdate(prevProps: Readonly<Props>){
    let blogID = this.props.navigation.getParam("navigationBarTitleText")
    let prevBlogID = prevProps.navigation.getParam("navigationBarTitleText")
    if(blogID && prevBlogID && blogID !== prevBlogID ){
      this.getBlogDetails(blogID)
    this.getRelatedBlogList(blogID)
    }
    
  }

  getBlogDetails=(blogID:string)=>{
    this.getBlogApiCallID = sendAPIRequest(
      configJSON.getBlogEndPoint + blogID,
      {
        method: configJSON.validationApiMethodType
      }
    );
  }

  getRelatedBlogList = (blogID: string) =>{
    this.getRecentBlogApiCallID = sendAPIRequest(
      configJSON.getBlogEndPoint + blogID + '/related_blogs',
      {
        method: configJSON.validationApiMethodType
      }
    );
  }

  handleNavigation = (route: string, id?: number) => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage),route);
    id && message.addData(getName(MessageEnum.NavigationScreenNameMessage),id)
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  };

  // Customizable Area End
}
