import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
import { sendAPIRequest } from "../../../components/src/Utils";
// Customizable Area End

export const configJSON = require("./config");

export interface Breadcrumb {
  label: string;
  path: string
}

export interface BlogCategories {
  id: string
  attributes: {
    id: number
    name: string
  }
}

interface BlogCategory {
  id: number;
  name: string;
}

interface BlogAttributes {
  id: number;
  title: string;
  post: string;
  banner_url: string;
  blog_category: BlogCategory;
  created_at: string;
  updated_at: string;
  author_name: null | string;
  author_image: null | string;
  reading_time: null | string
}

export interface Blog {
  id: string;
  type: string;
  attributes: BlogAttributes;
}

export interface Props {
  navigation: any;
  id: string;

  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
// Customizable Area End

interface S {
  // Customizable Area Start
  blogBreadCrumb: Breadcrumb[]
  blogCategories: BlogCategories[]
  selectedCategorie: number
  recentBlog: Blog[]
  moreReadBlog: Blog[]
  viewAll: string
  currentPage: number
  hasMore: boolean
  hasRecent: boolean
  bannerTitle: string
  bannerImg: string
  // Customizable Area End
}

interface SS {
  id: string | number;
  // Customizable Area Start
  // Customizable Area End
}


export default class BlogListController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getBlogCatApiID: string = ""
  getRecentBlogApiID: string = ""
  getMoreReadBlogApiID: string = ""
  getBlogBannerApiID: string =""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      blogBreadCrumb: [{
        label: 'Blogs',
        path: 'BlogList'
      }],
      blogCategories: [],
      selectedCategorie: 0,
      recentBlog: [],
      moreReadBlog: [],
      viewAll: "",
      currentPage: 1,
      hasMore: false,
      hasRecent: false,
      bannerTitle: "",
  bannerImg: "",
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (this.getBlogBannerApiID === apiRequestCallId) {
        if (responseJson?.data) {
          this.setState({bannerImg:responseJson.data?.attributes?.banner_image , bannerTitle: responseJson.data?.attributes?.description})
        }
      }

      if (this.getBlogCatApiID === apiRequestCallId) {
        if (responseJson?.data) {
          let allCategories = {
            id: "0",
            attributes: {
              id: 0,
              name: "All Categories"
            }
          }
          this.setState({ blogCategories: [allCategories, ...responseJson.data] })
        }
      }

      if (this.getMoreReadBlogApiID === apiRequestCallId) {
        if (responseJson?.data) {
          let hasMore = responseJson.data.length > 8
          this.setState((prevState)=>({ moreReadBlog: [...prevState.moreReadBlog, ...responseJson.data], hasMore }))
        }
      }

      if (this.getRecentBlogApiID === apiRequestCallId) {
        if (responseJson?.data) {
          let hasRecent = responseJson.data.length > 8
          this.setState((prevState)=>({ recentBlog: [...prevState.recentBlog, ...responseJson.data], hasRecent }))

        }
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount()
    this.getBlogBanner()
    this.getBlogCategories()
    this.getRecentBlog()
    this.getMoreReadBlog()
  }

  getBlogCategories = async () => {
    const token = await getStorageData("token");
    this.getBlogCatApiID = sendAPIRequest(
      configJSON.getBlogsCatEndPoint,
      {
        method: configJSON.validationApiMethodType,
        headers: { token }
      }
    );
  }

  handleViewClick = (type: string) =>{
    this.setState({viewAll: type, blogBreadCrumb: [{
      label: 'Blogs',
      path: 'BlogList'
    },{
      label: type,
      path: type
    }]})
  }

  hasMoreData = () => {
    return this.state.viewAll === "Recent" ? this.state.hasRecent : this.state.hasMore
  }

  getBlogBanner = async () =>{
    const token = await getStorageData("token");
    this.getBlogBannerApiID = sendAPIRequest(
      configJSON.getBlogsBannerEndPoint,
      {
        method: configJSON.validationApiMethodType,
        headers: { token }
      }
    );
  }

  fetchMoreData =()=>{
    this.setState((prevState)=>({currentPage: prevState.currentPage + 1 }),()=>{
      this.handleApiCall()
    })
  }

  handleApiCall=()=>{
    this.state.viewAll && (this.state.viewAll === "Recent" ? this.getRecentBlog() : this.getMoreReadBlog())
  }

  getRecentBlog = async () => {
    const token = await getStorageData("token");
    this.getRecentBlogApiID = sendAPIRequest(
     configJSON.getRecentBlogEndPoint + this.state.selectedCategorie+`&page=${this.state.currentPage}`,
      {
        method: configJSON.validationApiMethodType,
        headers: { token }
      }
    );
  }
  getMoreReadBlog = async () => {
    const token = await getStorageData("token");
    this.getMoreReadBlogApiID = sendAPIRequest(
      configJSON.getMoreReadBlogEndPoint + this.state.selectedCategorie+`&page=${this.state.currentPage}`,
      {
        method: configJSON.validationApiMethodType,
        headers: { token }
      }
    );
  }

  changeCategory = (_id: number) =>{
    this.setState({selectedCategorie: _id, recentBlog: [], moreReadBlog: []},()=>{
      this.getMoreReadBlog()
      this.getRecentBlog()
    })
  }

  checkView = (type:string) =>{
    if(this.state.viewAll !== "" && type !== this.state.viewAll){
      return false
    }
    return true
  }

  blogData = (data: Blog[]) => {
    if(this.state.viewAll){
      return data
    }else{
      return data.slice(0, 3)
    }
  }

  handleBreadClick = (path: string) =>{
    if(path === "BlogList"){
      this.setState({viewAll: "", blogBreadCrumb:[{
        label: 'Blogs',
        path: 'BlogList'
      }]})
    }
  }

  handleNavigation = (route: string, id?: number) => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage),route);
    message.addData(getName(MessageEnum.NavigationScreenNameMessage),id)
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  };

  // Customizable Area End
}
