import React from 'react';
import { Breadcrumbs, Link, Typography } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

// Styled components for dynamic styling
const Root = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'start',
  padding: theme.spacing(1),
}));

const StyledLink = styled(Typography)(({ theme }) => ({
    textDecoration: 'none',
    color: "#475569",
    fontFamily: "Lato",
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "22px",
    cursor:"grab",
  '&:hover': {
    color: "#0F172A",
  },
}));

const CurrentStep = styled(Typography)({
    color: "#0F172A",
    fontFamily: "Lato",
    fontSize: "16px",
    fontWeight: 600,
    lineHeight: "24px",
    textDecoration: 'none',
    cursor: 'pointer',
});

// Generic Stepper Component
interface Step {
  label: string;
  path: string;
}

interface BreadcrumbStepperProps<T extends Step> {
  steps: T[];
  onStepClick?: (path: string) => void;
  
  isMap?: boolean
}

const BreadcrumbStepper = <T extends Step>({ steps, onStepClick, isMap }: BreadcrumbStepperProps<T>) => {
  const handleClick = (event: React.MouseEvent, path: string) => {
    event.preventDefault();
    if (onStepClick) {
      onStepClick(path);
    }
  };

  return (
    <Root>
      <Breadcrumbs aria-label="breadcrumb" separator={<NavigateNextIcon fontSize="small" style={{cursor:"pointer"}} />}>
        {steps.map((step, index) => (
          index < steps.length - 1 ? (
            <StyledLink
              key={step.label}
              onClick={(event) => handleClick(event, step.path)}
            >
              {step.label}
            </StyledLink>
          ) : (
            <CurrentStep key={step.label}>{step.label}</CurrentStep>
          )
        ))}
      </Breadcrumbs>
    </Root>
  );
};

export default BreadcrumbStepper;
