import React from "react";
// Customizable Area Start
import {
    FormLabel,
    Box,
    Grid,
    Typography,
    Avatar,
    Button,
    IconButton,
    Badge,
    Menu,
    Fade,
    MenuItem,
    FormHelperText,
    Tooltip,
    Container,
} from "@material-ui/core";
import { createTheme, styled, ThemeProvider } from "@material-ui/core/styles";
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import MyLocationTwoToneIcon from '@material-ui/icons/MyLocationTwoTone';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import WebFooter from "../../../components/src/Footer.web";
import "react-datepicker/dist/react-datepicker.css";
import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/style.css'

import {
    Field,
    Form,
    Formik,
    FormikErrors,
    FormikTouched,
    FormikValues
} from "formik";
import CustomInput from "../../../components/src/CustomInput";
import CustomSelect from "../../../components/src/CustomSelect";
import GenericModal from "../../../components/src/GenericModal";
import { rightTickImg } from "../../../blocks/forgot-password/src/assets";
import CustomDatePicker from "../../../components/src/CustomDatePicker";
import { countries } from "../src/UserProfileEditController.web";
import WebHeader from "../../../blocks/landingpage/src/WebHeader.web";
import BreadcrumbStepper from "../../../components/src/BreadcrumbStepper.web";
import DelegateEditMemberController, {
    Props,
    configJSON,
    IDelegateMember,
} from "./DelegateEditMemberController.web";

const theme = createTheme({
    palette: {
        primary: {
            main: "#0000ff",
            contrastText: "#fff"
        }
    },
    overrides: {
        MuiTypography: {
            h1: {
                fontFamily: "Lato",
                fontSize: "24px",
                fontWeight: 700,
                color: "#0F172A",
                lineHeight: "32px",
            },
            h5: {
                fontFamily: "Quattrocento",
                fontSize: "20px",
                fontWeight: 700,
                color: "#0F172A",
                lineHeight: "28px",
            },
            h6: {
                fontFamily: "Lato",
                fontSize: "14px",
                fontWeight: 500,
                color: "#9176C4",
                lineHeight: "18px",
            },
            subtitle1: {
                fontFamily: "Lato",
                fontSize: "16px",
                fontWeight: 400,
                color: "#64748B",
                lineHeight: "22px",
            },
            body1: {
                fontFamily: "Lato",
                fontSize: "18px",
                fontWeight: 700,
                color: "#9176C4",
                lineHeight: "26px",
            },
            body2: {
                fontFamily: "Lato",
                fontSize: "18px",
                fontWeight: 400,
                lineHeight: "24px",
                color: "#64748B",
            },
        },
        MuiFormLabel: {
            root: {
                fontFamily: "Lato",
                fontSize: "14px",
                fontWeight: 400,
                color: "#334155",
                lineHeight: "22px",
            }
        },
        MuiStepLabel: {
            root: {
                label: {
                    fontFamily: "Lato",
                    fontSize: "16px",
                    fontWeight: 600,
                    lineHeight: "24px",
                },
            }
        },
        MuiOutlinedInput: {
            root: {
                borderRadius: "8px",
                minHeight: "48px",
                "& [class*=MuiOutlinedInput-root].Mui-focused": {
                    borderColor: "#94A3B8",
                },
                "& .Mui-focused": {
                    "& [class*=MuiOutlinedInput-notchedOutline]": {
                        borderColor: "#94A3B8 !important",
                    }
                },
            },
            input: {
                "&::placeholder": {
                    fontFamily: "Lato !important",
                    color: "#94A3B8",
                    fontWeight: 400,
                    opacity: 1,
                },
                "&::focused": {
                    borderColor: "#94A3B8 !important",
                },
            },
        },
        MuiInputBase: {
            root: {
                borderRadius: "8px",
                minHeight: "48px",
                "& .Mui-focused": {
                    "& [class*=MuiOutlinedInput-notchedOutline]": {
                        borderColor: "#94A3B8 !important",
                    }
                },
            },
        },
        MuiButton: {
            contained: {
                width: "248px",
                height: "48px",
                fontFamily: "Lato",
                fontSize: '16px',
                fontWeight: 600,
                color: "#FFFFFF",
                backgroundColor: "#A190C2",
                borderRadius: "100px",
                textTransform: "none",
            },
            outlined: {
                width: "248px",
                height: "48px",
                fontFamily: "Lato",
                fontSize: '16px',
                fontWeight: 600,
                color: "#9176C4",
                borderRadius: "100px",
                textTransform: "none",
            },
        },
        MuiDialog: {
            paper: {
                maxWidth: "418px !important",
            },
        },
        MuiTooltip: {
            popper: {
                backgroundColor: "none",
            }
        },
        MuiCardHeader: {
            avatar: {
                marginRight: "8px",
            },
            action: {
                alignSelf: "flex-center",
            }
        }
    }
});
// Customizable Area End

export default class DelegateEditMemberBlockDelegateEditMember extends DelegateEditMemberController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start

    renderInfoIcon = () => {
        return (
            <LightTooltip title={
                <>
                    <Typography style={{
                        fontFamily: "Lato",
                        fontSize: "12px",
                        fontWeight: 400,
                        lineHeight: "18px",
                        color: "#000000",
                    }}>
                        {configJSON.securityPhraseInfo}
                    </Typography>
                </>
            }
            >
                <InfoOutlinedIcon className="infoIcon" />
            </LightTooltip>
        );
    };

    renderImageField = (values: FormikValues, setFieldValue: {
        (field: string,
            value: any,
            shouldValidate?: boolean | undefined): void;
        (arg0: string, arg1: string): void;
    }) => {
        return (
            <Box>
                <input
                    data-test-id="profile-image"
                    id="profilePicInput"
                    type="file"
                    name="profile_pic"
                    accept="image/*"
                    onChange={(event) =>
                        this.handleProfileImg(event, setFieldValue)
                    }
                    style={{ display: 'none' }}
                />
                <label htmlFor="profilePicInput">
                    <IconButton component="span" style={{ paddingLeft: '0' }}>
                        {this.state.profileImage || values.photo ? (
                            <Badge
                                className="profilePicBadge"
                                overlap="circular"
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                }}
                                badgeContent={
                                    <>
                                        <div onClick={(e: React.MouseEvent<HTMLInputElement>) =>
                                            this.handleProfilePicEditMenu(e)
                                        }>
                                            <IconButton
                                                style={{ padding: "0" }}
                                            >
                                                <svg fill="none" height="24" width="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                    <rect fill="#9176C4" width="24" height="24" rx="12" />
                                                    <path d="M17.25 17.834H6.75C6.51083 17.834 6.3125 17.6357 6.3125 17.3965C6.3125 17.1573 6.51083 16.959 6.75 16.959H17.25C17.4892 16.959 17.6875 17.1573 17.6875 17.3965C17.6875 17.6357 17.4892 17.834 17.25 17.834Z" fill="white" />
                                                    <path d="M16.095 7.02964C14.9633 5.89798 13.855 5.86881 12.6942 7.02964L11.9883 7.73548C11.93 7.79381 11.9067 7.88714 11.93 7.96881C12.3733 9.51464 13.61 10.7513 15.1558 11.1946C15.1792 11.2005 15.2025 11.2063 15.2258 11.2063C15.29 11.2063 15.3483 11.183 15.395 11.1363L16.095 10.4305C16.6725 9.85881 16.9525 9.30464 16.9525 8.74464C16.9583 8.16714 16.6783 7.60714 16.095 7.02964Z" fill="white" />
                                                    <path d="M14.1058 11.726C13.9367 11.6443 13.7733 11.5626 13.6158 11.4693C13.4875 11.3935 13.365 11.3118 13.2425 11.2243C13.1433 11.1601 13.0267 11.0668 12.9158 10.9735C12.9042 10.9676 12.8633 10.9326 12.8167 10.886C12.6242 10.7226 12.4083 10.5126 12.2158 10.2793C12.1983 10.2676 12.1692 10.2268 12.1283 10.1743C12.07 10.1043 11.9708 9.98763 11.8833 9.85346C11.8133 9.76596 11.7317 9.63763 11.6558 9.5093C11.5625 9.3518 11.4808 9.1943 11.3992 9.03096C11.2921 8.80152 10.9909 8.73335 10.8119 8.9124L7.53167 12.1926C7.45583 12.2685 7.38583 12.4143 7.36833 12.5135L7.05333 14.7476C6.995 15.1443 7.10583 15.5176 7.35083 15.7685C7.56083 15.9726 7.8525 16.0835 8.1675 16.0835C8.2375 16.0835 8.3075 16.0776 8.3775 16.066L10.6175 15.751C10.7225 15.7335 10.8683 15.6635 10.9383 15.5876L14.224 12.302C14.3994 12.1265 14.3335 11.8246 14.1058 11.726Z" fill="white" />
                                                </svg>
                                            </IconButton>
                                        </div>
                                        <Menu
                                            id="fade-menu"
                                            anchorEl={this.state.anchorEl}
                                            open={Boolean(this.state.anchorEl)}
                                            anchorOrigin={{
                                                vertical: 'bottom',
                                                horizontal: 'left'
                                            }}
                                            transformOrigin={{
                                                vertical: 'top',
                                                horizontal: 'left'
                                            }}
                                            onClose={this.handleProfilePicEditMenuClose}
                                            getContentAnchorEl={null}
                                            TransitionComponent={Fade}
                                            keepMounted
                                        >
                                            <MenuItem>
                                                <input
                                                    data-test-id="change-image"
                                                    id="changeProfilePicInput"
                                                    type="file"
                                                    name="profile_pic_change"
                                                    accept="image/*"
                                                    onChange={(event) =>
                                                        this.handleProfileImg(event, setFieldValue)
                                                    }
                                                    style={{ display: 'none' }}
                                                />
                                                <label style={{ display: "flex" }} htmlFor="changeProfilePicInput">
                                                    <Box>
                                                        <svg fill="none" width="18" height="18" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M9.94501 2.70041L3.78751 9.21791C3.55501 9.46541 3.33001 9.95291 3.28501 10.2904L3.00751 12.7204C2.91001 13.5979 3.54001 14.1979 4.41001 14.0479L6.82501 13.6354C7.16251 13.5754 7.63501 13.3279 7.86751 13.0729L14.025 6.55541C15.09 5.43041 15.57 4.14791 13.9125 2.58041C12.2625 1.02791 11.01 1.57541 9.94501 2.70041Z" stroke="#292D32" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                                            <path d="M8.91748 3.78711C9.23998 5.85711 10.92 7.43961 13.005 7.64961" stroke="#292D32" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                                            <path d="M2.25 16.5H15.75" stroke="#292D32" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                                        </svg>
                                                    </Box>
                                                    &nbsp;{configJSON.changePicture}
                                                </label>
                                            </MenuItem>
                                            <MenuItem onClick={() => this.handleRemoveProfilePic(setFieldValue)}>
                                                <Box>
                                                    <svg fill="none" width="18" height="18" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M15.75 4.48438C13.2525 4.23687 10.74 4.10938 8.235 4.10938C6.75 4.10938 5.265 4.18438 3.78 4.33438L2.25 4.48438" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                        <path d="M6.375 3.7275L6.54 2.745C6.66 2.0325 6.75 1.5 8.0175 1.5H9.9825C11.25 1.5 11.3475 2.0625 11.46 2.7525L11.625 3.7275" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                        <path d="M14.1375 6.85547L13.65 14.408C13.5675 15.5855 13.5 16.5005 11.4075 16.5005H6.59255C4.50005 16.5005 4.43255 15.5855 4.35005 14.408L3.86255 6.85547" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                        <path d="M7.74756 12.375H10.2451" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                        <path d="M7.125 9.375H10.875" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                    </svg>
                                                </Box>
                                                &nbsp;{configJSON.removePicture}
                                            </MenuItem>
                                        </Menu>
                                    </>
                                }
                            >
                                <Avatar
                                    className="profilePicAvatar"
                                    style={{
                                        margin: '5px 0 10px 0'
                                    }}
                                    alt={values.first_name || ''}
                                    src={(this.state.profileImage as string) || values.photo}
                                />
                            </Badge>
                        ) : (
                            <svg fill="none" width="62" height="62" viewBox="0 0 62 62" xmlns="http://www.w3.org/2000/svg">
                                <rect fill="#F8FFF0" x="0.5" y="0.5" width="61" height="61" rx="30.5" />
                                <rect stroke="#ACCC86" x="0.5" y="0.5" width="61" height="61" rx="30.5" />
                                <path
                                    fill="#ACCC86"
                                    d="M33.12 24L34.95 26H39V38H23V26H27.05L28.88 24H33.12ZM34 22H28L26.17 24H23C21.9 24 21 24.9 21 26V38C21 39.1 21.9 40 23 40H39C40.1 40 41 39.1 41 38V26C41 24.9 40.1 24 39 24H35.83L34 22ZM31 29C32.65 29 34 30.35 34 32C34 33.65 32.65 35 31 35C29.35 35 28 33.65 28 32C28 30.35 29.35 29 31 29ZM31 27C28.24 27 26 29.24 26 32C26 34.76 28.24 37 31 37C33.76 37 36 34.76 36 32C36 29.24 33.76 27 31 27Z"
                                />
                            </svg>
                        )}
                    </IconButton>
                </label>
            </Box>
        );
    };

    renderDelegateMemberAddressForm = (
        values: FormikValues,
        errors: FormikErrors<IDelegateMember>,
        touched: FormikTouched<IDelegateMember>,
        setFieldValue: {
            (field: string,
                value: any,
                shouldValidate?: boolean | undefined): void; (arg0: string, arg1: string): void;
        }) => {
        return (
            <>
                <Grid item xs={12}>
                    <Typography variant="h6" style={{ alignItems: "flex-start" }} className="addressTitle">
                        <ErrorOutlineIcon className="ErrorOutlineIcon" />{configJSON.delegateMemberAddressDescription}
                    </Typography>
                </Grid>
                <Grid item lg={6}>
                    <FormLabel component="label">
                        {configJSON.addressLine1}<span style={{ color: "red" }}>*</span>
                    </FormLabel>
                    <Field
                        data-test-id="address_line_1"
                        name="address_line_1"
                        className="inputField"
                        size="small"
                        startIcon={styledMyLocationTwoToneIcon}
                        as={CustomInput}
                        placeholder="House/Flat no."
                        value={values.address_line_1}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            setFieldValue("address_line_1", e.target.value)
                        }
                        errorMsg={errors.address_line_1}
                        error={touched.address_line_1 && errors.address_line_1}
                    />
                </Grid>
                <Grid item lg={6}>
                    <FormLabel component="label">
                        {configJSON.addressLine2}<span style={{ color: "red" }}>*</span>
                    </FormLabel>
                    <Field
                        name="address_line_2"
                        data-test-id="address_line_2"
                        className="inputField"
                        size="small"
                        as={CustomInput}
                        placeholder="Street and Locality"
                        value={values.address_line_2}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            setFieldValue("address_line_2", e.target.value)
                        }
                        error={touched.address_line_2 && errors.address_line_2}
                        errorMsg={errors.address_line_2}
                    />
                </Grid>
                <Grid item lg={6}>
                    <FormLabel component="label">
                        {configJSON.landmarkLabel}
                    </FormLabel>
                    <Field
                        data-test-id="landmark"
                        name="landmark"
                        className="inputField"
                        size="small"
                        as={CustomInput}
                        placeholder="Landmark"
                        value={values.landmark}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            setFieldValue("landmark", e.target.value)
                        }

                    />
                </Grid>
                <Grid item lg={6}>
                    <FormLabel component="label">
                        {configJSON.townCityLabel}<span style={{ color: "red" }}>*</span>
                    </FormLabel>
                    <Field
                        data-test-id="city"
                        name="city"
                        className="inputField"
                        size="small"
                        as={CustomInput}
                        placeholder="City"
                        value={values.city}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            setFieldValue("city", e.target.value)
                        }
                        errorMsg={errors.city}
                        error={touched.city && errors.city}
                    />
                </Grid>
                <Grid item lg={6}>
                    <FormLabel component="label">
                        {configJSON.countryLabel}<span style={{ color: "red" }}>*</span>
                    </FormLabel>
                    <Field
                        data-test-id="country"
                        name="country"
                        className="selectField"
                        as={CustomSelect}
                        placeholder="Country"
                        options={countries}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            setFieldValue("country", e.target.value)
                        }}
                        value={values.country}
                        errorMsg={errors.country}
                        error={touched.country && errors.country}
                    />
                </Grid>
                <Grid item lg={6}>
                    <FormLabel component="label">
                        {configJSON.postCodeLabel}<span style={{ color: "red" }}>*</span>
                    </FormLabel>
                    <Field
                        data-test-id="postcode"
                        name="postcode"
                        className="inputField"
                        size="small"
                        as={CustomInput}
                        placeholder="Post Code"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            setFieldValue("post_code", e.target.value)
                        }
                        value={values.post_code}
                        errorMsg={errors.post_code}
                        error={touched.post_code && errors.post_code}
                    />
                </Grid>
            </>
        );
    };

    renderDelegateMemberForm = () => {
        return (
            <>
                <Box mt={3} mr={7}>
                    <Formik
                        initialValues={this.state.delegateMember}
                        validationSchema={this.delegateMemberValidationSchema(this.state.phoneNumberLength)}
                        onSubmit={(values) => {
                            this.handleDelegateFormSubmit(values)
                        }}
                        enableReinitialize={true}
                        data-test-id="delegatemembersForm"
                    >
                        {({ values, errors, touched, setFieldValue }) => (
                            <Form>
                                {this.renderImageField(values, setFieldValue)}
                                <Grid container spacing={2}>
                                    <Grid item lg={6}>
                                        <FormLabel component="label">
                                            {configJSON.firstNameLabel}<span style={{ color: "red" }}>*</span>
                                        </FormLabel>
                                        <Field
                                            data-test-id="first_name"
                                            name="first_name"
                                            className="inputField"
                                            size="small"
                                            as={CustomInput}
                                            placeholder="Enter your first name"
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                                setFieldValue("first_name", e.target.value)
                                            }
                                            value={values.first_name}
                                            error={touched.first_name && errors.first_name}
                                            errorMsg={errors.first_name}
                                        />
                                    </Grid>
                                    <Grid item lg={6}>
                                        <FormLabel component="label">
                                            {configJSON.middleNameLabel}
                                        </FormLabel>
                                        <Field
                                            data-test-id="middle_name"
                                            name="middle_name"
                                            className="inputField"
                                            size="small"
                                            as={CustomInput}
                                            placeholder="Enter your middle name"
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                                setFieldValue("middle_name", e.target.value)
                                            }
                                            value={values.middle_name}
                                        />
                                    </Grid>
                                    <Grid item lg={6}>
                                        <FormLabel component="label">
                                            {configJSON.lastNameLabel}<span style={{ color: "red" }}>*</span>
                                        </FormLabel>
                                        <Field
                                            data-test-id="last_name"
                                            name="last_name"
                                            className="inputField"
                                            size="small"
                                            as={CustomInput}
                                            placeholder="Enter your last name"
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                                setFieldValue("last_name", e.target.value)
                                            }
                                            value={values.last_name}
                                            error={touched.last_name && errors.last_name}
                                            errorMsg={errors.last_name}
                                        />
                                    </Grid>
                                    <Grid item lg={6}>
                                        <FormLabel component="label">
                                            {configJSON.nationaliti}<span style={{ color: "red" }}>*</span>
                                        </FormLabel>
                                        <Field
                                            data-test-id="nationality"
                                            className="selectField"
                                            name="nationality"
                                            placeholder="Select your Nationality"
                                            as={CustomSelect}
                                            options={this.state.nationalities}
                                            value={values.nationality}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                                setFieldValue("nationality", e.target.value)
                                            }
                                            error={touched.nationality && errors.nationality}
                                            errorMsg={errors.nationality}
                                        />
                                    </Grid>
                                    <Grid item lg={6}>
                                        <FormLabel component="label">
                                            {configJSON.genderLabel}<span style={{ color: "red" }}>*</span>
                                        </FormLabel>
                                        <Field
                                            data-test-id="gender"
                                            name="gender"
                                            className="selectField"
                                            as={CustomSelect}
                                            placeholder="Select gender"
                                            options={[
                                                { value: 1, name: "Male" },
                                                { value: 2, name: "Female" },
                                                { value: 3, name: "Other" },
                                            ]}
                                            value={values.gender}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                                setFieldValue("gender", e.target.value)
                                            }
                                            error={touched.gender && errors.gender}
                                            errorMsg={errors.gender}
                                        />
                                    </Grid>
                                    <Grid item lg={6}>
                                        <FormLabel component="label">
                                            {configJSON.emailIdLabel}<span style={{ color: "red" }}>*</span>
                                        </FormLabel>
                                        <Field
                                            data-test-id="email"
                                            className="inputField"
                                            name="email"
                                            size="small"
                                            placeholder="example@gmail.com"
                                            as={CustomInput}
                                            value={values.email}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                                setFieldValue("email", e.target.value)
                                            }
                                            error={touched.email && errors.email}
                                            errorMsg={errors.email}
                                        />
                                    </Grid>
                                    <Grid item lg={6}>
                                        {this.renderPhoneInput(values, errors, touched, setFieldValue)}
                                    </Grid>
                                    <Grid item lg={6}>
                                        <FormLabel component="label">
                                            {configJSON.dateOfBirthLabel}<span style={{ color: "red" }}>*</span>
                                        </FormLabel>
                                        <Field
                                            data-test-id="date_of_birth"
                                            name="date_of_birth"
                                            className="inputField"
                                            size="small"
                                            as={CustomDatePicker}
                                            placeholder="DD/MM/YYYY"
                                            endIcon={
                                                this.calenderIcon
                                            }
                                            onChange={(date: Date) =>
                                                setFieldValue("date_of_birth", date.toLocaleDateString("en-GB"))
                                            }
                                            ageRestriction={16}
                                            value={values.date_of_birth}
                                            error={touched.date_of_birth && errors.date_of_birth}
                                            errorMsg={errors.date_of_birth}
                                        />
                                    </Grid>
                                    {this.renderDelegateMemberAddressForm(values, errors, touched, setFieldValue)}
                                    <Grid item lg={6}>
                                        <FormLabel component="label">
                                            {configJSON.securityPhraseLabel}<span style={{ color: "red" }}>*</span>
                                        </FormLabel>
                                        <Field
                                            data-test-id="security_phrase"
                                            name="security_phrase"
                                            className="inputField"
                                            size="small"
                                            as={CustomInput}
                                            placeholder="Security phrase"
                                            endIcon={this.renderInfoIcon}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                                setFieldValue("security_phrase", e.target.value)
                                            }
                                            value={values.security_phrase}
                                            error={touched.security_phrase && errors.security_phrase}
                                            errorMsg={errors.security_phrase}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Box className="btnWrapper">
                                            <Button
                                                data-test-id="createProfileBtn"
                                                variant="contained"
                                                style={{ width: "240px", height: "48px" }}
                                                type="submit"
                                            >
                                                {configJSON.saveChangesProfileBtnText}
                                            </Button>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Form>
                        )}
                    </Formik>
                </Box>
            </>
        )
    }

    renderPhoneInput = (
        values: FormikValues,
        errors: FormikErrors<IDelegateMember>,
        touched: FormikTouched<IDelegateMember>,
        setFieldValue: {
            (field: string,
                value: any,
                shouldValidate?: boolean | undefined): void; (arg0: string, arg1: string): void;
        }) => {
        return (
            <>
                <FormLabel component="label">
                    {configJSON.phoneNumber}<span style={{ color: "red" }}>*</span>
                </FormLabel>
                <Box>
                    <PhoneInput
                        data-test-id="full_phone_number"
                        country={'gb'}
                        value={values.country_code?.toString() + values.phone_number?.toString()}
                        onChange={(value, country) =>
                            this.handlePhoneInputChange(value, country, setFieldValue)
                        }
                        inputStyle={{
                            width: '300px',
                            height: '48px',
                            border: (touched.phone_number && errors.phone_number) ? '1px solid red' : '1px solid #e0e0e0',
                            borderRadius: '8px',
                            padding: '10px 40px',
                        }}
                        containerStyle={{
                            margin: '5px 0',
                            width: '300px',
                            height: '48px',
                        }}
                        inputProps={{
                            name: 'phone',
                            autoFocus: false,
                            required: true,
                        }}
                        buttonStyle={{
                            border: (touched.phone_number && errors.phone_number) ? '1px solid red' : '1px solid #e0e0e0',
                            borderTopLeftRadius: '8px',
                            borderBottomLeftRadius: '8px',
                        }}
                    />
                    {touched.phone_number && errors.phone_number &&
                        <FormHelperText error className="errorMsg">
                            <InfoOutlinedIcon className="errorIcon" />
                            <span>{errors.phone_number}</span>
                        </FormHelperText>
                    }
                </Box>
            </>
        );
    }
    // Customizable Area End

    render() {
        // Customizable Area Start
        const {
            stepsDelegates
        } = this.state;
        return (
            <>
                <Wrapper>

                    <ThemeProvider theme={theme}>
                        <Wrapper>
                            <WebHeader navigation={this.props.navigation} />
                            <Box className="containerBoxsDelegate">
                                <Box>
                                    <BreadcrumbStepper data-test-id="breadcrumbStepper" steps={stepsDelegates} onStepClick={(path) => this.handleNavigation(path)} />
                                </Box>
                                <Box className="containerDeleagte">
                                <Container>
                                <Box>
                                    {this.renderDelegateMemberForm()}

                                </Box>
                                </Container>
                            </Box>
                            </Box>
                       
                        <WebFooter handleNavigation={this.handleNavigation} />
                        </Wrapper>
                    </ThemeProvider>

                </Wrapper>
            
                <GenericModal open={this.state.openDialog} data-test-id="data-edited" onClose={this.handleCloseDialogEditMember}>
                    <ThemeProvider theme={theme}>
                        <DialogWrapper>
                            <Box className="dialogContentWrappersMember">
                                <Box className="dialogDescriptionWrapperEditMember">
                                    <img alt="success"
                                        src={rightTickImg}
                                        style={{
                                            width: "56px",
                                            height: "56px",
                                            margin: "0px auto"
                                        }} />
                                    <Typography variant="body2" align="center">
                                        {configJSON.dialogMessageText}
                                    </Typography>
                                </Box>
                            </Box>
                        </DialogWrapper>
                    </ThemeProvider>
                </GenericModal>
               
            </>
            //Merge Engine End DefaultContainer
        );
        // Customizable Area End
    }
}

// Customizable Area Start
const Wrapper = styled('div')(({ theme }) => ({
    "& .rightPanelWrapper": {
        overflowY: "auto",
        maxHeight: "100vh",
        boxSizing: "border-box",
    },
    "& .rightPanelContentWrapper": {
        maxWidth: "462px",
        margin: "0 auto",
    },
    "& .container": {
        minHeight: '100vh',
    },
    "& .containerBoxsDelegate": {
        padding: "50px 72px",
    },
    "& .containerDeleagte": {
        display: "flex",
      
    },
    "& .stepperWrapper": {
        width: "415px",
    },
    "& .stepper": {
        fontFamily: "Lato !important",
        fontSize: "16px",
        fontWeight: 600,
        lineHeight: "24px",
        padding: "0",
    },
    "& .stepLabel": {
        fontFamily: "Lato",
        fontWeight: 600,
        fontSize: "16px",
        lineHeight: "24px",
    },
    "& .createProfileFormTitleWrapper": {
        margin: "16px 0px",
        width: "327px",
        height: "59",
    },
    "& .profilePicAvatar": {
        width: "62px",
        height: "62px",
    },
    "& .ErrorOutlineIcon": {
        "&:hover": {
            fill: "#9176C4",
        },
        color: "#9176C4",
    },
    "& .profilePic": {
        marginBottom: "16px",
    },
    "& .inputField": {
        height: "48px",
        width: "300px",
        borderRadius: "8px",
    },
    "& .calenderIcon": {
        cursor: "grab",
    },
    "& .selectField": {
        borderRadius: "8px",
        margin: "5px 0px",
        display: "block",
        height: "48px",
        width: "300px",
    },
    "& .addressTitle": {
        display: "flex",
        alignItems: "center",
        gap: "5px",
        maxWidth: "570px",
    },
    "& .btnWrapper": {
        display: "flex",
        justifyContent: "center",
        gap: "8px",
    },
    "& .confirmBtnWrapper": {
        display: "flex",
        justifyContent: "center",
    },
 
    "& .delegateMemberWrapper": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    },
    "& .delegateMemberLabel": {
        fontFamily: "Lato",
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: "22px",
        color: "#1E293B",
        display: "flex",
        alignItems: "center",
        gap: "8px",
    },
    "& .addDelegateMemberFormTitleWrapper": {
        maxWidth: "605px",
        margin: "16px 0px",
    },
    "& .alignCenter": {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    "& .infoIcon": {
        fill: "#A190C2",
        "&:hover": {
            backgroundColor: "#A190C2",
            fill: "#FFF",
            borderRadius: "100px",
        },
    },
   
    "& .react-tel-input .selected-flag:hover": {
        backgroundColor: "unset",
    },
    "& .react-tel-input .flag-dropdown.open .selected-flag": {
        backgroundColor: "unset",
    },

    "& .errorIcon": {
        marginRight: "2px",
        marginBottom: "3px",
        width: "16px",
        height: "16px",
    },
    "& .errorMsg": {
        display: "flex",
        textAlign: "center",
        alignItems: "center",
        fontSize: "12px",
        color: "#DC2626",
    },
}));

const DialogWrapper = styled('div')(({ theme }) => ({
    "& .dialogContentWrappersMember": {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  "& .dialogDescriptionWrapperEditMember": {
    maxWidth: "339px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    gap: "8px",
    marginBottom: "32px",
  },
    "& .rightTickImg": {
        height: "72px",
        width: "72px",
    },

}));

const styledMyLocationTwoToneIcon =
    styled(MyLocationTwoToneIcon)({
        fill: "#A190C2",
    });

const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ tooltip: className }} />
))(({ theme }) => ({
    backgroundColor: theme.palette.common.white,
    boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px',
    fontFamily: "Lato",
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "18px",
}));

// Customizable Area End
