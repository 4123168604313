import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { sendAPIRequest} from "../../../components/src/Utils";
import { getStorageData, removeStorageData } from "../../../framework/src/Utilities";
import { toast } from "react-toastify";

interface MapedImage {
  id: string;
  type: string;
  attributes: Attributes;
}

export interface IMedia {
  file_id: number;
  file_name: string;
  file_size: string;
  url: string;
  content_type: string;
}

interface Attributes {
  id: string;
  location_name: string;
  description: string;
  latitude: number;
  longitude: number;
  created_at: string;
  totel_files: number;
  files: (File | IMedia)[];
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any
  id: string;
  // Customizable Area Start
  isMinScreen: boolean
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  mapBreadCrumb: {
    label: string;
    path: string
  }[],
  allMarkers: MapedImage[],
  searchLocation: string,
  searchLocationResult: {}[],
  mapCenter: {
    lat: number
    lng: number
  }
  addLocationDialog: boolean
  isAddLocation: boolean
  name: string
  description: string
  files: ( File | IMedia)[]
  selectedId: string
  successMes:string;
  isEdit: boolean
  nameError: boolean
  isPreview: boolean
  mapZoom: number
  loading: boolean
  fileError: string
  isFromView: boolean
  maxMarker: number
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class GeoLocationSearchController extends BlockComponent<
  Props,
  S,
  SS
> {
  searchBoxRef: google.maps.places.SearchBox | null;
  getMarkersApiCallID: string = ""
  addEditMarkersApiCallID: string = ""
  deleteMarkerApiCallID :string = ""
  userPlanApiCallID: string = ""
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.searchBoxRef = null;
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      mapBreadCrumb: [
        {
          label: 'Home',
          path: 'HomePage'
        },
        {
          label: 'Map',
          path: 'GeoLocationSearch'
        }],
      allMarkers: [],
      searchLocation: "",
      searchLocationResult: [],
      mapCenter: {
        lat: 28.644800,
        lng: 77.216721,
      },
      addLocationDialog: false,
      isAddLocation: false,
      name: "",
      description: "",
      files: [],
      isEdit: false,
      selectedId: "",
      nameError: false,
      isPreview: false,
      mapZoom: 10,
      successMes:"",
      loading: false,
      fileError: "",
      isFromView: false,
      maxMarker: 2
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJSON = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (apiRequestCallId === this.getMarkersApiCallID) {
      if (responseJSON.data.length > 0) {
        let centerData = responseJSON?.data[0]?.attributes
        let mapCenter = { lat: centerData.latitude, lng: centerData.longitude }
        this.setState({ allMarkers: responseJSON.data})
        !this.state.isFromView && this.setState({mapCenter})
      }
    }
    if(apiRequestCallId === this.addEditMarkersApiCallID){
      if(responseJSON.data){
        let newMarkers = this.state.isEdit ? 
        this.state.allMarkers.filter((_data : any)=> parseInt(_data.id) !== parseInt(this.state.selectedId)) : 
          this.state.allMarkers

          let message = this.getSuccessMes()
        this.setState({
          allMarkers: [responseJSON.data , ...newMarkers],
          successMes: message,
          isAddLocation: true
        })
        this.handleCloseDetailsDialog()
      }else{
        this.handleError(responseJSON?.errors)
      }
      this.setState({loading: false})
    }
    if(apiRequestCallId === this.deleteMarkerApiCallID){     
        this.removeMarkerResponse(responseJSON)
    }
    if(apiRequestCallId === this.userPlanApiCallID){
      if(responseJSON.data){
        let maxMarker = responseJSON.data?.attributes?.feature?.max_map_pins 
        this.setState({maxMarker})
      }
    }

    // Customizable Area End
  }

  // Customizable Area Start

  async componentDidMount() {
    super.componentDidMount();
    this.setLocalData()
    this.getPreviousMarkers()
    this.getUserPlan()
  }

  getUserPlan = async ()=>{
    const token = await getStorageData("token");
    this.userPlanApiCallID = sendAPIRequest(
      configJSON.getUserPlanEndPoint,
      { method: configJSON.validationApiMethodType,
        headers: {token}
      }
    );
  }

  getPreviousMarkers = async () => {
    const token = await getStorageData("token");

    this.getMarkersApiCallID = sendAPIRequest(
      configJSON.getPreviousMarkersEndPoint,
      {
        method: configJSON.validationApiMethodType,
        headers: {
          token,
        }
      }
    );
  }

  setLocalData = async() =>{
    let markerData = await getStorageData("ViewMarker")
    let data = JSON.parse(markerData)
    if(data?.lat){
      let mapCenter = {lat: Number(data.lat),
        lng: Number(data.lng)
      }
      this.setState({mapCenter, isFromView: true, mapZoom: 15})
    }

    removeStorageData("ViewMarker")
    
  }

  getSuccessMes = () =>{
    let message = this.state.isEdit ? configJSON.editSuccessMessage : configJSON.addSuccessMessage
    return message
  }

  handleNavigation = (route: string) => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), route);
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  };

  handleDeleteID = (callId: string) =>{
    this.deleteMarkerApiCallID = callId
    this.setState({loading: true})
  }

  handlePlacesChanged = () => {
    if(this.searchBoxRef){
      const places = this.searchBoxRef.getPlaces();
      if (places && places.length > 0) {
        const place = places[0];
        if (place.geometry?.location) {
        const location = {
          lat: place.geometry?.location.lat(),
          lng: place.geometry.location.lng(),
        };
  
        this.setState({
          mapCenter: location,
          mapZoom: 11
        })}
      }
    }
  };
  onSearchBoxLoad = (ref: google.maps.places.SearchBox) => {
    this.searchBoxRef = ref;
  };

  handleCloseConfirmLoc = () => {
    this.setState({ isAddLocation: false , successMes: ""})
  }
  handleAddLoc = () => {
    this.handleCloseConfirmLoc()
    if(this.state.maxMarker < this.state.allMarkers.length){
      let error = configJSON.maxMarkerError.replace("${number}", this.state.maxMarker)
      toast.error(error)
      return
    }
    this.setState({addLocationDialog: true })
  }

  removeMarkerResponse = (responseJSON : {message: string, errors: {message:string}[]}) =>{
    if(responseJSON.message === "Location was successfully removed"){
    let newMarkers = this.state.allMarkers.filter((_data : any)=> parseInt(_data.id) !== parseInt(this.state.selectedId))
    this.setState({allMarkers: newMarkers, 
      successMes: configJSON.deleteSuccessMessage, 
      isAddLocation: true})
    this.handleCloseDetailsDialog()
    }else{
      this.handleError(responseJSON?.errors)
    }
  }

  handleZoomClick = () =>{
    let pathMap = this.props.isMinScreen ? "Maps" : "HomePage";
    this.handleNavigation(pathMap)
  }
  handleCloseDetailsDialog = () =>{
    this.setState({
      addLocationDialog: false, 
      isEdit: false, 
      name: "",
      description: "",
      files: [],
      selectedId: "",
      isPreview: false,
      fileError:""
    })
  }

  handlePreviewChange = () =>{
    this.setState((prevState)=> ({
      isPreview: !prevState.isPreview
    }))
  }

  handleAddEditID = (callID: string) =>{
    this.addEditMarkersApiCallID = callID
    this.setState({loading: true})
  }

  handleError = (errors: {message:string}[])=>{
    let message = errors[0]?.message
    toast.error(message)
    
  }

  handleMapClick = (event: google.maps.MapMouseEvent) => {
    if(!event.latLng) return
    let location = { lat: event.latLng.lat(), lng: event.latLng.lng()}
    this.setState({
      mapCenter: location,
      isAddLocation: true
    })
  };
  markerDetails = () =>{
    let {name, description, files, mapCenter,isEdit,loading, isPreview, addLocationDialog, selectedId} = this.state
    return {
      name,
    description,
    files,
    mapCenter,
    isEdit,
    isPreview,
    addLocationDialog,
    selectedId,
    loading,
    handlePreviewChange: this.handlePreviewChange,
    handleCloseDetailsDialog: this.handleCloseDetailsDialog,
    handleDeleteID: this.handleDeleteID,
    handleAddEditID: this.handleAddEditID
    }
  }

  handleMarkerClick = (event: React.MouseEvent<HTMLInputElement>, selectMark: MapedImage ) =>{
    event.stopPropagation();
    let {latitude , longitude , location_name , description , id, files} = selectMark.attributes
    let mapCenter = { lat: latitude, lng: longitude }
    this.setState({
      mapCenter,
      isEdit: true,
      name: location_name,
      description,
      files,
      selectedId: id,
      isPreview: true
    },()=>this.setState({ addLocationDialog: true,}))
  }

  // Customizable Area End
}
