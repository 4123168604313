import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import React from "react";
import * as Yup from "yup";
import { sendAPIRequest } from "../../../components/src/Utils";
import { getStorageData ,setStorageData} from "../../../framework/src/Utilities";
import { FormikValues } from "formik";
import { ISelectOptions } from "../../../blocks/customform/src/LegalInformationController.web";
import { toast } from "react-toastify";
import { IStep } from "../../multipageforms/src/LegalDataEditController.web";
export interface IDelegateMember {
  id: number;
  photo: string | null;
  first_name: string;
  middle_name: string;
  last_name: string;
  nationality: string;
  gender: string;
  gender_name: string;
  email: string;
  country_code: string;
  phone_number: string;
  full_phone_number: string;
  date_of_birth: string;
  address_line_1: string;
  address_line_2: string;
  landmark: string;
  city: string;
  country: string;
  post_code: string;
  security_phrase: string;
  delegate_key: string;
};

export interface IDelegateMemberAPIResponse {
  id: number;
  photo_url: string;
  first_name: string;
  middle_name: string;
  last_name: string;
  nationality: string;
  gender: string;
  gender_name: string;
  email: string;
  country_code: string;
  phone_number: string;
  full_phone_number: string;
  date_of_birth: string;
  address_line_1: string;
  address_line_2: string;
  landmark: string;
  city: string;
  country: string;
  post_code: string;
  security_phrase: string;
  delegate_key: string;
  account_id: number,
  created_at: string,
};


// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  activeStep: "Profile" | "Delegate Members" | "completed";
  delegateKey: string;
  delegateMember: IDelegateMember;
  delegateMember1: IDelegateMember;
  delegateMember2: IDelegateMember;
  showMemberCard1: boolean;
  showMemberCard2: boolean;
  profileImage: string | ArrayBuffer | Blob | null | undefined;
  removePhoto: boolean;
  switchChecked: boolean;
  openDialog: boolean;
  openAllSetDialog: boolean;
  editMode: boolean;
  anchorEl: Element | null;
  errorMessage: string;
  successMsg: string;
  nationalities: ISelectOptions[];
  phoneNumberLength: number;
  stepsDelegates:IStep[]
  delegateKey1:any
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class DelegateMembersController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  callEditDelegateMemberApiId: string = "";
  callGetNationalitiesApiId: string = "";
  callShowsDelegateMemberApiId:string ="";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      activeStep: "Delegate Members",
      delegateKey: "delegate_1",
      delegateMember: {
          id: 0,
          photo: "",
          first_name: "",
          middle_name: "",
          last_name: "",
          nationality:"",
          gender: "",
          gender_name: "",
          email: "",
          country_code: "+44",
          phone_number: "",
          full_phone_number: "",
          date_of_birth: "",
          address_line_1: "",
          address_line_2: "",
          landmark: "",
          city: "",
          country: "",
          post_code: "",
          security_phrase: "",
          delegate_key: "delegate_1",
      },
      delegateMember1: {
        id: 0,
        photo: "",
        first_name: "",
        middle_name: "",
        last_name: "",
        nationality:"",
        gender: "",
        gender_name: "",
        email: "",
        country_code: "+44",
        phone_number: "",
        full_phone_number: "",
        date_of_birth: "",
        address_line_1: "",
        address_line_2: "",
        landmark: "",
        city: "",
        country: "",
        post_code: "",
        security_phrase: "",
        delegate_key: "delegate_1",
    },
      delegateMember2: {
        id: 0,
        photo: "",
        first_name: "",
        middle_name: "",
        last_name: "",
        nationality:"",
        gender: "",
        gender_name: "",
        email: "",
        country_code: "+44",
        phone_number: "",
        full_phone_number: "",
        date_of_birth: "",
        address_line_1: "",
        address_line_2: "",
        landmark: "",
        city: "",
        country: "",
        post_code: "",
        security_phrase: "",
        delegate_key: "delegate_2",
    },
      showMemberCard1: false,
      showMemberCard2: false,
      profileImage: null,
      removePhoto: false,
      switchChecked: false,
      openDialog: false,
      openAllSetDialog: false,
      editMode: false,
      anchorEl: null,
      errorMessage: "",
      successMsg: "",
      nationalities: [],
      phoneNumberLength: 10,
      stepsDelegates: [
        { label: 'Home', path: 'HomePage' },
        { label: 'Account', path: 'HomePage' },
        { label: 'Delegates', path: 'DelegateMembersEdit' },
      ],
      delegateKey1:""
      // Customizable Area End
    };
    // Customizable Area Start
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    this.getNationalitiesListMember();
     this.getShowsDelegateMemberApi()
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJSON = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    this.apiSuccessCallBackController(apiRequestCallId, responseJSON);

    // Customizable Area End
  }

  // Customizable Area Start

  //API integration
  apiSuccessCallBackController = (
    apiRequestCallId: string,
    responseJSON: Record<string, unknown>
  ) => {
    const successCallbackMap = {
      [this.callEditDelegateMemberApiId]: this.handleEditDelegateMemberApiResponse,
      [this.callGetNationalitiesApiId]: this.handleGetNationalitiesListMemberApiResponse,
      [this.callShowsDelegateMemberApiId]: this.handleShowsDelegateMemberApiIdResponse,
    }

    if (apiRequestCallId) {
      const successCallback: (responseJSON: Record<string, unknown>) => void = successCallbackMap[apiRequestCallId]
      !!successCallback && successCallback(responseJSON)
    }
  }

  handleErrorResponse = (responseJSON: Record<string, any>) => {
    const { errors: possibleErrors } = responseJSON;
    if (possibleErrors) {
      let key = Object.keys(possibleErrors[0])[0];
      toast.error(possibleErrors[0][key]);
      return true; // Indicates that there was an error
    }
    return false; // Indicates that there was no error
  }

  delegateMemberValidationSchema = (phoneNumberLength: number) => Yup.object().shape({
    first_name: Yup.string().nullable().required("Please enter your first name"),
    last_name: Yup.string().nullable().required("Please enter your last name"),
    nationality: Yup.string().nullable().required("Please select your nationality"),
    gender: Yup.string().nullable().required("Please select your gender"),
    email: Yup.string().nullable().email("Please enter valid email ID").required("Please enter your email ID"),
    phone_number: Yup.string().nullable()
    .transform((value) => {
      return value.replace(/\D/g, '');  // Remove non-numeric characters
    })
      .matches(
        new RegExp(`^\\d{${phoneNumberLength}}$`),
        `Phone number must be ${phoneNumberLength} digits`
      )
      .required("Please enter a phone number"),
    date_of_birth: Yup.string().nullable().required("Please enter your date of birth"),
    address_line_1: Yup.string().nullable().required("Please enter your address line 1"),
    address_line_2: Yup.string().nullable().required("Please enter your address line 2"),
    city: Yup.string().nullable().required("Please enter your city"),
    country: Yup.string().nullable().required("Please select your country"),
    post_code: Yup.string().nullable().required("Please enter a valid post code"),
    security_phrase: Yup.string().nullable().required("Please enter a security phrase"),
  });

  calenderIcon = () => {
    return (
      <div className="calenderIcon">
        <svg fill="none" width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path stroke="#A190C2" d="M8 2V5" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
        <path stroke="#A190C2" d="M16 2V5" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
        <path stroke="#A190C2" d="M3.5 9.08984H20.5" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M21 8.5V17C21 20 19.5 22 16 22H8C4.5 22 3 20 3 17V8.5C3 5.5 4.5 3.5 8 3.5H16C19.5 3.5 21 5.5 21 8.5Z" stroke="#A190C2" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
        <path stroke="#A190C2" d="M11.9955 13.6992H12.0045" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        <path stroke="#A190C2" d="M8.29431 13.6992H8.30329" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        <path stroke="#A190C2" d="M8.29431 16.6992H8.30329" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
      </svg>
      </div>
    );
  };

//web events
  handleCloseAllSetDialog = () => {
    this.setState({ openAllSetDialog: !this.state.openAllSetDialog });
  };

  handleProfileImg = (event: React.ChangeEvent<HTMLInputElement>, setFieldValue:
    {
      (field: string,
        value: any,
        shouldValidate?: boolean | undefined): void; 
        (arg0: string, arg1: string): void;
    }) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      setFieldValue("photo", file);

      const reader = new FileReader();

      reader.onload = (event) => {
        this.setState({ 
          profileImage: event.target?.result,
          anchorEl: null ,
          removePhoto: false,
        });
      };
      reader.readAsDataURL(file);
    }
  };

  handleProfilePicEditMenu = (event: React.MouseEvent<HTMLInputElement>) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleProfilePicEditMenuClose = () => {
    this.setState({ anchorEl: null });
  };

  handleRemoveProfilePic = (setFieldValue:
    {
      (field: string,
        value: any,
        shouldValidate?: boolean | undefined): void; (arg0: string, arg1: string): void;
    }) => {
    setFieldValue("photo", null);
    this.setState({ profileImage: null, anchorEl: null, removePhoto: true });
  };

  handleNavigation = (route: string) => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), route);
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  };



  handleDelegateFormSubmit = async (
    values: FormikValues,
    ) => {
    this.setState({
      errorMessage: "",
      successMsg: "",
    });
    const token = await getStorageData("token");
    const formData = new FormData();

    Object.entries(values).forEach(([keyName, value]) => {
      if(keyName !== 'id' && value !== undefined && keyName !== 'full_phone_number'){
        if(keyName === 'photo'){
            if(this.state.profileImage){
              formData.append(`partner[${keyName}]`, value);
            }
            else if(value === null){
               formData.append(`partner[${keyName}]`, new Blob([], {type: "image/jpg"}), "");
            }
        }
        else if(keyName === 'delegate_key'){
            formData.append(`partner[${keyName}]`, this.state.delegateKey);
        }
        else{
            formData.append(`partner[${keyName}]`, value);
        }
      }
    });

    formData.append(`partner[delegate_key]`, this.state.delegateKey);

      this.callEditDelegateMemberApiId = sendAPIRequest(
        `${configJSON.editDelegateMemberApiEndPoint}/${values.id}?remove_photo=${this.state.removePhoto}`,
        {
          method: configJSON.httpPutMethod,
          headers: {
            token,
          },
          body: formData,
        }
      );
  };


  handleShowsDelegateMemberApiIdResponse =(responseJSON:any)=>{
    const filteredObject = responseJSON.data
    const delegateMember = {
          id: filteredObject.id,
          photo: filteredObject.attributes.photo_url,
          first_name: filteredObject.attributes.first_name,
          middle_name: filteredObject.attributes.middle_name,
          last_name: filteredObject.attributes.last_name,
          country_code: filteredObject.attributes.country_code,
          phone_number: filteredObject.attributes.phone_number,
          full_phone_number: filteredObject.attributes.full_phone_number,
          nationality: filteredObject.attributes.nationality,
          gender: filteredObject.attributes.gender,
          gender_name: filteredObject.attributes.gender_name,
          email: filteredObject.attributes.email,
          date_of_birth: filteredObject.attributes.date_of_birth,
          address_line_1: filteredObject.attributes.address_line_1,
          address_line_2: filteredObject.attributes.address_line_2,
          city: filteredObject.attributes.city,
          landmark: filteredObject.attributes.landmark,
          country: filteredObject.attributes.country,
          post_code: filteredObject.attributes.post_code,
          security_phrase: filteredObject.attributes.security_phrase,
          delegate_key: filteredObject.attributes.delegate_key,
        } as IDelegateMember;
    this.setState({ 
            delegateMember: delegateMember, 
          }); 
  }

  handleEditDelegateMemberApiResponse = async(responseJSON: any) => {
    await setStorageData('showUpdateedDeletedID',responseJSON.data.id); 
    if (this.handleErrorResponse(responseJSON)) return;
    this.setState({ errorMessage: '', openDialog: true });
  };

  handleGetNationalitiesListMemberApiResponse = (responseJSON: Record<string, unknown>) => {
    if (this.handleErrorResponse(responseJSON)) { 
      return; 
    }
  
      const response = responseJSON as {
        nationalities?: string[];
      };
  
      if (response.nationalities) {
        let nationalities: ISelectOptions[] = [];
  
        response.nationalities.forEach((nationality, index) => {
          nationalities.push({
            value: index.toString(),
            name: nationality,
          });
        });
  
        this.setState({ nationalities });
      }
  };

  getNationalitiesListMember = async () => {
    this.callGetNationalitiesApiId = sendAPIRequest(
        configJSON.getNationalitiesApiEndPoint,
        {
          method: configJSON.httpGetMethod,
        }
      );
  };


  handlePhoneInputChange = (value: string, country: any, setFieldValue:
    {
      (field: string, value: any, shouldValidate?: boolean | undefined): void; (arg0: string, arg1: string): void;
    }) => {
        setFieldValue("country_code", country.dialCode);
        setFieldValue("phone_number", value.slice(country.dialCode.length));

        this.setState({ 
          phoneNumberLength: country.format.match(/\./g).length - country.dialCode.length
        });
  };

  getShowsDelegateMemberApi =async()=>{
    const showParticularData =await getStorageData("showParticularData")
    const token = await getStorageData("token");
    this.callShowsDelegateMemberApiId = sendAPIRequest(
    `/bx_block_customisableuserprofiles/delegate_members/${showParticularData}`,
      {
        method: configJSON.httpGetMethod,
        headers: {
          token,
        },
      }
    );
  }
  handleCloseDialogEditMember = () => {
    window.history.back()
    this.setState({ openDialog: false });
  };
  handleBacks = () => {
    window.history.back();
  };
  // Customizable Area End
}
