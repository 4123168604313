import React from "react";
// Customizable Area Start
import { ThemeProvider } from "@material-ui/core/styles";
import MedicalDataController, {
    configJSON,
    Props,
} from "./MedicalDataController.web";
import { 
  Box, 
  Grid, 
  Typography, 
  Button,
  FormLabel, 
  RadioGroup, 
  FormControlLabel,
  Checkbox,
  FormGroup,
  Container, 
} from "@material-ui/core";
import WebFooter from "../../../components/src/Footer.web";
import WebHeader from "../../../blocks/landingpage/src/WebHeader.web";
import { 
    Field, 
    FieldArray, 
    Form, 
    Formik, 
    FormikErrors, 
    FormikTouched, 
    FormikValues 
} from "formik";
import CustomSelect from "../../../components/src/CustomSelect";
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import CustomInput from "../../../components/src/CustomInput";
import MediaUpload from "../../../components/src/MediaUpload.web";
import { IMedicalConditions, IMedicalInformations } from "../../../blocks/customform/src/MedicalInformationController.web";
import { DialogWrapper, StyledRadio } from "../../../blocks/customform/src/LegalInformation.web";
import BreadcrumbStepper from "../../../components/src/BreadcrumbStepper.web";
import ErrorMessage from "../../../components/src/ErrorMessage";
import { Wrapper, theme } from "./LegalDataEdit.web";
import GenericModal from "../../../components/src/GenericModal";
import { rightTickImg } from "../../../blocks/forgot-password/src/assets";

const levelOfCondition = [
  { value: 1, name: "Critical" },
  { value: 2, name: "Stable" },
  { value: 3, name: "Fair" },
];

const conditionType = [
    { value: 1, name: "Hereditary" },
    { value: 2, name: "Non- Hereditary" },
];

const bloodGroup = [
  { value: 1, name: "O+ (O Positive)" },
  { value: 2, name: "O- (O Negative)" },
  { value: 3, name: "A+ (A Positive)" },
  { value: 4, name: "A- (A Negative)" },
  { value: 5, name: "B+ (B Positive)" },
  { value: 6, name: "B- (B Negative)" },
  { value: 7, name: "AB+ (AB Positive)" },
  { value: 8, name: "AB- (AB Negative)" },
];
// Customizable Area End

export default class MedicalInformation extends MedicalDataController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderMedicalConditionData = (
    values: FormikValues,
    setFieldValue: {
      (field: string,
        value: any,
        shouldValidate?: boolean | undefined): void; (arg0: string, arg1: string): void;
    }) => {
      const { 
        isEdit } = this.state;
    return(
      <FieldArray name="medical_condition" data-test-id="fieldArray">
        {({ remove, push }) => (
          values.medical_condition.map((medicalCondition: IMedicalConditions, index: number) => {
            return(
              <Grid item xs={12} style={{ margin: "24px 0" }} className={`${isEdit ? "formSectionBackground" : ""}`}>
                <Grid container spacing={2}>
                  <Grid item xs={12} className="policyDetailsHeaderWrapper">
                    <Typography data-test-id="medicalConditionFormTitle" variant={this.state.isEdit ? "h3" : "h2"}>
                      {configJSON.medicalConditionFormTitle}
                    </Typography>
                    {(index === 0 && isEdit) && (
                    <Button 
                      className="addMoreBtn"
                      data-test-id = "addMoreConditionsBtn"
                      onClick={() => push({
                        condition_type: "",
                        condition_name: "",
                        level: "",
                        description: "",
                      })}
                    >
                      <AddCircleOutlineIcon className="circleOutlineIcon" />
                      {configJSON.addMoreConditionsBtnText}
                    </Button>
                    )}
                    {(index !== 0 && isEdit) && (
                    <Button 
                      data-test-id="removeConditionBtn"
                      className="addMoreBtn"
                      onClick={() => remove(index)}>
                      <RemoveCircleOutlineIcon className="circleOutlineIcon" />
                      {configJSON.removeConditionBtnText}
                    </Button>)}
                  </Grid>
                  <Grid item lg={4} md={4} sm={6}>
                    <FormLabel component="label">
                        {configJSON.conditionTypeLabel}<span style={{ color: "red" }}>*</span>
                    </FormLabel>
                    <Field
                      className="selectField"
                      data-test-id="conditionType"
                      name="conditionType"
                      disabled={!isEdit}
                      as={CustomSelect}
                      placeholder="Select condition type"
                      options={conditionType}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setFieldValue(`medical_condition.${index}.condition_type`, e.target.value)
                      }}
                      value={medicalCondition.condition_type}
                    />
                    <ErrorMessage name={`medical_condition.${index}.condition_type`} />
                  </Grid>
                  <Grid item lg={4} md={4} sm={6}>
                    <FormLabel component="label">
                        {configJSON.conditionNameLabel}<span style={{ color: "red" }}>*</span>
                    </FormLabel>
                    <Field
                      className="inputField"
                      data-test-id="conditionName"
                      name="conditionName"
                      disabled={!isEdit}
                      as={CustomInput}
                      size="small"
                      placeholder="Enter condition name"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setFieldValue(`medical_condition.${index}.condition_name`, e.target.value)
                      }}
                      value={medicalCondition.condition_name}
                    />
                    <ErrorMessage name={`medical_condition.${index}.condition_name`} />
                  </Grid>
                  <Grid item lg={4} md={4} sm={6}>
                    <FormLabel component="label">
                        {configJSON.levelOfConditionLabel}
                    </FormLabel>
                    <Field
                      className="selectField"
                      data-test-id="levelOfCondition"
                      name="levelOfCondition"
                      disabled={!isEdit}
                      as={CustomSelect}
                      placeholder="Level of Condition"
                      options={levelOfCondition}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setFieldValue(`medical_condition.${index}.level`, e.target.value)
                      }}
                      value={medicalCondition.level}
                    />
                    <ErrorMessage name={`medical_condition.${index}.level`} />
                  </Grid>
                  <Grid item xs={12}>
                    <FormLabel component="label">{configJSON.descriptionFieldLabel}</FormLabel>
                    <Field
                      name="medicalConditionDescription"
                      data-test-id="medicalConditionDescription"
                      size="medium"
                      multiline
                      style={{ minHeight: "84px !important" }}
                      disabled={!isEdit}
                      as={CustomInput}
                      placeholder="Details about condition"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setFieldValue(`medical_condition.${index}.description`, e.target.value)
                      }
                      value={medicalCondition.description}
                    />
                  </Grid>
                </Grid>
              </Grid>
            );
          })
        )}
      </FieldArray>
  )};

  renderOrganDonationData = (
    values: FormikValues, 
    errors: FormikErrors<IMedicalInformations>, 
    touched: FormikTouched<IMedicalInformations>, 
    setFieldValue: {
      (field: string,
        value: any,
        shouldValidate?: boolean | undefined): void; (arg0: string, arg1: string): void;
    }) => {
    const organsList = ['All', 'Lungs', 'Pancreas', 'Liver', 'Heart', 'Other'];
    const { isEdit, savedOrgansList } = this.state;
    return(
      <Grid item xs={12} style={{ margin: "24px 0" }} className={`${isEdit ? "formSectionBackground" : ""}`}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box mb={2}>
              <Typography className="textMargin" variant={this.state.isEdit ? "h3" : "h2"} data-test-id="organDonationDetailsTitle">
                {configJSON.organDonationDetailsFormTitle}
              </Typography>
              <Typography variant="subtitle2" className="textMargin">
                {configJSON.organDonationDetailsFormDescription}
              </Typography>
            </Box>
          </Grid>
          <Grid item lg={3} md={4} sm={6}>
            <FormLabel component="label">{configJSON.idNumberFieldLabel}<span style={{ color: "red" }}>*</span></FormLabel>
            <Field
              name="idNumber"
              data-test-id="idNumber"
              size="small"
              className="inputField"
              disabled={!isEdit}
              as={CustomInput}
              placeholder="Enter ID number"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setFieldValue(`organ_donation.id_number`, e.target.value)
              }
              value={values.organ_donation.id_number}
              error={touched.organ_donation?.id_number && errors.organ_donation?.id_number
              }
              errorMsg={errors.organ_donation?.id_number}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant={this.state.isEdit ? "h3" : "body2"} className="textMargin">
              {configJSON.organsCheckBoxListLabel}
            </Typography>
            {!isEdit && (
              savedOrgansList.length > 0 ? (
                <Box display="flex" gridGap={8}>
                  {savedOrgansList.map((organ, index) => (
                  <Box key={organ}>
                    <Typography variant="h6" className="dataNotAvailable">
                      {organ}{index !== savedOrgansList.length - 1 ? ", " : ""}
                    </Typography>
                  </Box>
                ))}
                </Box>
              ) : (
                <Box>
                  <Typography variant="subtitle1" className="dataNotAvailable">
                    {configJSON.dataNotAvailable}
                  </Typography>
                </Box>
              )
            )}
          </Grid>
          {isEdit && (
            <>
              <Grid item xs={12}>
                <FormGroup row>
                  {organsList.map((organ) => (
                    <Box key={organ}>
                      <Checkbox
                        data-test-id="organCheckboxList"
                        checked={values.organ_donation.organs.includes(organ)}
                        color="primary"
                        onChange={(e) => {
                          if (e.target.checked) {
                            setFieldValue('organ_donation.organs', [...values.organ_donation.organs, organ])
                          } else {
                            setFieldValue(
                              'organ_donation.organs',
                              values.organ_donation.organs.filter((item: string) => item !== organ)
                            );
                          }
                        }}
                      />
                      <FormLabel component="label" className="organCheckboxLabel">
                        {organ}
                      </FormLabel>
                    </Box>
                  ))}
                </FormGroup>
              </Grid>
              <Grid item lg={3} md={4} sm={6}>
                {values.organ_donation.organs.includes("Other") && (
                  <FieldArray name="organ_donation.organ_name" data-test-id="fieldArrayOrganName">
                    {({ remove, push }) => (
                      values.organ_donation.organ_name.map((organName: string, index: number) => {
                        return (
                          <Box className="organNameFieldWrapper" key={index}>
                            <FormLabel component="label">{configJSON.organNameFieldLabel}<span style={{ color: "red" }}>*</span></FormLabel>
                            <Field
                              data-test-id="organName"
                              name="organName"
                              size="small"
                              className="inputField"
                              as={CustomInput}
                              placeholder="Enter organ name"
                              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                setFieldValue(`organ_donation.organ_name.${index}`, e.target.value)
                              }
                              value={organName}
                            />
                            <ErrorMessage name={`organ_donation.organ_name.${index}`} />
                            {index === 0 && (
                              <Button
                                className="addMoreBtn"
                                data-test-id="addMoreOrganNameBtn"
                                onClick={() => push("")}
                              >
                                <AddCircleOutlineIcon className="circleOutlineIcon" />
                                {configJSON.addMoreBtnText}
                              </Button>
                            )}
                            {index !== 0 && (
                              <Button
                                data-test-id="removeOrganNameBtn"
                                className="addMoreBtn"
                                onClick={() => remove(index)}>
                                <RemoveCircleOutlineIcon className="circleOutlineIcon" />
                                {configJSON.removeBtnText}
                              </Button>)}
                          </Box>
                        )
                      }
                      ))}
                  </FieldArray>
                )}
              </Grid>
            </>
            )}
        </Grid>
      </Grid>
  )};

  renderBloodGroupData = (
    values: FormikValues, 
    errors: FormikErrors<IMedicalInformations>, 
    touched: FormikTouched<IMedicalInformations>, 
    setFieldValue: {
      (field: string,
        value: any,
        shouldValidate?: boolean | undefined): void; (arg0: string, arg1: string): void;
    }) => {
      const { isEdit } = this.state;
      const max_medical_documents = this.state.subscriptionData?.features ? this.state.subscriptionData.features.max_medical_documents : 3;
    return(
      <Grid item style={{ margin: "24px 0" }} className={`${isEdit ? "formSectionBackground" : ""}`}>
        <Grid container spacing={2}>
          <Grid item lg={3} md={4} sm={6}>
            <FormLabel component="label">
              {configJSON.bloodGroupFieldLabel}<span style={{ color: "red" }}>*</span>
            </FormLabel>
            <Field
              className="selectField"
              data-test-id="bloodGroup"
              name="bloodGroup"
              as={CustomSelect}
              disabled={!isEdit}
              placeholder="Select blood group"
              options={bloodGroup}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setFieldValue(`blood_type.blood_group`, e.target.value)
              }}
              value={values.blood_type.blood_group}
              error={touched.blood_type?.blood_group && errors.blood_type?.blood_group
              }
              errorMsg={errors.blood_type?.blood_group}
            />
          </Grid>
          <Grid item xs={12}>
            <MediaUpload
              data-test-id="mediaUpload"
              files={this.state.files}
              onUpload={(event: React.ChangeEvent<HTMLInputElement>) => this.handleFileUpload(event, setFieldValue)}
              maxFiles={max_medical_documents}
              readonly={!isEdit}
              title={!isEdit ? "Media" : ""}
            />
            {!isEdit && this.state.files.length === 0 && (
              <Box>
                <Typography variant="subtitle1" className="dataNotAvailable">
                  {configJSON.NoMediaFound}
                </Typography>
              </Box>
            )}
          </Grid>
        </Grid>
      </Grid>
    )};
  // Customizable Area End

  render() {
    // Customizable Area Start
    const {
      isEdit,
      medicalData,
      delegateInCharge,
      steps,
      openDialog,
    } = this.state;
    return (
      <>
        <ThemeProvider theme={theme}>
          <Wrapper>
            <WebHeader navigation={this.props.navigation} />
            <Box className="container">
                <Container>
                    <Box>
                        <BreadcrumbStepper steps={steps} onStepClick={(path) => this.handleNavigation(path)} />
                    </Box>
                <Box className="medicalDataWrapper">
                <Box className="formWrapper">
                  <Formik
                    initialValues={medicalData}
                    validationSchema={this.validationSchema(isEdit)}
                    onSubmit={(values) => {
                      this.handleMedicalDataFormSubmit(values)
                    }}
                    enableReinitialize={true}
                    data-test-id="medicalDataForm"
                  >
                    {({ values, errors, touched, setFieldValue }) => (
                      <Form>
                        <Box className="formWrapper">
                          <Box>
                          <Box className="pageTitle">
                            <Typography data-test-id="pageTitle" align="center" variant="h1" className="titleText">
                              {configJSON.medicalDataPageTitle}
                            </Typography>
                            <Box className="checkboxWrapper">
                              <Checkbox
                                color="primary"
                                checked={values.medical_information.include_in_final_pdf}
                                onChange={(e) => {
                                  setFieldValue('medical_information.include_in_final_pdf', e.target.checked)
                                }}
                                disabled={!isEdit}
                                style={{ color: "#9176C4" }}
                                inputProps={{ 'aria-label': 'primary checkbox' }}
                              />
                              <FormLabel component="label" className="pdfLabel">
                                {configJSON.pdfLabel}
                              </FormLabel>
                            </Box>
                          </Box>
                          <Box>
                            <Grid container spacing={2}>
                              <Grid item xs={4} md={4} sm={6}>
                                <Box className="formSubtitle">
                                    <Typography variant="h2">
                                        {configJSON.delegateInChargeText}
                                    </Typography>
                                </Box>
                                <FormLabel component="label">
                                  {configJSON.delegateInChargeText}<span style={{ color: "red" }}>*</span>
                                </FormLabel>
                                <Field
                                  name="delegateInCharge"
                                  data-test-id="delegateInCharge"
                                  className="selectField"
                                  as={CustomSelect}
                                  disabled={!isEdit}
                                  placeholder="Select Delegate"
                                  options={delegateInCharge}
                                  setValue={true}
                                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                    setFieldValue("medical_information.delegate_id", e.target.value)
                                  }
                                  value={values.medical_information.delegate_id}
                                  error={
                                    touched.medical_information?.delegate_id &&
                                    errors.medical_information?.delegate_id
                                  }
                                  errorMsg={errors.medical_information?.delegate_id}
                                />
                              </Grid>
                              <Grid item xs={12}>
                              { isEdit && (
                                      <Box>
                                        <FormLabel component="label" className="formRadioLabel">
                                          {configJSON.haveMedicalConditionQuestion}
                                        </FormLabel>
                                        <RadioGroup
                                          className="radioGroup"
                                          data-test-id="haveMedicalCondition"
                                          aria-label="have_medical_condition"
                                          name="have_medical_condition"
                                          value={values.medical_information.have_medical_condition}
                                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                            setFieldValue("medical_information.have_medical_condition", e.target.value);
                                          }
                                          }
                                        >
                                          <FormControlLabel
                                            className="radioLabel"
                                            value="1"
                                            control={<StyledRadio />}
                                            label="Yes"
                                          />
                                          <FormControlLabel
                                            className="radioLabel"
                                            value="0"
                                            control={<StyledRadio />}
                                            label="No"
                                          />
                                        </RadioGroup>
                                      </Box>
                              )}
                              { !isEdit && values.medical_information.have_medical_condition === "0" && (
                                <Box>
                                  <Typography variant="h2">
                                    {configJSON.medicalConditionFormTitle}
                                  </Typography>
                                  <Typography variant="subtitle1" className="dataNotAvailable">
                                    {configJSON.dataNotAvailable}
                                  </Typography>
                                </Box>
                              )}
                              </Grid>
                              {values.medical_information.have_medical_condition === "1" && (
                                this.renderMedicalConditionData(values, setFieldValue)
                              )}
                              <Grid item xs={12}>
                                    {isEdit && (
                                      <Box>
                                        <FormLabel component="label" className="formRadioLabel">
                                          {configJSON.haveRegisteredOrganDonationQuestion}
                                        </FormLabel>
                                        <RadioGroup
                                          className="radioGroup"
                                          data-test-id="haveRegisteredOrganDonation"
                                          aria-label="have_registered_organ_donation"
                                          name="have_registered_organ_donation"
                                          value={values.medical_information.have_registered_organ_donation}
                                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                            setFieldValue("medical_information.have_registered_organ_donation", e.target.value);
                                          }
                                          }
                                        >
                                          <FormControlLabel
                                            className="radioLabel"
                                            value="0"
                                            control={<StyledRadio />}
                                            label="Yes"
                                          />
                                          <FormControlLabel
                                            className="radioLabel"
                                            value="1"
                                            control={<StyledRadio />}
                                            label="No"
                                          />
                                        </RadioGroup>
                                      </Box>
                                    )}
                                    {!isEdit && values.medical_information.have_registered_organ_donation === "0" && (
                                      <Box>
                                        <Typography variant="h2">
                                          {configJSON.organDonationDetailsFormTitle}
                                        </Typography>
                                        <Typography variant="subtitle1" className="dataNotAvailable">
                                          {configJSON.dataNotAvailable}
                                        </Typography>
                                      </Box>
                                    )}
                              </Grid>
                              {values.medical_information.have_registered_organ_donation === "1" && (
                                this.renderOrganDonationData(values, errors, touched, setFieldValue)
                              )}
                              <Grid item xs={12}>
                                    {isEdit && (
                                      <Box>
                                        <FormLabel component="label" className="formRadioLabel">
                                          {configJSON.haveYouGiveBloodQuestion}
                                        </FormLabel>
                                        <RadioGroup
                                          className="radioGroup"
                                          data-test-id="haveYouGiveBlood"
                                          aria-label="have_you_give_blood"
                                          name="have_you_give_blood"
                                          value={values.medical_information.have_you_give_blood}
                                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                            setFieldValue("medical_information.have_you_give_blood", e.target.value);
                                          }
                                          }
                                        >
                                          <FormControlLabel
                                            className="radioLabel"
                                            value="1"
                                            control={<StyledRadio />}
                                            label="Yes"
                                          />
                                          <FormControlLabel
                                            className="radioLabel"
                                            value="0"
                                            control={<StyledRadio />}
                                            label="No"
                                          />
                                          <FormControlLabel
                                            className="radioLabel"
                                            value="2"
                                            control={<StyledRadio />}
                                            label="Sometimes"
                                          />
                                        </RadioGroup>
                                      </Box>
                                    )}
                                    { !isEdit && (
                                            <Box>
                                                <Typography variant="h2">
                                                  {configJSON.haveYouGiveBlood}
                                                </Typography>
                                                <Typography variant="subtitle1" className="dataNotAvailable">
                                                  { this.getOrganDonationStatus(values.medical_information.have_registered_organ_donation)}
                                                </Typography>
                                            </Box>
                                    )}
                              </Grid>
                              <Grid item xs={12}>
                                    {isEdit ? (
                                      <Box>
                                        <FormLabel component="label" className="formRadioLabel">
                                          {configJSON.haveYouKnowBloodTypeQuestion}
                                        </FormLabel>
                                        <RadioGroup
                                          className="radioGroup"
                                          data-test-id="haveYouKnowBloodType"
                                          aria-label="have_you_know_blood_type"
                                          name="customized-radios"
                                          value={values.medical_information.have_you_know_blood_type}
                                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                            setFieldValue("medical_information.have_you_know_blood_type", e.target.value);
                                          }
                                          }
                                        >
                                          <FormControlLabel
                                            className="radioLabel"
                                            value="1"
                                            control={<StyledRadio />}
                                            label="Yes"
                                          />
                                          <FormControlLabel
                                            className="radioLabel"
                                            value="0"
                                            control={<StyledRadio />}
                                            label="No"
                                          />
                                        </RadioGroup>
                                      </Box>
                                    ): (
                                    <Box mb={2}>
                                      <Typography variant="h2">
                                        {configJSON.bloodType}
                                      </Typography>
                                    </Box>
                                    )}
                                    { (!isEdit) && values.medical_information.have_you_know_blood_type === "0" && (
                                      <Box mb={6}>
                                        <Typography variant="subtitle1" className="dataNotAvailable">
                                          {configJSON.dataNotAvailable}
                                        </Typography>
                                      </Box>
                                    )}
                                    {values.medical_information.have_you_know_blood_type == "1" && (
                                      this.renderBloodGroupData(values, errors, touched, setFieldValue)
                                    )}
                              </Grid>
                            </Grid>
                          </Box>
                          </Box>
                          <Box className="btnWrapper">
                            <Button
                              className="btnSize"
                              data-test-id="backBtn"
                              variant="outlined"
                              onClick={() => this.handleNavigation("HomePage")}
                            >
                              {configJSON.backBtnText}
                            </Button>
                            { isEdit ? (
                                <Button
                                  type="submit"
                                  data-test-id="saveChangesBtn"
                                  className="btnSize"
                                  variant="contained"
                                >
                                  {configJSON.saveChangesBtnText}
                                </Button>
                            ): (
                                  <Button
                                    className="btnSize"
                                    data-test-id="editDetailsBtn"
                                    variant="contained"
                                    type="button"
                                    onClick={this.handleEditBtnClick}
                                  >
                                    {configJSON.editDetailsBtnText}
                                  </Button>    
                            )}
                          </Box>
                        </Box>
                      </Form>
                    )}
                  </Formik>
                </Box>
              </Box>
                </Container>
            </Box>
            <Box mt={2}>
                <WebFooter handleNavigation={this.handleNavigation} />
            </Box>
          </Wrapper>
        </ThemeProvider>
        <GenericModal open={openDialog} data-test-id="data-edited" onClose={this.handleCloseDialog}>
          <ThemeProvider theme={theme}>
            <DialogWrapper>
              <Box className="dialogContentWrapper">
                <Box className="dialogDescriptionWrapper">
                  <img alt="success" 
                  src={rightTickImg} 
                  style={{ 
                    width: "56px", 
                    height: "56px", 
                    margin: "0px auto" 
                  }} />
                  <Typography variant="body2" align="center">
                    {configJSON.dialogMessageText}
                  </Typography>
                </Box>
              </Box>
            </DialogWrapper>
          </ThemeProvider>
        </GenericModal>
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
// Customizable Area End
