import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { getStorageData, setStorageData } from "../../../../packages/framework/src/Utilities";
import storage from '../../../framework/src/StorageProvider';

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  email: string;
  password: string;
  emailError: string;
  passwordError: string;
  open: boolean;
  linkSent:boolean;
  isVerified: boolean;

  // Customizable Area Start
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class SignuploginController extends BlockComponent<
  Props,
  S,
  SS
> {
  apiEmailLoginCallId: string | null = null;
  sendVerifyEmailCallId:string = "";
  deleteAccountCallId:string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      email: "",
      password: "",
      emailError: "",
      passwordError: "",
      // Customizable Area Start
      open:false,
      linkSent:false,
      isVerified: false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }
  
  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Received", message);
  
    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));
  
      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );
  
      this.setState({ txtSavedValue: value });
    }
  
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const response = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      runEngine.debugLog("API Response Received", response);
  
      if (response.meta && response.meta.token) {
        setStorageData("token", response.meta.token);
        const userData = response.data;
        setStorageData("subscriptionHave", userData.attributes["have_subscription?"]);
        const activated = userData.attributes.activated;
        if (activated) {
          this.handleRedirections(response);
        }
        else {
           this.handleOpen();
        }
      } else if (response.errors && response.errors.length > 0) {
        const error = response.errors[0];
        if (error.email) {
          this.setState({
            emailError: error.email,
            passwordError: ""
          });
        } else if (error.password) {
          this.setState({
            emailError: "",
            passwordError: error.password

          });
        } 
      } 
    }
    // Customizable Area Start
    // Customizable Area End
  }
  
  handleRedirections = (response: any) => {
    const userData = response.data;
    if(!userData.attributes["have_created_profile?"]) {
      this.handleNavigation("CustomisableUserProfiles");
    }
    else if(!userData.attributes["have_delegate_members?"]){
      this.handleNavigation("DelegateMembers");
    }
    else if(!userData.attributes["have_subscription?"]){
      this.handleNavigation("Customisableusersubscriptions");
    }
    else if(userData.attributes["have_subscription?"]){
      this.handleNavigation("CustomForm");
    }
    else{
      this.handleNavigation("HomePage");
    }
  };
  

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };
 
  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };
  handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ email: event.target.value });
  };
  
  handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ password: event.target.value });
  };
  // Customizable Area Start

  async componentDidMount(){
    super.componentDidMount()
    this.getHeaderURl()
  }

  getHeaderURl=()=>{
      let urlParams = new URLSearchParams(window.location.search);
      let isActivated = urlParams.get("activated");
      let token = urlParams.get("token")
      if(isActivated && isActivated === "true"){
        setStorageData("token", token);
        this.setState({isVerified: true, open: true})
      }
  }

  doEmailLogIn(): boolean {
    runEngine.debugLog("Attempting to log in with email: ", this.state.email);

    const header = {
      "Content-Type": configJSON.loginApiContentType,
    };

    if(this.state.email === "" && this.state.password === ""){
      this.setState({ 
        emailError: configJSON.emailErrorMsg,
        passwordError: configJSON.passErrorMsg,
      });
      return false;
    }
    else if(this.state.email === ""){
      this.setState({ emailError: configJSON.emailErrorMsg });
      return false;
    }
    else if(this.state.password === ""){
      this.setState({ passwordError: configJSON.passwordErrorMsg });
      return false;
    }

    const attrs = {
      email: this.state.email,
      password: this.state.password,
    };

    const data = {
      type: "email_account",
      attributes: attrs,
    };

    const httpBody = {
      data: data,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiEmailLoginCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.loginAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.loginAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }
  handleClose = () => {
    this.setState({ open: false, isVerified: false });
    if(this.state.linkSent){
      this.setState({email:'',password:''})
    }
  };
  
  
  navigateToForgotPass=()=>{
    const navigateRolesAndPermission2 = new Message(getName(MessageEnum.NavigationMessage));
    navigateRolesAndPermission2.addData(getName(MessageEnum.NavigationTargetMessage), "ForgotPassword");
    navigateRolesAndPermission2.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(navigateRolesAndPermission2);
  }

  sendVerifyEmail1 = async () => {
    const header = {
      "Content-Type": configJSON.signUpApiContentType,
    };

    const setIntroMsgg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const token = await storage.get("token");
    this.sendVerifyEmailCallId = setIntroMsgg.messageId; 
    setIntroMsgg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_signuplogin/signup/send_mail?token=${token}`
    );

    setIntroMsgg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    setIntroMsgg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(setIntroMsgg.id, setIntroMsgg);
  };
  handleSendLink = () => {
    this.sendVerifyEmail1();
    this.setState({ linkSent: true });

  }
  handleOpen = () => {
    this.setState({ open: true });

  };
  navigateToSignUp=()=>{
    const navigateRolesAndPermission2 = new Message(getName(MessageEnum.NavigationMessage));
    navigateRolesAndPermission2.addData(getName(MessageEnum.NavigationTargetMessage), "Signup");
    navigateRolesAndPermission2.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(navigateRolesAndPermission2);
  }

  apiCall = async (data: any) => {
    const token = await getStorageData("token");
    const { contentType, method, endPoint, body, type } = data;
    const header = { "Content-Type": contentType, token };
    const request = new Message(getName(MessageEnum.RestAPIRequestMessage));
    request.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    request.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    request.addData(getName(MessageEnum.RestAPIRequestMethodMessage), method);
    body && type != "formData"
      ? request.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),
          JSON.stringify(body)
        )
      : request.addData(getName(MessageEnum.RestAPIRequestBodyMessage), body);
    runEngine.sendMessage(request.id, request);
    return request.messageId;
  };

  clearFields = async () => {
    this.deleteAccountCallId= await this.apiCall({
      method: "DELETE",
      endPoint: "account_block/accounts/remove_account",
      contentType: "application/json",
    });
    this.handleClose();
     this.navigateToSignUp();
  }

  handleNavigation = (route: string) => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), route);
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  };
  // Customizable Area End
}

