import React from "react";
// Customizable Area Start
import AboutCVController, {
    configJSON,
    Props
} from "./AboutCVController.web";
import {
    Box,
    Grid,
    Button,
    FormLabel,
    RadioGroup,
    FormControlLabel
} from "@material-ui/core";
import { Field, Form, Formik } from "formik";
import { StyledRadio } from "./LegalInformation.web";
import CustomInput from "../../../components/src/CustomInput";
import ErrorMessage from "../../../components/src/ErrorMessage";
import MediaUpload from "../../../components/src/MediaUpload.web";

// Customizable Area End

export default class AboutCV extends AboutCVController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        // Customizable Area Start
        const {
            aboutCV,
        } = this.state;
        return (
            <Box className="formWrapper">
                <Formik
                    initialValues={aboutCV}
                    validationSchema={this.validationSchema}
                    onSubmit={(values) => {
                        this.handleAboutCVInformationsFormSubmit(values)
                    }}
                    enableReinitialize={true}
                    data-test-id="AboutCVForm"
                >
                    {({ values, setFieldValue }) => (
                        <Form className="form">
                            <Box className="formSectionBackground">
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <FormLabel component="label" className="formRadioLabel">
                                            {configJSON.uploadCV}
                                        </FormLabel>
                                        <RadioGroup
                                            data-test-id="haveCV"
                                            aria-label="have_legal_representative"
                                            name={configJSON.name}
                                            value={values.have_cv}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                setFieldValue("have_cv", e.target.value);
                                            }
                                            }
                                            className="radioGroup"
                                        >
                                            <FormControlLabel
                                                value="1"
                                                control={<StyledRadio />}
                                                label="Yes"
                                                className="radioLabel"
                                            />
                                            <FormControlLabel
                                                value="0"
                                                control={<StyledRadio />}
                                                label="No"
                                                className="radioLabel"
                                            />
                                        </RadioGroup>
                                    </Grid>

                                    {
                                        values.have_cv === "1" &&
                                        <Grid container spacing={3}>
                                            <Grid item xs={12}>
                                                <MediaUpload
                                                    data-test-id="mediaUploadForCV"
                                                    files={values.files}
                                                    onUpload={(event: React.ChangeEvent<HTMLInputElement>) => this.handleCVFile(event, setFieldValue, values.files)}
                                                />
                                            </Grid>
                                        </Grid>
                                    }
                                    <Grid item xs={12}>
                                        <FormLabel component="label" className="formRadioLabel">
                                            {configJSON.addFavouritePosition}
                                        </FormLabel>
                                        <RadioGroup
                                            data-test-id="haveJob"
                                            aria-label="have_legal_representative"
                                            name={configJSON.name}
                                            value={values.have_job}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                setFieldValue("have_job", e.target.value);
                                            }
                                            }
                                            className="radioGroup"
                                        >
                                            <FormControlLabel
                                                value="1"
                                                control={<StyledRadio />}
                                                label="Yes"
                                                className="radioLabel"
                                            />
                                            <FormControlLabel
                                                value="0"
                                                control={<StyledRadio />}
                                                label="No"
                                                className="radioLabel"
                                            />
                                        </RadioGroup>
                                    </Grid>
                                    {
                                        values.have_job === "1" &&
                                        <Grid container spacing={3}>
                                            <Grid item lg={4} xs={12} sm={6}>
                                                <FormLabel component="label">
                                                    {configJSON.favouritePositionJob}<span style={{ color: "red" }}>*</span>
                                                </FormLabel>
                                                <Grid item xs={12} >
                                                    <Field
                                                        data-test-id="favouritePositionJob"
                                                        className="inputField"
                                                        name={configJSON.favouritePositionJob}
                                                        as={CustomInput}
                                                        size="small"
                                                        placeholder={configJSON.favouritePositionJobPlaceholder}
                                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                            setFieldValue(`job_title`, e.target.value)
                                                        }}
                                                        value={values.job_title}
                                                    />
                                                    <ErrorMessage name={`job_title`} />
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <FormLabel component="label">
                                                    {configJSON.favouritePositionDescription}
                                                </FormLabel>
                                                <Grid item xs={12} >
                                                    <Field
                                                        data-test-id="favouritePositionDescription"
                                                        className="multilineInput"
                                                        name={configJSON.favouritePositionDescription}
                                                        as={CustomInput}
                                                        size="small"
                                                        multiline
                                                        placeholder={configJSON.favouritePositionDescriptionPlaceholder}
                                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                            setFieldValue(`job_description`, e.target.value)
                                                        }}
                                                        value={values.job_description}
                                                    />
                                                    <ErrorMessage name={`job_description`} />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    }
                                </Grid>
                            </Box>
                            <Box className="btnWrapper">
                                <Button
                                    variant="outlined"
                                    className="btnSize"
                                    onClick={() => this.props.handleNext("CustomForm")}
                                    data-test-id="skipForNow"
                                >
                                    {configJSON.skipForNowBtnText}
                                </Button>
                                <Button
                                    variant="contained"
                                    data-test-id="saveAndNextBtn"
                                    type="submit"
                                    className="btnSize"
                                >
                                    {configJSON.saveAndNext}
                                </Button>
                            </Box>
                        </Form>
                    )}
                </Formik>
            </Box>
        );
        // Customizable Area End
    }
}


// Customizable Area Start
// Customizable Area End