import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { sendAPIRequest } from "../../../components/src/Utils";
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";
import { format } from 'date-fns';
import { toast } from "react-toastify";

export interface IOrder {
  id: string;
  type: "order";
  attributes: IOrderAttributes;
}

export interface IOrderAttributes {
  id: number;
  order_number: string;
  order_date: string;
  schedule_time: string;
  quantity: number;
  amount: number;
  sub_total: string;
  delivery_charges: string;
  total: string;
  status: string;
  invoive_pdf: string;
  created_at: string;
  product: IProductData;
  delivery_address: IAddressData;
}

export interface IProductData {
  data: {
    id: string;
    type: "products";
    attributes: IProductAttributes;
  };
}

export interface IProductAttributes {
  id: number;
  product_name: string;
  description: string;
  detail: string;
  additional_information: string;
  price: number;
  delivery_charge: number;
  category_id: number;
  category_name: string;
  is_popular: boolean;
  is_available: boolean;
  unit: number;
  main_image: string;
  other_images: Image[];
  created_at: string;
}

interface Image {
  url: string;
}

export interface IAddressData {
  data: {
    id: string;
    type: "addresses";
    attributes: IAddressAttributes;
  };
}

export interface IAddressAttributes {
  id: number;
  name: string;
  country_code: string;
  phone_number: string;
  address_type: string;
  address_for: string;
  address: string;
  address_line_2: string;
  landmark: string;
  city: string;
  country: string;
  zip_code: string;
  is_default: boolean;
  created_at: string;
  updated_at: string;
  account_id: number;
}

export interface IOrderDetails {
  id: number;
  order_number: string;
  order_date: string;
  schedule_time: string;
  quantity: number;
  amount: number;
  sub_total: string;
  delivery_charges: string;
  total: string;
  status: string;
  delivery_address: string;
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  ordersData: IOrder[];
  selectedTab: "on_going" | "delivered" | "cancelled";
  activeTab: "Ongoing" | "Completed" | "Cancelled";
  openCancelDialog: boolean;
  openDetailsDialog: boolean;
  openInvoiceDialog: boolean;
  cancelOrderId: number;
  currentItemIndex: number;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class OrderHistoryController extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      ordersData: [],
      selectedTab: "on_going",
      activeTab: "Ongoing",
      openCancelDialog: false,
      openDetailsDialog: false,
      openInvoiceDialog: false,
      cancelOrderId: 0,
      currentItemIndex: 0,
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJSON = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    this.apiSuccessCallBackController(apiRequestCallId, responseJSON);
    // Customizable Area End
  }

  // Customizable Area Start
  getOrdersListApiId: string = "";
  cancelOrderApiId: string = "";

  async componentDidMount() {
    super.componentDidMount();
    this.getOrdersDataList();
  }

  apiSuccessCallBackController = (
    apiRequestCallId: string,
    responseJSON: Record<string, unknown>
  ) => {
    const successCallbackMap = {
      [this.getOrdersListApiId]: this.handleGetOrdersListApiResponse,
      [this.cancelOrderApiId]: this.handleCancelOrderApiResponse,
    };

    if (apiRequestCallId) {
      const successCallback: (responseJSON: Record<string, unknown>) => void =
        successCallbackMap[apiRequestCallId];
      !!successCallback && successCallback(responseJSON);
    }
  };

  getOrdersDataList = async () => {
    const token = await getStorageData("token");

    this.getOrdersListApiId = sendAPIRequest(
      `bx_block_order_management/orders?status=${this.state.selectedTab}`,
      {
        method: "GET",
        headers: {
          token,
        },
      }
    );
  };

  handleGetOrdersListApiResponse = (
    responseJSON: Record<string, unknown>
  ) => {
    if(this.handleErrorResponse(responseJSON)){
      return;
    }
    const response = responseJSON as {
      data?: IOrder[];
    };

    if (response.data && response.data.length > 0) {
      let ordersData: IOrder[] = [];

      response.data.forEach((order) => {
        return ordersData.push(order);
      });

      this.setState({ ordersData });
    }
  };

  cancelOrder = (orderId: number) => {
    this.setState({
      openCancelDialog: true,
      cancelOrderId: orderId,
    });
  };

  handleCancelBtnClick = async () => {
    this.setState({ openCancelDialog: false });
    const token = await getStorageData("token");

    this.cancelOrderApiId = sendAPIRequest(
      `bx_block_order_management/orders/${this.state.cancelOrderId}/cancel_order`,
      {
        method: "PUT",
        headers: {
          token,
        },
      }
    );
  };

  handleCancelOrderApiResponse = (
    responseJSON: Record<string, unknown>
  ) => {
    if(this.handleErrorResponse(responseJSON)){
      return;
    }
    const response = responseJSON as {
      data?: IOrder;
      meta?: { message: string; };
    };

    if (response.data) {
      toast.success("order cancelled successfully");
      let ordersData = this.state.ordersData.filter((order) => order.attributes.id !== this.state.cancelOrderId);
      this.setState({ ordersData, cancelOrderId: 0 });
    }
  };

  handleErrorResponse = (responseJSON: Record<string, any>) => {
    const { errors: possibleErrors } = responseJSON;
    if (possibleErrors) {
      toast.error(possibleErrors[0].message);
      return true; // Indicates that there was an error
    }
    return false; // Indicates that there was no error
  }

  handleTabClick = (activeTab: string) => {
    switch (activeTab) {
      case "Ongoing":
        this.setState({
          selectedTab: "on_going",
          activeTab: "Ongoing",
          ordersData: [],
        });
        this.getOrdersDataList();
        return;
      case "Completed":
        this.setState({
          selectedTab: "delivered",
          activeTab: "Completed",
          ordersData: [],
        });
        this.getOrdersDataList();
        return;
      case "Cancelled":
        this.setState({
          selectedTab: "cancelled",
          activeTab: "Cancelled",
          ordersData: [],
        });
        this.getOrdersDataList();
        return;
    }
  };

  showOrderDetails = (index: number) => {
    this.setState({ currentItemIndex: index, openDetailsDialog: true });
  };

  getOrderDate = (orderDate: string) => {
    return format(new Date(orderDate), "d MMMM yyyy");
  };

  getOrderAddress = (address: IAddressData) => {
    const addressAttr = address.data.attributes;
    return addressAttr.address + ", " + addressAttr.address_line_2 + ", " + addressAttr.landmark + ", " + addressAttr.city + ", " + addressAttr.country + " - " + addressAttr.zip_code;
  };

  downloadFile =  async(fileUrl: string) => {
    this.setState({ openInvoiceDialog: false });

     // Create a temporary anchor element
     const link = document.createElement('a');
     link.href = fileUrl; // Set the Blob URL
     link.download = fileUrl.split("/").pop() || "invoice.pdf"; // Specify the file name for the download
     document.body.appendChild(link); 
     link.click();
     document.body.removeChild(link);
  };

  handleInvoiceClick = (index: number) => {
    this.setState({ openInvoiceDialog: true, currentItemIndex: index });
  };

  handleClose = () => {
    this.setState({ 
      openCancelDialog: false, 
      openDetailsDialog: false,
      openInvoiceDialog: false, 
    });
  };

  handleProductNavigationcheckout = async (productId: number, orderDetails: IOrderAttributes) => {
    await setStorageData("Quantity", orderDetails.quantity);
    await setStorageData("OrderDetails", JSON.stringify(orderDetails));
    this.handleProductNavigation(productId, "OrderSummary");
  };

  handleNavigation = (route: string) => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), route);
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  };

  handleProductNavigation = (productId: number, path: string) => {
    const message: Message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), path);
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    message.addData(getName(MessageEnum.NavigationScreenNameMessage), productId);
    this.send(message);
  }
  // Customizable Area End
}
