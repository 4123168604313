import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { IStep } from "../../multipageforms/src/LegalDataEditController.web";
import { getStorageData,setStorageData } from "../../../framework/src/Utilities";
import { sendAPIRequest } from "../../../components/src/Utils";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  errorMessage: string;
  isError: boolean;
  openDialog: boolean;
  stepsDelegate: IStep[];
  ShowsDeleagteApiData:any;
  ShowsDeleagteApiData1:any;
  showUpdateedDelegateID:any;
  showsDeleagteApi:any,
  showsDeleagteApi1:any,
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class DelegateMembersEditController extends BlockComponent<
  Props,
  S,
  SS
> {

 callShowsDelegateMemberApiId:string ="";
 callListDelegateMemberApi: string ="";
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
        errorMessage: "",
        isError: false,
        stepsDelegate: [
            { label: 'Home', path: 'HomePage' },
            { label: 'Account', path: 'HomePage' },
            { label: 'Delegates', path: 'DelegateMembersEdit' },
          ],
        openDialog: false,
        ShowsDeleagteApiData:[],
        ShowsDeleagteApiData1:[],
        showsDeleagteApi:[],
        showsDeleagteApi1:[],
        showUpdateedDelegateID:""
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  // Customizable Area Start

  async componentDidMount() {
    super.componentDidMount();
    this.getShowsDelegateMemberApi()
    this.getListDelegateMemberApi()
    let showUpdateedDeletedIDs = await getStorageData("showUpdateedDeletedID");
   
    this.setState({
        showUpdateedDelegateID:showUpdateedDeletedIDs
    })
  }
  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJSON = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (apiRequestCallId === this.callShowsDelegateMemberApiId) {
       this.setState({
        // ShowsDeleagteApiData:responseJSON.data[0].attributes,
        // ShowsDeleagteApiData1:responseJSON.data[1].attributes
       })
      }
      if (apiRequestCallId === this.callListDelegateMemberApi) {
       
        const delegateData = responseJSON.data[0].attributes; 
        const delegateData1 = responseJSON.data.length > 1 ? responseJSON.data[1].attributes : null; 
        this.setState({
            ShowsDeleagteApiData: delegateData,
            ShowsDeleagteApiData1: delegateData1,
        });
    }
    
    // Customizable Area End
  }



  handleCloseSnackbar = ()=>{
    this.setState({
      errorMessage: "", 
      isError: false
    });
  };

  handleCloseDialog = () => {
    this.setState({ openDialog: false });
  };

  handleNavigation = (route: string) => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), route);
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  };
  handleDelegateOneBtnClick =  async()=>{
    await setStorageData('showParticularData',this.state.ShowsDeleagteApiData.id); 
    const navigationMessage: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    navigationMessage.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "DelegateEditMember"
    );
    navigationMessage.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    this.send(navigationMessage);
  }
  handleDelegateTwoBtnClick = async()=>{
    await setStorageData('showParticularData',this.state.ShowsDeleagteApiData1.id); 
    const navigationMessage: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    navigationMessage.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "DelegateEditMember"
    );
    navigationMessage.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    this.send(navigationMessage);
  }
  getShowsDelegateMemberApi =async()=>{
    
    const token = await getStorageData("token");
    this.callShowsDelegateMemberApiId = sendAPIRequest(
      
    `/bx_block_customisableuserprofiles/delegate_members/${this.state.showUpdateedDelegateID}`,
      {
        method: configJSON.httpGetMethod,
        headers: {
          token,
        },
      }
    );
  }

  getListDelegateMemberApi =async()=>{
    
    const token = await getStorageData("token");
    this.callListDelegateMemberApi = sendAPIRequest(
      
      configJSON.getDelegateMembersApiEndPoint,
      {
        method: configJSON.httpGetMethod,
        headers: {
          token,
        },
      }
    );
  }
  // Customizable Area End
}