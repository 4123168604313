Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "MultipageForms";
exports.labelBodyText = "MultipageForms Body";

exports.btnExampleTitle = "CLICK ME";

exports.successMessage = "UserDetails inserted successfully";
exports.errorsDataMsg  = "Internal Server error";
exports.multiPageFormSubmitEndPoint = "/bx_block_multipageforms/user_profiles";

exports.getApiRequest = "GET";
exports.postApiRequest = "POST";
exports.getDelegateMembersApiEndPoint = "bx_block_customisableuserprofiles/delegate_members";
exports.getLegalDataApiEndPoint = "bx_block_custom_form/legal_informations";
exports.getFriendsDetailsApiEndPoint = "/bx_block_custom_form/friend_details";
exports.getMedicalDataApiEndPoint = "bx_block_custom_form/medical_informations";
exports.addPetsInfoApiEndPoint = "/bx_block_custom_form/pet_informations";
exports.getPetPhotoTemplatesApiEndPoint = "bx_block_upload_media/pet_templates";
exports.formAPiMethod = "POST";
exports.getFuneralBasicsDataApiEndPoint = "bx_block_custom_form/funeral_infos";
exports.getFuneralAttireDataApiEndPoint = "bx_block_custom_form/attires";
exports.getHairBeautyDataApiEndPoint = "bx_block_custom_form/hair_and_beauty_infos";
exports.getFuneralFurtherItemsDataApiEndPoint = "bx_block_custom_form/further_items";
exports.getDelegateMembersApiEndPoint = "bx_block_customisableuserprofiles/delegate_members";
exports.addAfterWishesInfoApiEndPoint = "bx_block_custom_form/after_wishes";
exports.getAboutMePhysicalDataApiEndPoint = "bx_block_custom_form/physcial_informations";
exports.getAboutMeFavouriteDataApiEndPoint = "bx_block_custom_form/favourite_informations";
exports.getAboutMeHobbiesDataApiEndPoint = "bx_block_custom_form/hobbies_informations";
exports.getAboutMeDreamsDataApiEndPoint = "bx_block_custom_form/dream_informations";
exports.getAboutMeMomentDataApiEndPoint = "bx_block_custom_form/proud_moments";
exports.getAboutMeAchievementsDataApiEndPoint = "bx_block_custom_form/achievement_informations";
exports.getAboutMeCVDataApiEndPoint = "bx_block_custom_form/cv_informations";

exports.myDataTitle = "My Data";
exports.mapTitle = "Map";
exports.photoTitle = "Photos";
exports.viewAllLink = "View all";
exports.videoTitle = "Videos";
exports.maxFileError = "As per your plan max ${numberOfFile} will allowed."
exports.friendsDetailsTitle = "Friends Details";
exports.messageText = "This info will be added to your final booklet";
exports.friendsDetailsEditQuestion1 = "1. Name of your best friend";
exports.friendsDetailsEditQuestion2 = "2. How you met your best friend?";
exports.friendsDetailsEditQuestion3 = "3. Please describe your best friend in three or more words";
exports.friendsDetailsEditQuestion4 = "4. Add your favourite memory of you and your best friend";
exports.friendsDetailsEditQuestion5 = "5. Add more of your closest friends?";
exports.friendsDetailsEditQuestion6 = "6. Add a group memory?";
exports.locationFieldLabelFriends = "Location where you met";
exports.friendMediaAttributeLocationLabelFriends = "Location of Image";
exports.friendMediaAttributeLocationLabel = "Location of Image";
exports.friendMediaAttributeDateLabel = "Date of Image";
exports.friendMediaAttributeDateLabelFriends = "Date of Image";
exports.friendsMemoriesFormTitle = "Friends Memories";
exports.friendsClosestFriendsForm = "Closest Friends"
exports.friendsGroupMemoriesForm = "Group Memories"
exports.favouriteMemoryFormTitleFriends = "Memories";
exports.memoriesSubtitleFriends = "You can add max";
exports.memoriesTextFriends = "memories";
exports.addAnotherBtnTextFriends = "Add another";
exports.addMoreBtnTextFriends = "Add more";
exports.removeBtnTextFriends = "Remove";
exports.memoryTitleFieldLabelFriends = "Memory Title";
exports.friendMemoryLocationFieldLabelFriends = "Location";
exports.friendMemoryTimeStampFieldLabelFriends = "Time Stamp";
exports.fetchFormDataMethod="GET";
// closest friends form
exports.closestFriendsFormTitle = "Close Friend";
exports.closestFriendsSubtitle = "You can add max";
exports.closeFriendsText = "close friends";

exports.closeFriendFullNameFieldLabel = "Full name";
exports.closeFriendLocationFieldLabel = "Location where you met";
exports.aboutCloseFriendFieldLabel = "Write about first meeting with your friend";
exports.closeFriendDescriptionFieldLabel = 
"Please describe your close friend in three or more words";
exports.closeFriendMemoriesSwitchLabel = "Add memory with close friend?";

// group memories form //
exports.groupMemoriesFormTitleFriends = "Group Memory";
exports.friendNameFieldLabelFriends  = "Friend Name";
exports.investmentsAndFundsEndPoint = "bx_block_custom_form/investment_informations"
exports.investmentsAndFundsPageTitle = "Investments & Funds";
exports.haveBankingProviderQuestion = "1. Do you have a banking provider?";
exports.haveStocksQuestion = "2. Do you have any Stocks/ Shares?";
exports.havePremimumBondsQuestion = "3. Do you have any premium bonds?";
exports.bankDetailsFormTitle = "Bank Details";
exports.providerNameFieldLabel = "Provider Name";
exports.stockDetailsFormTitle = "Stock Details";
exports.stockNameFieldLabel = "Stock Name";
exports.stockQuantity = "Stock Quantity";
exports.totalValue = "Total Value";
exports.bondDetailsFormTitle = "Bond Details";
exports.holderNameFieldLabel = "Holder’s Name";
exports.unitFieldLabel = "Unit(€)";
exports.noOfUnitFieldLabel = "Number of Units";
exports.bondValueFieldLabel = "Bond Value(€)";
exports.addMoreBank = "Add more bank";
exports.addMoreBond = "Add more bond";
exports.addMoreStock = "Add more stock";
exports.removeBank = "Remove bank";
exports.removeBond = "Remove bond";
exports.removeStock = "Remove stock";
// Legal Data //
exports.legalDataTitle = "Legal Data";
exports.delegateInChargeText = "Delegate In-charge";
exports.backBtnText = "Back";
exports.editDetailsBtnText = "Edit Details";
exports.saveChangesBtnText = "Save Changes";
exports.dialogMessageText = "Your changes have been saved successfully!";
exports.dataNotAvailable = "No";
exports.NoMediaFound = "No Media";


exports.haveLegalRepresentativeLabel = 
"1. Do you have a legal representative in place to help with the transition of assets and possessions to loved ones or charities?";
exports.haveWillLabel = "2. Do you have a will in place already?";
exports.haveLifeInsuranceLabel = "3. Do you have a Life Insurance?";

// LegalRepresentativeForm
exports.legalRepresentativeTitle = "Legal Representative";
exports.fullNameFieldLabel = "Full Name";
exports.emailIDFieldLabel = "Email ID";
exports.phoneNumberFieldLabel = "Phone Number";

exports.fullNamePlaceholder = "Enter representative's full name";
exports.emailIDPlaceholder = "example@gmail.com";
exports.phoneNumberPlaceholder = "0000000000";

// Kin Details Form
exports.kinDetailsTitle = "Kin Details";
exports.relationFieldLabel = "Relation to next to Kin";
exports.dateOfBirthLabel = "Date of Birth";
exports.addressLine1Label = "Address line 1";
exports.addressLine2Label = "Address line 2";
exports.landmarkLabel = "Landmark (if required)";
exports.townCityLabel = "Town/City";
exports.countryLabel = "Country";
exports.postCodeLabel = "Post Code";

exports.kinFullNamePlaceholder = "Enter Kin's full name";
exports.kinEmailIDPlaceholder = "example@gmail.com";
exports.kinPhoneNumberPlaceholder = "0000000000";
exports.kinRelationFieldPlaceholder = "Enter relationship";
exports.kinDateOfBirthPlaceholder= "DD/MM/YYYY";
exports.kinAddressLine1Placeholder = "House/Flat no.";
exports.kinAddressLine2Placeholder = "Street and Locality";
exports.kinLandmarkPlaceholder = "Landmark";
exports.kinTownCityPlaceholder = "City";
exports.kinCountryPlaceholder = "Country";
exports.kinPostCodePlaceholder = "Post Code";

// Location Of Will Form
exports.locationOfWillTitle = "Location of Will";
exports.nameOfPersonHoldingWillLabel = "Name of person holding will (if any)";
exports.additionalInformationLabel = "Additional Information (optional)";

exports.nameOfPersonHoldingWillPlaceholder = "Enter full name";
exports.additionalInformationPlaceholder = "Example - Will is stored in the family safe";
exports.willAddressLine1Placeholder = "House numberFlat no.";
exports.willAddressLine2Placeholder = "Street and Locality";
exports.willLandmarkPlaceholder = "Landmark";
exports.willTownCityPlaceholder = "City";
exports.willCountryPlaceholder = "Country";
exports.willPostCodePlaceholder = "Post Code";

// Policy Details Form
exports.policyDetailsFormTitle = "Policy Details";
exports.policyNumberLabel = "Policy Number";
exports.policyProviderLabel = "Policy Provider (Company)";
exports.agentNameLabel = "Agent Name (if any)";
exports.planNameLabel = "Plan Name";
exports.policyTermLabel = "Policy Term (Years)";
exports.paymentFrequencyLabel = "Payment Frequency";
exports.startDateLabel = "Start Date";
exports.maturityDateLabel = "Maturity Date";
exports.sumAssuredLabel = "Sum Assured";
exports.addMorePolicyBtnText = "Add more policies";
exports.removePolicyBtnText = "Remove Policy";

exports.policyNumberPlaceholder = "Enter policy number";
exports.policyProviderPlaceholder = "Enter full name of provider";
exports.agentNamePlaceholder = "Enter full name of agent";
exports.planNamePlaceholder = "Enter plan name";
exports.policyTermPlaceholder = "Enter policy term";
exports.paymentFrequencyPlaceholder = "Select payment frequency";
exports.startDatePlaceholder = "DD/MM/YYYY";
exports.maturityDatePlaceholder = "DD/MM/YYYY";
exports.sumAssuredPlaceholder = "00.00";


// validation messages
exports.delegateInchargeMsg = "Please select delegate member";
exports.fullNameMsg = "Please enter your full name";
exports.emailMsg = "Please enter email";
exports.validEmailMsg = "Please enter valid email";
exports.phoneNumberMsg = "Please enter phone number";

exports.addressline1Msg = "Please enter addressline1";
exports.addressline2Msg = "Please enter addressline2";
exports.cityMsg = "Please enter city";
exports.countryMsg = "Please select country";
exports.postcodeMsg = "Please enter a valid post code";

exports.kinFullNameMsg = "Please enter kin's full name";
exports.kinRelationMsg = "Please enter relation to next to kin";
exports.kinEmailMsg = "Please kin's enter email";
exports.kinValidEmailMsg = "Please enter kin's valid email";
exports.kinDOBMsg = "Please enter kin's date of birth";
exports.kinAddressline1Msg = "Please enter kin's addressline1";
exports.kinAddressline2Msg = "Please enter kin's addressline2";
exports.kinCityMsg = "Please enter kin's city";
exports.kinCountryMsg = "Please select kin's country";
exports.kinPostcodeMsg = "Please enter a valid post code";

exports.policyNumberMsg = "Please enter policy number";
exports.policyProviderMsg = "Please enter name of policy provider";
exports.planNameMsg = "Please enter plan name";
exports.policyTermMsg = "Please enter policy term in years";
exports.paymentFrequencyMsg = "Please select payment frequency";
exports.startDateOfPolicyMsg = "Please enter start date of policy";
exports.maturityDateOfPolicyMsg = "Please enter maturity date of policy";
exports.sumAssuredMsg = "Please enter sum assured ";

exports.familyDetailsTitle = "Family Details";
// Medical Information //
exports.medicalDataPageTitle = "Medical Information";
exports.pdfLabel = "Include this information in Final PDF";
exports.haveMedicalConditionQuestion = "1. Do you have any medical conditions?";
exports.haveRegisteredOrganDonationQuestion = 
"2. Have you registered to opt OUT of organ donation if you live in ENGLAND?";
exports.haveYouGiveBloodQuestion = "3. Do you give blood?";
exports.haveYouKnowBloodTypeQuestion = "4. Do you know blood type?";

exports.haveYouGiveBlood = "Do you give blood?";

// Medical Condition Form
exports.medicalConditionFormTitle = "Medical Condition";
exports.addMoreConditionsBtnText = "Add more conditions";
exports.removeConditionBtnText = "Remove condition";

exports.addMoreBtnText = "Add more";
exports.removeBtnText = "Remove";

exports.conditionTypeLabel = "Condition Type";
exports.conditionNameLabel = "Condition Name";
exports.levelOfConditionLabel = "Level of Condition";
exports.descriptionFieldLabel = "Description (if required)";

exports.organDonationDetailsFormTitle = "Organ donation Details";
exports.organDonationDetailsFormDescription = "This information can be found on your donation card/confirmation";

exports.idNumberFieldLabel = "ID Number";
exports.organsCheckBoxListLabel = "Organs to be donated:"; 
exports.organNameFieldLabel = "Organ Name";

exports.bloodGroupFieldLabel = "Blood Group";
exports.bloodType = "Blood Type Details";

exports.haveYouGiveBlood = "Do you give blood?";

// Medical Condition Form
exports.medicalConditionFormTitle = "Medical Condition";
exports.addMoreConditionsBtnText = "Add more conditions";
exports.removeConditionBtnText = "Remove condition";

exports.conditionTypeLabel = "Condition Type";
exports.conditionNameLabel = "Condition Name";
exports.levelOfConditionLabel = "Level of Condition";
exports.descriptionFieldLabel = "Description (if required)";

exports.organDonationDetailsFormTitle = "Organ donation Details";
exports.organDonationDetailsFormDescription = "This information can be found on your donation card/confirmation";

exports.idNumberFieldLabel = "ID Number";
exports.organsCheckBoxListLabel = "Organs to be donated:"; 
exports.organNameFieldLabel = "Organ Name";

exports.addMoreBtnText = "Add more";
exports.removeBtnText = "Remove";

exports.bloodType = "Blood Type Details";
exports.bloodGroupFieldLabel = "Blood Group";

exports.familyDetailsQuestion1 = "1. In your family, who are you closest to?";
exports.familyDetailsQuestion2 = "2. Why is this person special to you?";
exports.familyDetailsQuestion3 = "3. What do you love most about your family?";
exports.familyDetailsQuestion4 = "4. Add your favourite memory of you and your family?";
exports.familyDetailsQuestion5 = "5. Add a festive memory (eg, Christmas)";
exports.familyDetailsQuestion6 = "6. Please describe your family in three words";
exports.memoriesText = "memories";
exports.addAnotherBtnText = "Add another";
exports.remove= "Remove";
exports.favouriteMemoryFormTitle = "Memories";
exports.memoryTitleFieldText = "Memory Title";
exports.festiveMemoryFormTitle = "Festive Memory";
exports.celebrationTypeFieldText = "Celebration type";
exports.getFamilyDetailsEndPoint = "bx_block_custom_form/family_details";
exports.getSubscriptionPlanApiEndPoint = "bx_block_custom_user_subs/user_subscriptions/active_plan"; 

exports.getPersonalLetterEndPoint = "bx_block_custom_form/personal_letters";
exports.addLettersBtnText = "Add letters";
exports.personalLettersPageTitle = "Personal Letters";
exports.personalLettersPageSubtitle = "Add or upload small letters for loved ones to read once you are gone.";
exports.delegateFieldLabel = "Delegate";
exports.recipientFieldLabel = "Recipient of letter";
exports.letterFieldLabel = "Letter";
exports.haveSpecialDaySwitchLabel = "Do you wish the letter to be opened on a specific day?";
exports.specialDayFieldLabel = "Special Day/ Subject";
exports.personalLetterHeading ="Delegate In-charge of delivering letter"
// Pets And Animals //
exports.petsPageTitle = "Pets and Animals";
exports.petsTitle = "Pets";
exports.petsTitleArrangments = "Pets Arrangements";
exports.petsAndAnimalsQuestion1 = "1. Do you have Pets?";
exports.petsAndAnimalsQuestion2 = 
"2. Have you made arrangements for who will be responsible for your pets?";

// pet details section
exports.petDetailsFormTitle = "Pet Details";
exports.noOfPetsFieldLabel = "No. of Pets";
exports.petNameFieldLabel = "Pet Name";
exports.petBreedfieldLabel = "Pet Breed (Optional)";
exports.petsRequirementfieldLabel = "Pets requirements";
exports.petAddressTitle = "Pets living address";

exports.uploadButtonText = "Upload Photo";
exports.templateBtnText = "Template";
exports.templatesDialogTitle = "Templates";
// caretaker details section
exports.careTakerFormTitle = "Caretaker Details";
exports.caretakerFieldLabel = "Caretaker";
exports.caretakerNameFieldLabel = "Full Name";
exports.voiceNoteEndPoint = "bx_block_custom_form/voice_notes"
exports.voiceNotesPageTitle = "Voice Notes"
exports.invalidFileError = "Only audio files is allowed."
exports.maxFileSizeError= "Maximum files size allowed: 15MB"
exports.voiceNotesPageSubtitle = "Add or upload small voice notes for loved ones to listen to."
exports.haveSpecialVoiceDaySwitchLabel = "Do you wish the voice note to be opened on a specific day?"

exports.reportPhoneNumberLabel = "Phone Number";
exports.phoneNumberLabel = "Phone Number";
exports.reportPhoneNumberPlaceholder = "0000000000";
exports.personalLetterHeading ="Delegate In-charge of delivering letter";
exports.voiceNotes = "Voice Notes";
exports.getVoiceNotesEndPoint = "bx_block_custom_form/voice_notes";
exports.voiceErrorMessage = "As per you plan only 10 sec voice not is alowed.";
exports.microphoneError = "Microphone access denied.";
exports.microphoneError1 = "Error accessing microphone:";
exports.selectPromptLabel = "Select Prompts";
exports.uploadMedia = "Upload Media";
exports.dragAndDrop1 = "Only Audio with max size of 15MB are allowed";
exports.dragAndDrop2 = "Choose File";
exports.dragAndDrop3 = "Or drag & drop here";

// Funeral Edit //
exports.funeralPageTitle = "Your Funeral";
exports.funeralPageSubTitle = "Edit your funeral wishes here";

// Funeral Basics //
exports.funeralBasicsQuestion1 = "1. How would like your body to be handled after your passing?";
exports.funeralBasicsQuestion2 = "2. Funeral Plan already in place?";
exports.funeralBasicsQuestion3 = "3. Do you have a location in mind for your wake?";
exports.funeralBasicsQuestion4 = "4. Do you have a grave stone or memorial spot already purchased?";
exports.funeralBasicsQuestion5 = "5. Do you have songs in mind to be played on your funeral?";
exports.funeralBasicsQuestion6 = "6. Who would you like to carry you if possible?";
exports.funeralBasicsQuestion7 = "7. Would you like to add two close friends or family members to speak or deliver your eulogy apart from delegate users?";
exports.funeralBasicsQuestion8 = "8. Do you have any specific requests?";

exports.funeralBasicsTitle = "Delegate In-charge of funeral";
exports.decisionFieldLabel = " Why you have come to this decision?";

// Cremation Details
exports.cremationDetailsTitle = "Cremation Details";
exports.haveDiamonEctFieldLabel = "Would you like your ashes to be divided and perhaps turned into memorabilier such as diamons ect";
exports.cremationDecisionFieldLabel = "How would you like it to be turned";

// Funeral Place Details
exports.funeralDetailsTitle = "Funeral Details";

// Location for wake
exports.locationForWakeTitle = "Location for wake";
exports.nameOfPlaceFieldLabel = "Name of Place";

// songs fields
exports.songFieldLabel1 = "Song 1";
exports.songFieldLabel2 = "Song 2";
exports.songFieldLabel3 = "Song 3";

// List of people
exports.listOfPeopleTitle = "List of people";
exports.nameFieldLabel = "Name";

// Close Friends
exports.delegateUserFieldLabel1 = "Delegate User 1";
exports.delegateUserFieldLabel2 = "Delegate User 2";
exports.person1Title = "Person 1";
exports.person2Title = "Person 2";
exports.relationshipFieldLabel = "Relationship";

exports.specialRequestFieldLabel = "Special Request";

// Funeral Attire
exports.attireTitle = "Delegate In-charge";

exports.attireQuestion1 = "1. Please select a someone who you would like in-charge of your final attire";
exports.attireInCharge = "Attire In-charge";
exports.attireInChargeTitle = "In-Charge";

exports.attireQuestion2 = "2. Ideally what items of clothing would you like to part ways in?";
exports.upperClothFieldLabel = "Top/ Jumper/ Cardigan";
exports.lowerClothFieldLabel = "Trouser/ Bottom/ Skirt";
exports.shoesFieldLabel = "Shoes";
exports.socksFieldLabel = "Socks/ Pants/ Knickers/ Bra";
exports.otherFieldLabel = "Other";

// Funeral hair and beauty
exports.hairBeautyQuestion1 = "1. Please describe the type of hair you would like if this could be carried out";
exports.hairStyleTypeFieldLabel = "Hair Style Type";

exports.hairBeautyQuestion2 = "2. Would you require any makeup?";
exports.makeupDetailsTitle = "Makeup Details";
exports.makeupTypeFieldLabel = "Makeup Type";

exports.hairBeautyQuestion3 = "3. Would you require nails?";
exports.nailTypeFieldLabel = "Nail Type";

exports.hairBeautyQuestion4 = "4. Which perfume would you like to be applied";
exports.perfumeNameFieldLabel = "Perfume Name";

// Funeral further items
exports.futherItemsTitle = "The items will be removed during the burning process";
exports.addedYourCoffineQuestion = "Is there anything you would like to be added to your coffin such as photograph, bag, ring?";
exports.furtherItemsFieldLabel = "Further Items";


//About ME
exports.aboutMePageTitle = "About Me";
exports.aboutMePageSubtitle="Add a little more information about you for loved ones to read.";
exports.physicalHeading = "Physical Details";
exports.height = "Height";
exports.eyeColor = "Eye Colour";
exports.hairColor = "Hair Colour";
exports.visible = "Visible marks (if any)";
exports.tattoAllowed = "Do you have any tattoo on your body?";
exports.tattooDetails = "Tattoo Details";
exports.addMoreTattoo = "Add more tattoos";
exports.bodyPart = "Body Part";

// Favourite
exports.favouriteDetailsHeading = "Favourite Details";
exports.animal = "Favourite Animal";
exports.animalPlaceholder = "Enter your favourite animal";
exports.sport = "Favourite Sport";
exports.sportPlaceholder = "Enter your favourite sport";
exports.food = "Favourite Food";
exports.foodPlaceholder = "Enter your favourite food";
exports.drink = "Favourite Drink";
exports.drinkPlaceholder = "Enter your favourite drink";
exports.alcoholic_drink = "Favourite Alcoholic Drink";
exports.alcoholic_drinkPlaceholder = "Enter your favourite alcoholic drink";
exports.holiday_destination = "Favourite Holiday Destination";
exports.holiday_destinationPlaceholder = "Enter your favourite holiday destination";
exports.clothing_brand = "Favourite Clothing Brand";
exports.clothing_brandPlaceholder = "Enter your favourite clothing brand";
exports.singer = "Favourite Singer";
exports.singerPlaceholder = "Enter your favourite singer";
exports.actor = "Favourite Actor";
exports.actorPlaceholder = "Enter your favourite actor";
exports.movie = "Favourite Movie";
exports.moviePlaceholder = "Enter your favourite movie";
exports.clothing_item = "Favourite Clothing Item";
exports.clothing_itemPlaceholder = "Enter your favourite clothing item";
exports.upload_image_of_clothing_item = "Upload Image of Clothing Item?";
exports.age = "Favourite Age";
exports.agePlaceholder = "Enter your favourite age";
exports.date = "Date";
exports.about_age = "Why is this your favourite age?";
exports.about_agePlaceholder = "Anything special about your favourite age";
exports.home = "Favourite Home";
exports.homePlaceholder = "Enter your favourite home";
exports.colour = "Favourite Colour";
exports.colourPlaceholder = "Enter your favourite colour";
exports.holiday = "Favourite Festival/Holiday";
exports.holidayPlaceholder = "Enter your favourite festival/holiday";
exports.favouriteApiEndPoint = "bx_block_custom_form/favourite_informations";

//About hobbies
exports.hobbiesQuestion = "Do you have any hobbies you would like to add?";
exports.details = "Hobby Details";
exports.name = "Hobby Name";
exports.namePlaceholder = "Enter your hobby name";
exports.location = "Location";
exports.locationPlaceholder = "Enter the location";
exports.durationHeading = "How long have you been doing this?";
exports.duration = "Years/Months";
exports.durationPlaceholder = "Enter years/months";
exports.addMoreHobbies = "Add More Hobbies";

//about dreams
exports.job = "Dream Job";
exports.jobPlaceholder = "Enter your dream job";
exports.car = "Dream Car";
exports.carPlaceholder = "Enter your dream car";
exports.home = "Dream Home";
exports.homePlaceholder = "Enter your dream home";
exports.ultimate = "Ultimate Dream";
exports.ultimatePlaceholder = "Tell something about your ultimate dream";

//proudMoment 
exports.moment = "Proud Moment";
exports.momentPlaceholder = "Proudest moment of your life";
exports.momentDescription = "Description (Optional)";
exports.momentDescriptionPlaceholder = "Story of your proudest moment";
exports.momentHeading = "Whats your most proudest moment in life?";
exports.addMoreMoment = "Add more moments";

//about achievement
exports.achievements = "Achievements";
exports.achievement = "Achievement";
exports.achievementPlaceholder = "Achievement of your life";
exports.achievementDescription = "Description (Optional)";
exports.achievementDescriptionPlaceholder = "Details of your achievement";
exports.addMoreAchievements = "Add more achievements";
exports.wouldYouLikeToAddAchievements = "Would you like to add any achievements for loved ones to...";

//about CV 
exports.uploadCV = "1. Would you like to upload your CV for loved ones to view in the future?";
exports.addFavouritePosition = "2. Add favourite Position/Job";
exports.favouritePositionJob = "Favourite position/job";
exports.favouritePositionJobPlaceholder = "Favourite position/job";
exports.favouritePositionDescription = "Description (Optional)";
exports.favouritePositionDescriptionPlaceholder = "Why is this your favourite position/job.";

// After wishes //
exports.afterWishesPageTitle = "After Wishes";
exports.afterWishesQuestion = "Is there anything you would like to be done once you passed?";

exports.afterWishesFormTitle = "Delegate In-charge";
exports.delegateInChargeFieldLabel = "Delegate In-charge";
exports.myWishFieldLabel = "My wish";
exports.addMoreWishesBtnText = "Add more wishes";
exports.wishesTitle="Wishes"
exports.photosEndPoint = "bx_block_geotaggingforpictures/map_attachments/photos"
exports.videoEndPoint = "bx_block_geotaggingforpictures/map_attachments/videos"

// Customizable Area End