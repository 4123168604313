import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import * as Yup from "yup";

// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
import { sendAPIRequest } from "../../../components/src/Utils";
import { IDream } from "../../../blocks/customform/src/AboutDreamsController.web";

interface IDreamInformationResponse {
    id: string;
    type: string;
    attributes: IDreamInformationAttributes;
}

interface IDreamInformationAttributes {
    id: number;
    job: string;
    car: string;
    home: string;
    ultimate_dream: string;
    account_id: number;
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    handleError: (error: string) => void
    handleSuccess: () => void
    handleNext: (path: string) => void
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    dream: IDream,
    isLoading: boolean,
    isEdit: boolean,
    // Customizable Area End
}

interface SS {
    id: any;
}

export default class AboutDreamsEditController extends BlockComponent<
    Props,
    S,
    SS
> {

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
        ];

        this.state = {
            dream: {
                job: "",
                car: "",
                home: "",
                ultimate_dream: "",
            },
            isLoading: false,
            isEdit: false,
        };
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        runEngine.debugLog("Message Recived", message);
        const apiRequestCallId = message.getData(
            getName(MessageEnum.RestAPIResponceDataMessage)
        );
        const responseJSON = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );

        if(apiRequestCallId === this.callGetAboutDreamsDataApiId){
            if(responseJSON.data){
              this.handleGetDreamsApiResponse(responseJSON.data as IDreamInformationResponse);
            }else{
              let messError = responseJSON?.errors[0]
              if(messError){
                  let key = Object.keys(messError)[0]
                  let message = key + " " + messError[key]
                  this.props.handleError(message.replace("_", " "))
              }
            }
          }
        else if (apiRequestCallId === this.callEditAboutDreamsDataApiId) {
            this.setState({ isLoading: false })
            if (responseJSON.data) {
                this.props.handleSuccess()
            } else {
                let error = responseJSON?.errors[0]
                if (error) {
                    let key = Object.keys(error)[0]
                    let message = key + " " + error[key]
                    this.props.handleError(message.replace("_", " "))
                }
            }
        }
        // Customizable Area End
    }

    // Customizable Area Start
    callGetAboutDreamsDataApiId: string = "";
    callEditAboutDreamsDataApiId: string = "";

    async componentDidMount() {
        super.componentDidMount();
        this.getDreamsData();
    }

    getDreamsData = async() => {
        const token = await getStorageData("token");
    
        this.callGetAboutDreamsDataApiId = sendAPIRequest(
            configJSON.getAboutMeDreamsDataApiEndPoint,
            {
              method: configJSON.getApiRequest,
              headers: {
                token,
              },
            }
          );
      };
    
      handleGetDreamsApiResponse = (response: IDreamInformationResponse) => {
            const attributes = response.attributes;
      
            let dream = {
                job: attributes.job,
                car: attributes.car,
                home: attributes.home,
                ultimate_dream: attributes.ultimate_dream,
            } as IDream;
    
            this.setState({ dream });   
      };

    handleAboutDreamsDataFormSubmit = async (values: IDream) => {
        if(!this.state.isEdit){
            this.setState({ isEdit: true });
            return;
          }
        const token = await getStorageData("token");
        const formData = new FormData();
        this.setState({ isLoading: true })
        Object.entries(values).forEach(([keyName, value]) => {
            formData.append(`dream[${keyName}]`, value);
        });

        this.callEditAboutDreamsDataApiId = sendAPIRequest(
            configJSON.getAboutMeDreamsDataApiEndPoint,
            {
                method: configJSON.postApiRequest,
                headers: {
                    token,
                },
                body: formData,
            }
        );
    };
    // Customizable Area End
}
