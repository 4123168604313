import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import { getStorageData } from "../../../framework/src/Utilities";
import { sendAPIRequest, urlToFile } from "../../../components/src/Utils";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { IStep } from "./LegalDataEditController.web";

export const configJSON = require("./config");

export interface Props {
    id: string;
    navigation: any
}

export interface IMedia {
    file_id: number;
    file_name: string;
    content_type: string;
    file_size: number;
    url: string;
}
export interface InvestmentsAndFundsInterface {
    investment: Investments,
    banking_provider: IBankingProvider[],
    stocks: IStock[],
    bonds: IBond[],
};

export interface Investments {
    have_banking_provider: string;
    have_stocks: string;
    have_premimum_bonds: string;
};
export interface IBankingProvider {
    provider_name: string;
};

export interface IStock {
    name: string;
    quantity: string;
    total_value: string;
};

export interface IBond {
    holder_name: string;
    unit: string;
    no_of_unit: string;
    bond_value: string;
    files: File[];
};


export interface S {
    investmentAndFunds: InvestmentsAndFundsInterface
    isEditInvestments: boolean
    investmentSteps: IStep[]
    successDialog: boolean
    maxDocumentAllow: number
}

export interface SS {

}

export default class InvesttmentsAndFundsEditController extends BlockComponent<Props,
    S,
    SS
> {
    callGetInvestmentsAPIID: string = ""
    callSetInvestmentsAPIID: string = ""
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),

        ];
        this.state = {
            investmentAndFunds: {
                investment: {
                    have_banking_provider: "0",
                    have_premimum_bonds: "0",
                    have_stocks: "0",
                },
                banking_provider: [{
                    provider_name: "",
                }],
                stocks: [{
                    name: "",
                    quantity: "",
                    total_value: "",
                }],
                bonds: [{
                    holder_name: "",
                    unit: "",
                    no_of_unit: "",
                    bond_value: "",
                    files: [],
                }],
            },
            isEditInvestments: false,
            investmentSteps: [
                { label: 'Home', path: 'HomePage' },
                { label: 'My Data', path: 'HomePage' },
                { label: 'Investments & Funds', path: 'InvestmentsAndFundsEdit' },
            ],
            successDialog: false,
            maxDocumentAllow: 2
        }
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        runEngine.debugLog("Message Recived", message);
        const apiRequestCallId = message.getData(
            getName(MessageEnum.RestAPIResponceDataMessage)
        );
        const responseJSON = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );

        if (apiRequestCallId === this.callGetInvestmentsAPIID) {
            if (responseJSON.data) {
                const {
                    have_banking_provider,
                    have_premimum_bonds,
                    have_stocks,
                    bank_providers,
                    stock_details,
                    bond_details
                } = responseJSON.data.attributes;

                let investmentAndFunds = {
                    investment: {
                        have_banking_provider: this.checkValue(have_banking_provider),
                        have_premimum_bonds: this.checkValue(have_premimum_bonds),
                        have_stocks: this.checkValue(have_stocks),
                    },
                    banking_provider: this.setBankProvider(bank_providers),
                    stocks: this.setStocks(stock_details),
                    bonds: this.setBondData(bond_details)
                }

                this.setState({ investmentAndFunds })
            }
        }
        if (apiRequestCallId === this.callSetInvestmentsAPIID) {
            if (responseJSON.data) {
                this.setState({successDialog: true})
            } else {
                this.handleApiErrorMes(responseJSON.errors)
            }
        }
        // Customizable Area End
    }
    handleInvestmentUpload = async (event: React.ChangeEvent<HTMLInputElement>, setFieldValue:
        {
            (field: string,
                value: any,
                shouldValidate?: boolean | undefined): void;
            (arg0: string, arg1: string): void;
        },
        index: number,
        prevFiles: File[]) => {
        if (event.target.files) {
            const hobbiesFile = Array.from(event.target.files);
            let files = [...prevFiles, ...hobbiesFile]
            if(files.length > this.state.maxDocumentAllow){
                let errorMessage = configJSON.maxFileError.replace("${numberOfFile}",this.state.maxDocumentAllow)
                toast.error(errorMessage)
                return false
            }
            setFieldValue(`bonds.${index}.files`, files)
        }
    };

    setBondData = (bonds: IBond[]) =>{
        let bondData = bonds?.length > 0 ? 
        bonds.map((bond: IBond) => ({
            holder_name: bond.holder_name,
            unit: bond.unit,
            no_of_unit: bond.no_of_unit,
            bond_value: bond.bond_value,
            files: bond.files,
        })) : [{
            holder_name: "",
            unit: "",
            no_of_unit: "",
            bond_value: "",
            files: [],
        }]
        return bondData
    }

    setBankProvider = (banks: IBankingProvider[]) =>{
        let bankData = banks?.length > 0 ? 
        banks.map((provider: IBankingProvider) => ({
            provider_name: provider.provider_name,
        })) : [{
            provider_name: ""
        }]
        return bankData
    }

    setStocks = (stocks: IStock[]) =>{
        let bankData = stocks?.length > 0 ? 
        stocks.map((stock: IStock) => ({
            name: stock.name,
            quantity: stock.quantity,
            total_value: stock.total_value,
        })) : [{
            name: "",
            quantity: "",
            total_value: "",
        }]
        return bankData
    }
    closeSuccessDialog = () => {
        this.getInvestmentsAndFundsData()
        this.setState({ successDialog: false, isEditInvestments: false,investmentSteps: [
            { label: 'Home', path: 'HomePage' },
            { label: 'My Data', path: 'HomePage' },
            { label: 'Investments & Funds', path: 'InvestmentsAndFundsEdit' },
        ], });
    }
    handleApiErrorMes = (apiError: {[key:string]: string}[]) => {
        if (apiError.length > 0) {
            apiError.forEach(errorObj => {
                Object.entries(errorObj).forEach(([key, message]) => {
                    let validErrorMessage = key === "token" ? message : key.replace("_", " ") + " " + message
                    toast.error(validErrorMessage)
                });
            });
        }
    }

    setEditForm=(event:React.MouseEvent<HTMLButtonElement, MouseEvent>)=>{
        event.preventDefault()
        this.setState((prevState) => ({
            isEditInvestments: true,
            investmentSteps: [...prevState.investmentSteps, { label: 'Edit Details', path: 'LegalData' }],
        }))
    }

    async componentDidMount() {
        super.componentDidMount()
        this.getInvestmentsAndFundsData()
        this.setMaxDocumentUpload()
    }

    checkValue = (value: string) => {
        return value === "Yes" ? '1' : '0'
    }
    setMaxDocumentUpload = async() =>{
        let subscriptionData = await getStorageData("active_subscription");
        let parsedSubscriptionData = JSON.parse(subscriptionData)?.features?.max_investment_documents
        if(parsedSubscriptionData){
        this.setState({maxDocumentAllow: parseInt(parsedSubscriptionData)})
            }
    }

    getInvestmentsAndFundsData = async () => {
        const token = await getStorageData("token");

        this.callGetInvestmentsAPIID = sendAPIRequest(
            configJSON.investmentsAndFundsEndPoint,
            {
                method: configJSON.validationApiMethodType,
                headers: {
                    token,
                },
            }
        );
    }

    getClassName = () =>{
        return this.state.isEditInvestments ? "formSectionBackground" : ""
    }

    getVaraintName = () =>{
        return this.state.isEditInvestments ? "h3" : "h2"
    }

    getTitle= () =>{
        return !this.state.isEditInvestments ? "Media" : ""
    }

    getFileLength = (length: number) =>{
return !this.state.isEditInvestments && length === 0
    }

    validateInvestments = () => {
        if (this.state.isEditInvestments) {
            return (
                Yup.object().shape({
                    stocks: Yup.array().when(['investment.have_stocks'], {
                        is: (have_stocks) => have_stocks === "1",
                        then: Yup.array().of(Yup.object().shape({
                            name: Yup.string().nullable().required("Please enter bond name"),
                            quantity: Yup.string().nullable().required("Please enter stock quantity"),
                            total_value: Yup.number().typeError("Please enter number value only").nullable().nullable().required("Please enter total value"),
                        })),
                        otherwise: Yup.array().of(Yup.object().nullable()),
                    }),
                    banking_provider: Yup.array().when(['investment.have_banking_provider'], {
                        is: (have_banking_provider) => have_banking_provider === "1",
                        then: Yup.array().of(Yup.object().shape({
                            provider_name: Yup.string().nullable().required("Please enter provider name"),
                        })),
                        otherwise: Yup.array().of(Yup.object().nullable()),
                    }),
                    bonds: Yup.array().when(['investment.have_premimum_bonds'], {
                        is: (have_premimum_bonds) => have_premimum_bonds === "1",
                        then: Yup.array().of(Yup.object().shape({
                            holder_name: Yup.string().nullable().required("Please enter bond holder name"),
                            unit: Yup.number().typeError("Please enter number value only").nullable().required("Please enter unit value"),
                            no_of_unit: Yup.number().typeError("Please enter number value only").nullable().required("Please enter numbers of units"),
                            bond_value: Yup.number().typeError("Please enter number value only").nullable().required("Please enter bond value"),
                        })),
                        otherwise: Yup.array().of(Yup.object().nullable()),
                    }),
                })
            )
        } else {
            return Yup.object().shape({});
        }
    }

    appendFormData = (formData: FormData, section: string, data: any) => {
        Object.entries(data).forEach(([keyName, value]) => {
          if (value) {
            formData.append(`${section}[][${keyName}]`, value as string);
          }
        });
      };

    handleInvestmentsForm = async (values: InvestmentsAndFundsInterface) => {
        if (this.state.isEditInvestments) {
            const token = await getStorageData("token");
            const formData = new FormData();
            Object.entries(values.investment).forEach(([keyName, value]) => {
                formData.append(`investment[${keyName}]`, value);
            });

            values.banking_provider.forEach((bankProvider) => {
                this.appendFormData(formData, "banking_provider", bankProvider)
            });
            for (const bond of values.bonds) {
                for (const [keyName, value] of Object.entries(bond)) {
                    if (value) {
                        if (keyName === 'files' && bond.files && bond.files.length > 0) {
                            for (const file of bond.files) {
                                const blobFile = await this.convertToBlob(file as File | IMedia);
                                formData.append(`bond[][${keyName}][]`, blobFile);
                            }
                        } else {
                            formData.append(`bond[][${keyName}]`, value as string);
                        }
                    }
                }
            }

            values.stocks.forEach((stock) => {
                this.appendFormData(formData, "stock", stock)
            });

            this.callSetInvestmentsAPIID = sendAPIRequest(
                configJSON.investmentsAndFundsEndPoint,
                {
                    method: configJSON.exampleAPiMethod,
                    headers: {
                        token,
                    },
                    body: formData,
                }
            );
        }
    }

    convertToBlob = async (file: File | IMedia) => {
        if ('content_type' in file) {
            return await urlToFile(file.url, file.file_name);
        } else {
            return file as Blob;
        }
    };


    handleNavigation = (route: string) => {
        const message = new Message(getName(MessageEnum.NavigationMessage));
        message.addData(getName(MessageEnum.NavigationTargetMessage), route);
        message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(message);
    };

}