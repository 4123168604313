import React from "react";

import {
  Box,
  CardMedia,
  Grid,
  IconButton,
  Typography
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import WebHeader from "../../../blocks/landingpage/src/WebHeader.web";
import WebFooter from "../../../components/src/Footer.web";
import { createTheme, styled, ThemeProvider } from "@material-ui/core/styles";
import BreadcrumbStepper from "../../../components/src/BreadcrumbStepper.web";
import PlayCircleFilledWhiteIcon from '@material-ui/icons/PlayCircleFilledWhite';



const theme = createTheme({
  palette: {
    primary: {
      main: "#0F172A",
    },
  },
  typography: {
    subtitle1: {
      fontSize: "16px",
      fontFamily: "Lato",
      lineHeight: "22px",
      fontWeight: 500,
    },
    subtitle2: {
      fontFamily: "Lato",
      lineHeight: "18px",
      fontSize: "12px",
      fontWeight: 400,
    },
  },
});
// Customizable Area End

import GeoPhotoVideoController, {
  Props
} from "./GeoPhotoVideoController.web";

export default class GeoPhotoVideo extends GeoPhotoVideoController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    const { mediaBreadCrumb, mediaFiles, isVideo } = this.state
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Wrapper>
          <WebHeader navigation={this.props.navigation} />
          <Box className="mediaImageContainer">
            <BreadcrumbStepper isMap={true} steps={mediaBreadCrumb} onStepClick={(path) => this.handleNavigation(path)} />
            <Grid container className="imageMainContainer">
              {mediaFiles?.map((_media) => {
                return (
                  <Grid item key={_media.id} xs={6} sm={4} md={3} lg={2} className="imageContainer">
                    {
                      isVideo ? <>
                      <Box position="relative" >
                        <video src={_media.url} className="cardMedia" muted />
                        <IconButton
                          className="playButton"
                        >
                          <PlayCircleFilledWhiteIcon fontSize="large" style={{ color: 'white' }} />
                        </IconButton>
                      </Box>
                        <Box
                          display="flex"
                          flexDirection="column"
                          gridGap="8px"
                          margin="12px 0"
                          overflow="hidden"
                        >
                          <Typography color="primary" variant="subtitle1">{_media.file_name}</Typography>
                          <Typography color="primary" variant="subtitle2">{_media.file_size}</Typography>
                        </Box>
                         </> :
                        <CardMedia 
                        className="cardMedia" 
                        component="img" 
                        image={_media.url} 
                        alt={_media.file_name} />
                    }
                  </Grid>
                )
              })}

            </Grid>

          </Box>
          <WebFooter handleNavigation={this.handleNavigation} />
        </Wrapper>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const Wrapper = styled("div")(() => ({
  "& .mediaImageContainer": {
    padding: '40px 50px',
    minHeight: "calc(100vh - 690px)"
  },
  "& .cardMedia": {
    width: "100%",
    aspectRatio: 1.1,
    height: "auto",
    objectFit: "cover",
    borderRadius: "12px",
  },
  "& .imageContainer":{
    padding: 8
  },
  "& .imageMainContainer":{
    margin: '30px -8px'
  },
  "& .playButton": {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
  }
}))
// Customizable Area End
