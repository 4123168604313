Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.putApiMethod = "PUT"
exports.deleteAPIMethod = "DELETE"
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "geotaggingforpictures";
exports.labelBodyText = "geotaggingforpictures Body";
exports.addLocationHeading = "Add Location"
exports.addLocationDesc = "Move the map to where you want to pin the location."
exports.addNewLocationButton = "Add New Location"
exports.btnExampleTitle = "CLICK ME";
exports.getPreviousMarkersEndPoint = "bx_block_geotaggingforpictures/maped_images"
exports.locationName = "Location Name";
exports.locationDes = "Description (optional)"
exports.locationDescription = "Description"
exports.locationNamePlace = "Enter a name for location"
exports.locationDesPlace = "Give a description/ significance of the place"
exports.saveLocation = "Save Location"
exports.uploadMedia= "Upload Media"
exports.uploadMediaDes = "Only Image and Video are allowed"
exports.nameErrorMessage = "Please enter a valid location name"
exports.deleteMarker = "Delete"
exports.editMarker = "Edit Location"
exports.viewLocation = "View Location"
exports.deleteLocation = "Delete Location"
exports.deleteMessage= "Are you sure you want to delete this location."
exports.cancelDilaog = "Cancel"
exports.photosAndVideos = "Photos / Videos"
exports.addSuccessMessage = "Your location has been saved successfully!!"
exports.editSuccessMessage = "Your location has been edited successfully!!"
exports.deleteSuccessMessage = "Your location has been deleted successfully!!"
exports.photosEndPoint = "bx_block_geotaggingforpictures/map_attachments/photos"
exports.videoEndPoint = "bx_block_geotaggingforpictures/map_attachments/videos"
exports.fileTypeErrMes = "Only image and video files are allowed. Please upload a valid file."
exports.maxMarkerError= "As per you plan can only add upto ${number} pin location."
exports.getUserPlanEndPoint = "bx_block_custom_user_subs/user_subscriptions/active_plan"
exports.map = "AIzaSyAtpiJDbDeUo0WUcbynM3PGJGnj6eHBhPg"
// Customizable Area End