import React from "react";
// Customizable Area Start
import { styled, ThemeProvider, createTheme } from "@material-ui/core/styles";
import GeoLocationSearchController, {
    configJSON,
    IMedia,
    Props,
} from "./GeoLocationSearchController.web";
import WebFooter from "../../../components/src/Footer.web";
import WebHeader from "../../landingpage/src/WebHeader.web";
import GenericModal from "../../../components/src/GenericModal";
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import { GoogleMap, LoadScript, OverlayView, StandaloneSearchBox } from '@react-google-maps/api';
import { Box, Button, InputAdornment, TextField, Typography } from "@material-ui/core";
import BreadcrumbStepper from "../../../components/src/BreadcrumbStepper.web";
import { fullScreenIcon, minScreenIcon, searchIcon, vector } from "./assets";
import { rightTickImg } from "../../forgot-password/src/assets";
import AddEditLocation from "./AddEditLocation.web";

export const theme = createTheme({
    overrides: {
        MuiTypography: {
            h1: {
                fontFamily: "Quattrocento",
                fontWeight: 600,
                fontSize: "20px",
                lineHeight: "28px",
                color: "#0F172A"
            },
            h4:{
                fontFamily: "Lato",
                fontWeight: 700,
                fontSize: "24px",
                lineHeight: "32px",
                textAlign: "center",
                color: "#0F172A",
                width: "100%",
                textTransform: "capitalize"
            },
            h2: {
                fontFamily: "Lato",
                fontSize: "17px",
                color: "#9176C4",
                fontWeight: 500,
                lineHeight: "28px",
            },
            h3:{
                width: "38%",
                color: "#64748B",
                fontWeight: 400,
                fontFamily: "Lato",
                fontSize: "18px",
                lineHeight: "26px",
            },
            h6:{
                fontFamily: "Lato",
                fontSize: "18px",
                color: "#334155",
                fontWeight: 400,
                lineHeight: "28px",
                padding: "10px 0 0 "
            },
            body1: {
                fontFamily: "Lato",
                fontSize: "18px",
                lineHeight: "26px",
                fontWeight: 700,
                color: "#0F172A",
            },
            body2: {
                fontFamily: "Lato",
                fontSize: "14px",
                lineHeight: "24px",
                fontWeight: 500,
                color: "#94A3B8",
                marginBottom: "8px",
            },
            subtitle1:{
                color: "#0F172A",
                fontWeight: 400,
                fontFamily: "Lato",
                fontSize: "18px",
                lineHeight: "26px",
                textAlign: "right",
                width: "60%"
            },
            subtitle2: {
                color: "#DC2626",
                fontWeight: 400,
                fontFamily: "Lato",
                fontSize: "14px",
                marginBottom: "12px",
                marginTop: "-13px",
                lineHeight: "26px",
                        }
        },
        MuiFormLabel: {
            root: {
                color: "#334155",
                fontFamily: "Lato",
                fontSize: "14px",
                fontWeight: 400,
                lineHeight: "22px",
            }
        },

        MuiButton: {
            root: {
                textTransform: "none",
                fontSize: '16px',
                fontFamily: "Lato",
                borderRadius: "100px",
                fontWeight: 600,
                width: "248px",
                height: "48px",
            },
            outlined: {
                color: "#DC2626",
                border: "1px solid #DC2626"
            },
            contained: {
                backgroundColor: "#A190C2",
                color: "#FFFFFF",

            },
            text: {
                color: "#9176C4",
                fontWeight: 500,
                lineHeight: "24px",
                textTransform: "none",
                width: "auto",
                height: "auto",
                textDecoration: 'underline', 
                textUnderlineOffset: '4px',
                padding: "0 15px 0 0" 
            }
        },
        MuiMenuItem: {
            root: {
                padding: '10px 14px'
            }
        },
        MuiPaper: {
            elevation1: {
                borderRadius: 8,
                boxShadow: "0px 2px 4px 0px #00000026",
                border: "1px solid #F5F5F5",
                position: 'absolute',
                width: '100%',
                marginTop: '4px',
                zIndex: 1

            }
        },
        MuiInputBase: {
            root: {
                minHeight: '48px',
                zIndex: 1,
                backgroundColor: "#FFF"
            }
        },
        MuiOutlinedInput: {
            root: {
                borderRadius: 8
            },
            notchedOutline: {
                border: 'none'
            }
        },
    },
});

// Customizable Area End

export default class GeoLocationSearch extends GeoLocationSearchController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    renderMarkerImage = (_file: File | IMedia) => {
        let file = (_file as IMedia)
        return (file.content_type.startsWith("image/") ? <img src={file.url} alt="file" /> :
            <video src={file.url} muted />
        )
    }
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { mapCenter, isAddLocation, successMes,mapZoom, addLocationDialog} = this.state;
        const {isMinScreen, navigation} = this.props
        return (
            <>
                <ThemeProvider theme={theme}>
                    <Wrapper>
                       {!isMinScreen && <WebHeader navigation={navigation} />}
                        <Box className="mainContainer">
                            {!isMinScreen &&
                            <Box className="breadcrumbContainer">
                                <BreadcrumbStepper isMap={true} steps={this.state.mapBreadCrumb} onStepClick={(path) => this.handleNavigation(path)} />
                            </Box>
                            }

                            <Box className="mapOuterContainer">
                                <LoadScript googleMapsApiKey={configJSON.map} libraries={["places"]}>
                                    <div style={{ position: 'relative' }}>
                                        <div className="mapContainerWrapper">
                                            <div style={{ width: '290px', position: 'relative' }}>
                                                <StandaloneSearchBox
                                                    onLoad={this.onSearchBoxLoad}
                                                    onPlacesChanged={this.handlePlacesChanged}
                                                >
                                                    <TextField
                                                        variant="outlined"
                                                        fullWidth
                                                        data-test-id="mapSearchField"
                                                        placeholder="Search"
                                                        className="seachInput"
                                                        InputProps={{
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    <img src={searchIcon} />
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                    />
                                                </StandaloneSearchBox>
                                            </div>
                                            <div className="searchIconStyle">
                                                <img data-test-id="mapZoomInOut" src={this.props.isMinScreen ? fullScreenIcon : minScreenIcon} onClick={()=> this.handleZoomClick()}/>

                                            </div>
                                        </div>
                                    </div>
                                    <GoogleMap
                                        mapContainerStyle={{ width: '100%', height: '100%', borderRadius: '4px'}}
                                        center={mapCenter}
                                        zoom={mapZoom}
                                        data-test-id="googleMap"
                                        onClick={this.handleMapClick}
                                        options={{
                                            disableDefaultUI: true,
                                            zoomControl: true,  
                                            streetViewControl: false,
                                            mapTypeControl: false,
                                            fullscreenControl: false,
                                            scaleControl: false
                                        }}
                                    >
                                        {this.state.allMarkers.map((_marker) => (
                                                <OverlayView
                                                    key={_marker.id}
                                                    position={{ lat: _marker.attributes.latitude, lng: _marker.attributes.longitude }}
                                                    mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                                                >
                                                    <div 
                                                    data-test-id={`overlay${_marker.id}`}
                                                    className="overlayWrapper"
                                                    onClick={(event:React.MouseEvent<HTMLInputElement>) => this.handleMarkerClick(event, _marker)}
                                                    >
                                                    <Box className="imageStack">
                                                        {_marker.attributes?.files?.slice(0,7).map((image, index) => {
                                                            return (
                                                                <Box
                                                                    key={index}
                                                                    className={`imageContainer image${index + 1}`} >
                                                                    {this.renderMarkerImage(image)}
                                                                </Box>
                                                            )
                                                        })}
                                                    </Box>
                                                    <img
                                                        src={vector}
                                                        alt="Marker Icon"
                                                        className="vectorImage"
                                                    />
                                                    </div>
                                                </OverlayView>
                                            ))}

                                    </GoogleMap>
                                </LoadScript>
                            </Box>
                        </Box>
                        {!isMinScreen &&  <WebFooter handleNavigation={this.handleNavigation} /> }
                    </Wrapper>
                    {isAddLocation && <GenericModal
                        open={isAddLocation}
                        onClose={this.handleCloseConfirmLoc}
                        data-test-id="information-added-modal"
                    >
                        <ThemeProvider theme={theme}>
                            <Wrapper>
                                    {successMes === "" ? 
                                <Box className="dialogContentWrapper">
                                    <Box className="dialogDescriptionWrapper">
                                        <Typography
                                            variant="h1"
                                            align="center"
                                        >
                                            {configJSON.addLocationHeading}
                                        </Typography>
                                        <Typography align="center" variant="h2">
                                            {configJSON.addLocationDesc}
                                        </Typography>
                                    </Box>
                                    <Box width='100%'>
                                        <Button
                                            fullWidth
                                            variant="contained"
                                            data-test-id="addLocationButton"
                                            onClick={this.handleAddLoc}
                                        >
                                            <AddRoundedIcon />{configJSON.addNewLocationButton}
                                        </Button>
                                    </Box>
                                </Box>
                                    : <Box className="dialogContentWrapper">
                                    <Box className="dialogDescriptionWrapper">
                                        <img alt="success"
                                         src={rightTickImg} 
                                         className="successDialogImg" />
                                        <Typography align="center" 
                                        variant="body2">
                                            {successMes}
                                        </Typography>
                                    </Box>
                                </Box>
                                    }
                            </Wrapper>
                        </ThemeProvider>
                    </GenericModal>}
                    {addLocationDialog && <AddEditLocation id="addEditLocation"  markerDetails={this.markerDetails()}/>}
                </ThemeProvider>
            </>
        );
        // Customizable Area End
    }
}


// Customizable Area Start
export const Wrapper = styled("div")(({ theme }) => ({
    "& .mapContainerWrapper": {
        alignItems: 'center',
        width: 360,
        position: "absolute",
        top: '20px',
        right: '20px',
        display: 'flex',
        justifyContent: "space-between"
    },
    "& .overlayWrapper": {
        transform: "translate(-50%, -100%)",
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
    },
    "& .vectorImage":{ 
        marginTop: 10, 
        width: 26, 
        height: 30 },
    "& .uploadImageContainer":{
        width: "259px",
        maxWidth: "100%",
        padding: 6,
        position: "relative"
    },
    "& .uploadedFiles":{
        width: '100%',
        aspectRatio: '5/3',
        borderRadius: 12,
        border: '1px solid #33415538',
        objectFit: "cover",
                  pointerEvents: "none", 
    },
    "& .btnWrapper": {
        display: "flex",
        gap: "8px",
        justifyContent: "flex-end",
    },
    "& .inputWrapper":{
        marginTop: "20px"
    },
    "& .imageStack": {
        position: "relative",
        width: 40,
        height: 71,
        borderRadius: 4,
      },
      "& .imageContainer": {
        position: "absolute",
        top: 0,
        left: 0,
        width: "40px",
        height: "71px",
        borderRadius: 4,
        overflow: "hidden",
        border: "1px solid #F5F9FF",
        boxShadow: "-4px -4px 4px 0px #00000014, 4px 4px 4px 0px #00000014",
        transition: "transform 0.3s ease, opacity 0.3s ease",
        "& img,video": {
          width: "100%",
          height: "100%",
          objectFit: "cover"          
        },
      },
      "& .image1": {
        transform: "translate(0px, 0px) scale(1)",
        zIndex: 3,
      },
      "& .image2": {
        transform: "translate(8px, 3px) scale(0.95)",
        zIndex: 2,
        height: '67px'
      },
      "& .image3": {
        transform: "translate(-8px,3px) scale(0.95)",
        zIndex: 2,
        height: '67px'
      },
      "& .image4": {
        transform: "translate(16px, 6px) scale(0.85)",
        zIndex: 1,
        height: '63px'
      },
      "& .image5": {
        transform: "translate(-16px, 6px) scale(0.85)",
        zIndex: 1,
        height: '63px'
      },
      "& .image6": {
        transform: "translate(24px, 9px) scale(0.80)",
        height: '59px'
      },
      "& .image7": {
        transform: "translate(-24px, 9px) scale(0.80)",
        height: '59px'
      },
    "& .successDialogImg":{ width: "56px", height: "56px", margin: "0px auto" },
    "& .deleteFilesIcon":{
        fontSize: "14px",
        color: "#DC2626",
        position: 'absolute',
        top: 0,
        right: 0,
        boxShadow: "0 2px 8px #00000014",
        padding: '5px',
        backgroundColor: "#F1F5F9",
        borderRadius: "50%",
    },
    "& .mapOuterContainer": {
        minHeight: '600px',
        height: 'calc(100vh - 700px)',
        maxHeight: "1350px"
    },
    "& .searchInput": {
        borderRadius: "8px",
        height: "48px",
        width: "275px",
        zIndex: 1,
        background: "white"
    },
    "& .mainContainer": {
        padding: '40px 0 50px',
        width: '100%'
    },
    "& .breadcrumbContainer": {
        padding: "0px 30px 10px"
    },
    "& .chooseFileBtn":{
        width:'155px'
    },
    "& .searchIconStyle": {
        zIndex: 1,
        width: '26px',
        padding: "12px",
        borderRadius: '8px',
        height: '26px',
        background: "#FFF",
        boxShadow: "0px 4px 8px 0px #00000008"
    },
    "& .uploadOuterBox": {
        border: "2px dashed #d1c4e9",
        padding: "16px",
        borderRadius: "8px",
        display: "flex",
        alignItems: "center",
        cursor: "pointer",
        justifyContent: "flex-start",
        backgroundColor: "#fafafa",
        gap: "16px",
        marginBottom: "16px",
      },
    "& .dialogContentWrapper": {
        flexDirection: "column",
        display: "flex",
        alignItems: "center",
    },
    "& .dialogDescriptionWrapper": {
        maxWidth: "339px",
        display: "flex",
        gap: "16px",
        flexDirection: "column",
        justifyContent: "center",
        marginBottom: "32px",
    },
    "& .headingDes": {
        display: '-webkit-box',
        overflow: 'hidden',
        WebkitBoxOrient: 'vertical',
        textOverflow: 'ellipsis',
        WebkitLineClamp: 2
    },

}));
// Customizable Area End