import { Message } from "../../framework/src/Message";
import MessageEnum, { getName } from "../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../framework/src/RunEngine";

type HttpMethods = 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE'
interface RequestParams {
  method?: HttpMethods;
  headers?: object;
  body?: object | FormData | any;
}

export function sendAPIRequest(endpoint: string, params?: RequestParams) {
    const { method = "GET", headers, body } = params || {}
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    )
    
    const callId = requestMessage.messageId
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endpoint
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    )
    if (body)
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        body?.append ? body : JSON.stringify(body)
      )
   
    if (headers)
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        headers
      )
    runEngine.sendMessage(requestMessage.id, requestMessage)
    return callId
  };

  export function getLastPartOfURL() {
    let url = window.location.pathname;
    let parts = url.split("/");
    let lastPart = parts[parts.length - 1];
    return lastPart;
};

export async function urlToFile(url: string, filename: string) {
  return new Promise<Blob>(async (resolve, reject) => {
    try {
      const response = await fetch(url);
      if (!response.ok) {
        reject(new Error('Blob conversion failed'));
      }
      const blob = await response.blob();
  
      const file = new File([blob], filename, { type: blob.type });
  
      resolve(file);
    } catch (error) {
      console.error("Error converting URL to file:", error);
      reject(new Error('Blob conversion failed'));
    }
  });
};

export function convertSizeToBytes(sizeString: string) : number {
  const sizeUnits = {
      B: 1,
      KB: 1024,
      MB: 1024 * 1024,
  };

  const regex = /^([\d.]+)\s*(B|KB|MB)$/i;
  const match = sizeString.match(regex);
  if (match) {
    const size = parseFloat(match[1]);
    const unit = match[2].toUpperCase() as keyof typeof sizeUnits;
    return size * sizeUnits[unit];
  } else {
      return sizeUnits.KB
  }
}