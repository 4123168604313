import React from "react";
// Customizable Area Start
import AboutDreamsController, {
    configJSON,
    Props
} from "./AboutDreamsController.web";
import {
    Box,
    Grid,
    Button,
    FormLabel
} from "@material-ui/core";
import { Field, Form, Formik } from "formik";
import CustomInput from "../../../components/src/CustomInput";
import ErrorMessage from "../../../components/src/ErrorMessage";

// Customizable Area End

export default class AboutDreams extends AboutDreamsController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        // Customizable Area Start
        const {
            dream,
        } = this.state;
        return (
            <Box className="formWrapper">
                <Formik
                    initialValues={dream}
                    onSubmit={(values) => {
                        this.handleAboutDreamsInformationsFormSubmit(values)
                    }}
                    enableReinitialize={true}
                    data-test-id="AboutDreamsForm"
                >
                    {({ values, setFieldValue }) => (
                        <Form>
                            <Box className="formSectionBackground">
                                <Grid container spacing={3}>
                                    <Grid item lg={4} xs={12} sm={6}>
                                        <FormLabel component="label">
                                            {configJSON.job}
                                        </FormLabel>
                                            <Grid item xs={12} >
                                                <Field
                                                    data-test-id="favjob"
                                                    className="inputField"
                                                    name={configJSON.job}
                                                    as={CustomInput}
                                                    size="small"
                                                    placeholder={configJSON.jobPlaceholder}
                                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                        setFieldValue(`job`, e.target.value)
                                                    }}
                                                    value={values.job}
                                                />
                                                <ErrorMessage name={`job`} />
                                            </Grid>
                                    </Grid>
                                    <Grid item lg={4} xs={12} sm={6}>
                                        <FormLabel component="label">
                                            {configJSON.car}
                                        </FormLabel>
                                            <Grid item xs={12} >
                                                <Field
                                                    data-test-id="favcar"
                                                    className="inputField"
                                                    name={configJSON.car}
                                                    as={CustomInput}
                                                    size="small"
                                                    placeholder={configJSON.carPlaceholder}
                                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                        setFieldValue(`car`, e.target.value)
                                                    }}
                                                    value={values.car}
                                                />
                                                <ErrorMessage name={`car`} />
                                            </Grid>
                                    </Grid>
                                    <Grid item lg={4} xs={12} sm={6}>
                                        <FormLabel component="label">
                                            {configJSON.home}
                                        </FormLabel>
                                            <Grid item xs={12} >
                                                <Field
                                                    data-test-id="favhome"
                                                    className="inputField"
                                                    name={configJSON.home}
                                                    as={CustomInput}
                                                    size="small"
                                                    placeholder={configJSON.homePlaceholder}
                                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                        setFieldValue(`home`, e.target.value)
                                                    }}
                                                    value={values.home}
                                                />
                                                <ErrorMessage name={`home`} />
                                            </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormLabel component="label">
                                            {configJSON.ultimate}
                                        </FormLabel>
                                            <Grid item xs={12} >
                                                <Field
                                                    data-test-id="favAboutAge"
                                                    className="multilineInput"
                                                    name={configJSON.ultimate}
                                                    as={CustomInput}
                                                    size="small"
                                                    multiline
                                                    placeholder={configJSON.ultimatePlaceholder}
                                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                        setFieldValue(`ultimate_dream`, e.target.value)
                                                    }}
                                                    value={values.ultimate_dream}
                                                />
                                                <ErrorMessage name={`ultimate_dream`} />
                                            </Grid>
                                    </Grid>
                                    </Grid>
                            </Box>
                            <Box className="btnWrapper">
                                <Button
                                    variant="outlined"
                                    className="btnSize"
                                    onClick={() => this.props.handleNext("CustomForm")}
                                >
                                    {configJSON.skipForNowBtnText}
                                </Button>
                                <Button
                                    variant="contained"
                                    className="btnSize"
                                    type="submit"
                                    data-test-id="saveAboutDream"
                                >
                                    {configJSON.saveAndNext}
                                </Button>
                            </Box>
                        </Form>
                    )}
                </Formik>
            </Box>
        );
        // Customizable Area End
    }
}


// Customizable Area Start
// Customizable Area End