import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData, removeStorageData } from "../../../../packages/framework/src/Utilities";
import { loadStripe } from "@stripe/stripe-js";

// Customizable Area Start
  export interface Errors {
    line1?: string;
    line2?: string;
    landmark?: string;
    city?: string;
    country?: string;
    postCode?: string;
  }
  export interface ShippingAddress {
    line1?: string;
    line2?: string;
    landmark?: string;
    city?: string;
    country?: string;
    postCode?: string;
  }
  interface CountryDropDownItem {
    value: number,
    name: string,
  }
  
  export const countries: CountryDropDownItem[] = [
    { value: 1, name: "Afghanistan" },
    { value: 2, name: "Albania" },
    { value: 3, name: "Algeria" },
    { value: 4, name: "Andorra" },
    { value: 5, name: "Angola" },
    { value: 6, name: "Antigua and Barbuda" },
    { value: 7, name: "Argentina" },
    { value: 8, name: "Armenia" },
    { value: 9, name: "Australia" },
    { value: 10, name: "Austria" },
    { value: 11, name: "Azerbaijan" },
    { value: 12, name: "Bahamas" },
    { value: 13, name: "Bahrain" },
    { value: 14, name: "Bangladesh" },
    { value: 15, name: "Barbados" },
    { value: 16, name: "Belarus" },
    { value: 17, name: "Belgium" },
    { value: 18, name: "Belize" },
    { value: 19, name: "Benin" },
    { value: 20, name: "Bhutan" },
    { value: 21, name: "Bolivia" },
    { value: 22, name: "Bosnia and Herzegovina" },
    { value: 23, name: "Botswana" },
    { value: 24, name: "Brazil" },
    { value: 25, name: "Brunei" },
    { value: 26, name: "Bulgaria" },
    { value: 27, name: "Burkina Faso" },
    { value: 28, name: "Burundi" },
    { value: 29, name: "Cabo Verde" },
    { value: 30, name: "Cambodia" },
    { value: 31, name: "Cameroon" },
    { value: 32, name: "Canada" },
    { value: 33, name: "Central African Republic" },
    { value: 34, name: "Chad" },
    { value: 35, name: "Chile" },
    { value: 36, name: "China" },
    { value: 37, name: "Colombia" },
    { value: 38, name: "Comoros" },
    { value: 39, name: "Congo (Congo-Brazzaville)" },
    { value: 40, name: "Costa Rica" },
    { value: 41, name: "Croatia" },
    { value: 42, name: "Cuba" },
    { value: 43, name: "Cyprus" },
    { value: 44, name: "Czechia (Czech Republic)" },
    { value: 45, name: "Democratic Republic of the Congo" },
    { value: 46, name: "Denmark" },
    { value: 47, name: "Djibouti" },
    { value: 48, name: "Dominica" },
    { value: 49, name: "Dominican Republic" },
    { value: 50, name: "Ecuador" },
    { value: 51, name: "Egypt" },
    { value: 52, name: "El Salvador" },
    { value: 53, name: "Equatorial Guinea" },
    { value: 54, name: "Eritrea" },
    { value: 55, name: "Estonia" },
    { value: 56, name: "Eswatini (fmr. 'Swaziland')" },
    { value: 57, name: "Ethiopia" },
    { value: 58, name: "Fiji" },
    { value: 59, name: "Finland" },
    { value: 60, name: "France" },
    { value: 61, name: "Gabon" },
    { value: 62, name: "Gambia" },
    { value: 63, name: "Georgia" },
    { value: 64, name: "Germany" },
    { value: 65, name: "Ghana" },
    { value: 66, name: "Greece" },
    { value: 67, name: "Grenada" },
    { value: 68, name: "Guatemala" },
    { value: 69, name: "Guinea" },
    { value: 70, name: "Guinea-Bissau" },
    { value: 71, name: "Guyana" },
    { value: 72, name: "Haiti" },
    { value: 73, name: "Holy See" },
    { value: 74, name: "Honduras" },
    { value: 75, name: "Hungary" },
    { value: 76, name: "Iceland" },
    { value: 77, name: "India" },
    { value: 78, name: "Indonesia" },
    { value: 79, name: "Iran" },
    { value: 80, name: "Iraq" },
    { value: 81, name: "Ireland" },
    { value: 82, name: "Israel" },
    { value: 83, name: "Italy" },
    { value: 84, name: "Jamaica" },
    { value: 85, name: "Japan" },
    { value: 86, name: "Jordan" },
    { value: 87, name: "Kazakhstan" },
    { value: 88, name: "Kenya" },
    { value: 89, name: "Kiribati" },
    { value: 90, name: "Kuwait" },
    { value: 91, name: "Kyrgyzstan" },
    { value: 92, name: "Laos" },
    { value: 93, name: "Latvia" },
    { value: 94, name: "Lebanon" },
    { value: 95, name: "Lesotho" },
    { value: 96, name: "Liberia" },
    { value: 97, name: "Libya" },
    { value: 98, name: "Liechtenstein" },
    { value: 99, name: "Lithuania" },
    { value: 100, name: "Luxembourg" },
    { value: 101, name: "Madagascar" },
    { value: 102, name: "Malawi" },
    { value: 103, name: "Malaysia" },
    { value: 104, name: "Maldives" },
    { value: 105, name: "Mali" },
    { value: 106, name: "Malta" },
    { value: 107, name: "Marshall Islands" },
    { value: 108, name: "Mauritania" },
    { value: 109, name: "Mauritius" },
    { value: 110, name: "Mexico" },
    { value: 111, name: "Micronesia" },
    { value: 112, name: "Moldova" },
    { value: 113, name: "Monaco" },
    { value: 114, name: "Mongolia" },
    { value: 115, name: "Montenegro" },
    { value: 116, name: "Morocco" },
    { value: 117, name: "Mozambique" },
    { value: 118, name: "Myanmar" },
    { value: 119, name: "Namibia" },
    { value: 120, name: "Nauru" },
    { value: 121, name: "Nepal" },
    { value: 122, name: "Netherlands" },
    { value: 123, name: "New Zealand" },
    { value: 124, name: "Nicaragua" },
    { value: 125, name: "Niger" },
    { value: 126, name: "Nigeria" },
    { value: 127, name: "North Korea" },
    { value: 128, name: "North Macedonia" },
    { value: 129, name: "Norway" },
    { value: 130, name: "Oman" },
    { value: 131, name: "Pakistan" },
    { value: 132, name: "Palau" },
    { value: 133, name: "Palestine State" },
    { value: 134, name: "Panama" },
    { value: 135, name: "Papua New Guinea" },
    { value: 136, name: "Paraguay" },
    { value: 137, name: "Peru" },
    { value: 138, name: "Philippines" },
    { value: 139, name: "Poland" },
    { value: 140, name: "Portugal" },
    { value: 141, name: "Qatar" },
    { value: 142, name: "Romania" },
    { value: 143, name: "Russia" },
    { value: 144, name: "Rwanda" },
    { value: 145, name: "Saint Kitts and Nevis" },
    { value: 146, name: "Saint Lucia" },
    { value: 147, name: "Saint Vincent and the Grenadines" },
    { value: 148, name: "Samoa" },
    { value: 149, name: "San Marino" },
    { value: 150, name: "Sao Tome and Principe" },
    { value: 151, name: "Saudi Arabia" },
    { value: 152, name: "Senegal" },
    { value: 153, name: "Serbia" },
    { value: 154, name: "Seychelles" },
    { value: 155, name: "Sierra Leone" },
    { value: 156, name: "Singapore" },
    { value: 157, name: "Slovakia" },
    { value: 158, name: "Slovenia" },
    { value: 159, name: "Solomon Islands" },
    { value: 160, name: "Somalia" },
    { value: 161, name: "South Africa" },
    { value: 162, name: "South Korea" },
    { value: 163, name: "South Sudan" },
    { value: 164, name: "Spain" },
    { value: 165, name: "Sri Lanka" },
    { value: 166, name: "Sudan" },
    { value: 167, name: "Suriname" },
    { value: 168, name: "Sweden" },
    { value: 169, name: "Switzerland" },
    { value: 170, name: "Syria" },
    { value: 171, name: "Tajikistan" },
    { value: 172, name: "Tanzania" },
    { value: 173, name: "Thailand" },
    { value: 174, name: "Timor-Leste" },
    { value: 175, name: "Togo" },
    { value: 176, name: "Tonga" },
    { value: 177, name: "Trinidad and Tobago" },
    { value: 178, name: "Tunisia" },
    { value: 179, name: "Turkey" },
    { value: 180, name: "Turkmenistan" },
    { value: 181, name: "Tuvalu" },
    { value: 182, name: "Uganda" },
    { value: 183, name: "Ukraine" },
    { value: 184, name: "United Arab Emirates" },
    { value: 185, name: "United Kingdom" },
    { value: 186, name: "United States of America" },
    { value: 187, name: "Uruguay" },
    { value: 188, name: "Uzbekistan" },
    { value: 189, name: "Vanuatu" },
    { value: 190, name: "Venezuela" },
    { value: 191, name: "Vietnam" },
    { value: 192, name: "Yemen" },
    { value: 193, name: "Zambia" },
    { value: 194, name: "Zimbabwe" },
    { value: 195, name: "Taiwan" }
  ];
  
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: any;
  productid:number;
  paymentData:any;
  productData:any;
  products: any [];
  count:number;
  billing:boolean;
  shippingAddress:ShippingAddress;
  billingAddress:ShippingAddress;
  billingSameAsShipping:boolean;
  price:number;
  subTotal:number;
  deliveryCharges:number;
  totalPayable:number;
  errors: {
    shippingAddress: Errors;
    billingAddress: Errors;
  };
  isChecked:boolean;
  open:boolean
  accept:boolean;
  reject:boolean;
  params: any;
  shippingAddresline1:string;
  openDialog: boolean;
  paymentSuccessed: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class OrderSummaryController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiGetDataCallId: string = "";
  confirmPayCallId: string="";
  showProductCallId:string = "";
  stripePromise = loadStripe('pk_test_51OzGO1C4D0Bu5gBia4YiUAHh3O8uWSpb6uViB4pJMUgAaAGudGMUXQjbaelpbuFeKOHZuBCPxeSIdoH7HLfFFPAb00JDTq3D6e');

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.increment = this.increment.bind(this);
    this.decrement = this.decrement.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      token: "",
      productid: 1,
      productData:null,
      paymentData:null,
      products: [],
      count: 1,
      billing:true,
      shippingAddress: {
        line1: '',
        line2: '',
        landmark: '',
        city: '',
        country: '',
        postCode: ''
    },
    billingAddress: {
      line1: '',
      line2: '',
      landmark: '',
      city: '',
      country: '',
      postCode: ''
  },
    billingSameAsShipping: false,
    price: 0,  
    subTotal: 0, 
    deliveryCharges: 0, 
    totalPayable: 0, 
    errors: {
      shippingAddress: {},
      billingAddress: {}
    },
    isChecked: false,
    open:false,
    accept:false,
    reject:false,
    params:"",
    shippingAddresline1:"",
    openDialog: false,
    paymentSuccessed: false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
        const response = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
        const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
        runEngine.debugLog("API Response Received", response);
  
        if (apiRequestCallId === this.showProductCallId) {
          await this.handleShowProductResponse(response);
        }
        if (apiRequestCallId === this.confirmPayCallId) {
          if (response) {
            const paymentData =response
             this.setState({paymentData})
             if(paymentData.checkout){
              const stripe = await this.stripePromise;
               await stripe?.redirectToCheckout({
                sessionId: response.checkout.id,
              });
            };
        
          }
      }
      }  
    // Customizable Area End
  }

  // Customizable Area Start


  async componentDidMount() {
    super.componentDidMount();

    const searchParams = new URLSearchParams(window.location.search);
    const status = searchParams.get("status");
    if(status === "success"){
      this.setState({ openDialog: true, paymentSuccessed: true });
    }
    else if(status === "cancel"){
      this.setState({ openDialog: true, paymentSuccessed: false });
    }

    this.initializeParams();
    this.functioncall();
  }

  async componentWillUnmount() {
    await removeStorageData("Quantity");
    await removeStorageData("OrderDetails");
  }

  // Extracted function for handling product data response
private async handleShowProductResponse(response: any) {
  if (response?.data) {
    const productData = response.data.attributes;
    const quantity = await getStorageData("Quantity");
    const orderDetails = await getStorageData("OrderDetails", true);

    const count = quantity ? parseInt(quantity) : 1;
    const subTotal = count * productData.price;
    const deliveryCharges = productData.delivery_charge
    const totalPayable = subTotal + deliveryCharges;

    this.setState({
      productData,
      price: productData.price,
      subTotal,
      totalPayable,
      deliveryCharges,
      count
    });

    if(orderDetails){
      const address = orderDetails.delivery_address.data.attributes;
      const shippingAddress = {
        line1: address.address,
        line2: address.address_line_2,
        landmark: address.landmark,
        city: address.city,
        country: address.country,
        postCode: address.zip_code,
      };
      this.setState({ shippingAddress });
    }
  }
}

functioncall =()=>{
  const productid = this.props.navigation.getParam("navigationBarTitleText"); 
     this.setState({ productid: productid }, () => {
         this.handleShowProduct(this.state.productid); 
     });
}
 
   handleShowProduct = async (productId: number) => {
    this.showProductCallId = await this.getApiCall({
        method: "GET",
        endPoint: `/bx_block_categories/products/${productId}`,
        contentType: "application/json",
    });
}

payBtn = async () => {

  const isValid = this.validateForm();
  if (!isValid) {
   
    return;
  }

  const { productid, count, price, deliveryCharges, shippingAddress, billingAddress, billingSameAsShipping } = this.state;

  let addressArray = [];

  if (billingSameAsShipping) {
      addressArray = [{
          address: shippingAddress.line1,
          address_line_2: shippingAddress.line2,
          landmark: shippingAddress.landmark,
          city: shippingAddress.city,
          country: shippingAddress.country,
          zip_code: shippingAddress.postCode,
          address_for: "billing_and_shipping"
      }];
  } else {
      addressArray = [
          {
              address: shippingAddress.line1,
              address_line_2: shippingAddress.line2,
              landmark: shippingAddress.landmark,
              city: shippingAddress.city,
              country: shippingAddress.country,
              zip_code: shippingAddress.postCode,
              address_for: "shipping"
          },
          {
              address: billingAddress.line1,
              address_line_2: billingAddress.line2,
              landmark: billingAddress.landmark,
              city: billingAddress.city,
              country: billingAddress.country,
              zip_code: billingAddress.postCode,
              address_for: "billing"
          }
      ];
  }

  const subTotal = count * price;
  const total = subTotal + deliveryCharges;

  const orders = {
      quantity: count,
      amount: price,
      sub_total: subTotal,
      delivery_charges: deliveryCharges,
      total: total
  };

  const payload = {
      product_id: productid,
      address: addressArray,
      orders: orders
  };

     this.confirmPayCallId= await this.getApiCall({
          method: "POST",
          endPoint: "/bx_block_payments/product_checkouts",
          contentType: "application/json",
          body: payload
      });
 
};
  getApiCall = async (data: any) => {
    const token = await getStorageData("token");
    const { contentType, type, method, endPoint, body } = data;
    const header = { "Content-Type": contentType, token };
    const request = new Message(getName(MessageEnum.RestAPIRequestMessage));
    
      
    request.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    request.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    request.addData(getName(MessageEnum.RestAPIRequestMethodMessage), method);
    body && type != "formData"
      ? request.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),
          JSON.stringify(body)
        )
      : request.addData(getName(MessageEnum.RestAPIRequestBodyMessage), body);
    runEngine.sendMessage(request.id, request);
    return request.messageId;
  };


  
  navigateToShop = async ()=>{
    const navigateToUserProfile = new Message(getName(MessageEnum.NavigationMessage));
    navigateToUserProfile.addData(getName(MessageEnum.NavigationTargetMessage), "NavigationMenu");
    navigateToUserProfile.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(navigateToUserProfile);
  }


  navigateToLandingPage = async ()=>{
    const navigateToLandingPage = new Message(getName(MessageEnum.NavigationMessage));
    navigateToLandingPage.addData(getName(MessageEnum.NavigationTargetMessage), "LandingPage");
    navigateToLandingPage.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(navigateToLandingPage);
  }
  handleProductNavigation = (productId: number) => {
    const message: Message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), 'NavigationMenu');
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    message.addData(getName(MessageEnum.NavigationScreenNameMessage), productId);
    this.send(message);
}

handleShowProductNavigation = (productId: number) => {
  const message: Message = new Message(getName(MessageEnum.NavigationMessage));
  message.addData(getName(MessageEnum.NavigationTargetMessage), 'ShowProduct');
  message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
  message.addData(getName(MessageEnum.NavigationScreenNameMessage), productId);
  this.send(message);
}
increment() {
  this.setState(prevState => {
    const newCount = prevState.count + 1;
    const newSubTotal = newCount * prevState.price;
    const newTotalPayable = newSubTotal + prevState.deliveryCharges;
    return {
      count: newCount,
      subTotal: newSubTotal,
      totalPayable: newTotalPayable,
    };
  });
}


decrement() {
  if (this.state.count > 1) {
    this.setState(prevState => {
      const newCount = prevState.count - 1;
      const newSubTotal = newCount * prevState.price;
      const newTotalPayable = newSubTotal + prevState.deliveryCharges;
      return {
        count: newCount,
        subTotal: newSubTotal,
        totalPayable: newTotalPayable,
      };
    });
  }
}
handleCheckboxChange = (event: { target: { checked: any; }; }) => {
  const isChecked = event.target.checked;
  this.setState({ billingSameAsShipping: isChecked });

  if (isChecked) {
      this.setState({ billingAddress: { ...this.state.shippingAddress } });
  } else {
      this.setState({ billingAddress: {
          line1: '',
          line2: '',
          landmark: '',
          city: '',
          country: '',
          postCode: ''
      }});
  }
};

handleShippingChange = (event: React.ChangeEvent<HTMLInputElement>, field: string) => {
    this.setState((prevState) => ({
      errors: {
        ...prevState.errors,
        shippingAddress: {
          ...prevState.errors.shippingAddress,
          [field]: "",
        },
      },
    }));

  this.setState((prevState) => {
    const updatedShippingAddress = {
      ...prevState.shippingAddress,
      [field]: event.target.value,
    };

    return {
      shippingAddress: updatedShippingAddress,
      billingAddress: prevState.billingSameAsShipping
        ? { ...updatedShippingAddress }
        : prevState.billingAddress,
    };
  });
};


handleBillingChange = (field: any, value: any) => {
  this.setState((prevState) => ({
    errors: {
      ...prevState.errors,
      billingAddress: {
        ...prevState.errors.billingAddress,
        [field]: "",
      },
    },
  }));

  this.setState(prevState => ({
      billingAddress: {
          ...prevState.billingAddress,
          [field]: value
      }
  }));
};

validateFields = (addressType: "shippingAddress" | "billingAddress") => {
  const errors: Errors = {};

  const address = this.state[addressType];

  if (!address.line1) errors.line1 = "Please enter a address line 1";
  if (!address.line2) errors.line2 = "Please enter a address line 2";
  if (!address.city) errors.city = "Please enter a city";
  if (!address.country) errors.country = "Please enter a country";
  if (!address.postCode) errors.postCode = "Please enter a postcode";

  this.setState((prevState) => ({
    errors: {
      ...prevState.errors,
      [addressType]: errors,
    },
  }));

  return Object.keys(errors).length === 0;
};

validateForm = () => {
  const isShippingValid = this.validateFields("shippingAddress");
  const isBillingValid = this.state.billingSameAsShipping ? true : this.validateFields("billingAddress");

  return isShippingValid && isBillingValid;
};




handleClose = () => {
  this.setState({ open: false });
};


initializeParams = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const status = urlParams.get('status');

  if (status === 'true') {
    this.setState({ accept: true, reject: false, open: true });
  } else if (status === 'false') {
    this.setState({ accept: false, reject: true, open: true });
  } else {
    this.setState({ open: false });  
  }
};
  

handleCountryChange = (event: React.ChangeEvent<{ name?: string; value: string | undefined | unknown; }>) => {
  const selectedCountry = event.target.value as string;

  this.setState((prevState) => ({
    errors: {
      ...prevState.errors,
      shippingAddress: {
        ...prevState.errors.shippingAddress,
        country: "",
      },
    },
  }));

  this.setState((prevState) => {
    const updatedShippingAddress = {
      ...prevState.shippingAddress,
      country: selectedCountry,
    };

    const updatedBillingAddress = prevState.billingSameAsShipping
      ? { ...updatedShippingAddress }
      : prevState.billingAddress;

    return {
      shippingAddress: updatedShippingAddress,
      billingAddress: updatedBillingAddress,
    };
  });
};
handleBillingCountryChange = (event: React.ChangeEvent<{ name?: string; value: string | undefined | unknown; }>) => {
  const selectedCountry = event.target.value as string;

  this.setState((prevState) => ({
    errors: {
      ...prevState.errors,
      billingAddress: {
        ...prevState.errors.billingAddress,
        country: "",
      },
    },
  }));

  this.setState(prevState => ({
    billingAddress: {
      ...prevState.billingAddress,
      country: selectedCountry
    }
  }));
};
handelDelete =()=>{
  this.setState({
    count: 0,
    totalPayable: 0,
    subTotal: 0,
  });

}
handleNavigation = (route: string) => {
  const message = new Message(getName(MessageEnum.NavigationMessage));
  message.addData(getName(MessageEnum.NavigationTargetMessage), route);
  message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
  this.send(message);
};

handleCloseDialog = () => {
  this.setState({ openDialog: false });
};
  // Customizable Area End
}
