import React from "react";
// Customizable Area Start
import { Box, Container, Typography, createTheme } from "@material-ui/core";
import { ThemeProvider, styled } from "@material-ui/styles";
import WebHeader from "../../../blocks/landingpage/src/WebHeader.web";
import WebFooter from "../../../components/src/Footer.web";

const theme = createTheme({
  overrides: {
    MuiTypography: {
      h1: {
        color: "#0F172A",
        fontFamily: "Quattrocento",
        fontSize: "24px",
        fontWeight: 700,
        lineHeight: "32px",
      },
    },
  },
});
// Customizable Area End

import InvoiceBillingController, {
  Props,
  configJSON,
} from "./InvoiceBillingController.web";

export default class InvoiceBilling extends InvoiceBillingController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <ThemeProvider theme={theme}>
          <Wrapper>
            <WebHeader navigation={this.props.navigation} />
            <Container className="container">
                <Box className="invoiceBillingTitleWrpper">
                  <Typography
                    data-test-id="pageTitle"
                    variant="h1"
                    align="center"
                  >
                    {configJSON.pageTitle}
                  </Typography>
                </Box>
              <Box className="invoiceFileWrapper">
              <object data={`${this.state.invoicePdf}`} type="application/pdf" width="100%">
                <p>Failed to load pdf!</p>
              </object>
              </Box>
            </Container>
            <WebFooter handleNavigation={this.handleNavigation} />
          </Wrapper>
        </ThemeProvider>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const Wrapper = styled("div")(({ theme }) => ({
  "& .container": {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "50px 72px",
    flexDirection: "column",
  },
  "& .invoiceBillingTitleWrpper":{
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    textAlign: "center",
    marginBottom: "32px",
    width: "100%",
  },
  "& .invoiceFileWrapper": {
    marginBottom: "32px",
    display: "flex",
    justifyContent: "center",
    width: "100%",
    height: "100%",
    minHeight: "100vh",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
  },
  " & .toolbarButtonWithContainer .toolbarButton": {
    display: "none !important",
  },
  "& #toolbarContainer": {
    display: "none !important",
  },
  " & > .toolbarButton": {
    display: "none !important",
  },
}));
// Customizable Area End
