import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { sendAPIRequest } from "../../../components/src/Utils";
import { toast } from "react-toastify";
interface ImageDetails {
  file_id: number;
  file_name: string;
  content_type: string;
  file_size: number;
  url: string;
}

interface OrganisationDetail {
  id: number;
  headings: string;
  description: string;
  url: string;
}

interface SupportData {
  id: number;
  heading_1: string;
  heading_2: string;
  care_description_1: string;
  care_description_2: string;
  care_description_3: string;
  forword_description: string;
  care_image_1: ImageDetails;
  care_image_2: ImageDetails;
  care_image_3: ImageDetails;
  forword_image: ImageDetails;
  organisation_details: OrganisationDetail[];
}
interface BlogCategory {
  id: number;
  name: string;
}



interface BlogData {
  id: string;
  type: string;
  attributes: {
    id: number;
    title: string;
    post: string;
    banner_url: string | null;
    blog_category: BlogCategory;
    created_at: string;
    updated_at: string;
  };
}


interface BlogsResponse {
  data: BlogData[];
}


// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  supportData: SupportData;
  supportBlogData: BlogsResponse;
  showImageDataIndex:number
  // Customizable Area End
}

interface SS {
  id: string;
}

export default class SupportController extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      supportData: {
        id: 0,
        heading_1: "",
        heading_2: "",
        care_description_1: "",
        care_description_2: "",
        care_description_3: "",
        forword_description: "",
        care_image_1: {
          file_id: 0,
          file_name: "",
          content_type: "",
          file_size: 0,
          url: "",
        },
        care_image_2: {
          file_id: 0,
          file_name: "",
          content_type: "",
          file_size: 0,
          url: "",
        },
        care_image_3: {
          file_id: 0,
          file_name: "",
          content_type: "",
          file_size: 0,
          url: "",
        },
        forword_image: {
          file_id: 0,
          file_name: "",
          content_type: "",
          file_size: 0,
          url: "",
        },
        organisation_details: []
      },
      supportBlogData: {
        data:[]
      },
      showImageDataIndex:3
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJSON = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (apiRequestCallId === this.supportAPICallID) {
      if (responseJSON?.data) {
        this.handleSupportResponse(responseJSON)
      }
      else {
        toast.error(configJSON.somethingWW)
      }
    }
    if (apiRequestCallId === this.SupportBlogsAPICallID) {
      if (responseJSON?.data) {
        this.handleSupportBlogsResponse(responseJSON)
      }
      else {
        toast.error(configJSON.somethingWW)
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  supportAPICallID: string = "";
  SupportBlogsAPICallID: string = "";
  async componentDidMount() {
    super.componentDidMount();
    this.getSupportDataList();
    this.getSupportBlogsDataList();
  }


  getSupportDataList = () => {
    this.supportAPICallID = sendAPIRequest(
      configJSON.getSupportAPIEndPoint,
      {
        method: configJSON.validationApiMethodType,
      }
    );
  };
  getSupportBlogsDataList = () => {
    this.SupportBlogsAPICallID = sendAPIRequest(
      configJSON.getSupportBlogsAPIEndPoint,
      {
        method: configJSON.validationGetApiMethodType,
      }
    );
  };



  handleSupportResponse = (
    responseJSON: Record<string, unknown>
  ) => {
    console.log("responseJSON==>", responseJSON)
    const response = responseJSON as {
      data: { attributes: SupportData }
    }
    if (response?.data) {
      this.setState({ supportData: response.data?.attributes });
    }
  };
  handleSupportBlogsResponse = (
    responseJSON: any
  ) => {
    this.setState({ supportBlogData: responseJSON });
    console.log("supportBlogData==>",this.state.supportBlogData)
  };

  handleNavigation = (route: string) => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), route);
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  };
  handleViewMore=()=>{
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "BlogList");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }
  handleCardClick =(id:any)=>{
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), `BlogDetails/${id}`);
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }
  // Customizable Area End
}
