import React from "react";
// Customizable Area Start
import {
    Box,
    Grid,
    Typography,
} from "@material-ui/core";
import { createTheme, styled, ThemeProvider } from "@material-ui/core/styles";

import "react-datepicker/dist/react-datepicker.css";
import 'react-phone-input-2/lib/style.css'
import WebHeader from "../../../blocks/landingpage/src/WebHeader.web";
import BreadcrumbStepper from "../../../components/src/BreadcrumbStepper.web";
import WebFooter from "../../../components/src/Footer.web";
import TransactionHistoryController, {
    Props,
    configJSON,
} from "./TransactionHistoryController.web";



const theme = createTheme({
    palette: {
        primary: {
            main: "#0000ff",
            contrastText: "#fff"
        }
    },
}
);
// Customizable Area End

export default class TransactionHistory extends TransactionHistoryController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start


    // Customizable Area End

    render() {
        // Customizable Area Start
        const {
            stepsTransactionHistory,
        } = this.state;
        return (
            <>
                <ThemeProvider theme={theme}>
                    <Wrapper>
                        <WebHeader navigation={this.props.navigation} />
                        <Box className="containerBoxs">
                            <Box>
                                <BreadcrumbStepper data-test-id="breadcrumbStepper" steps={stepsTransactionHistory} onStepClick={(path) => this.handleNavigation(path)} />
                            </Box>
                        </Box>
                        <Box className="containerBoxsss">
                            {this.state.transactionHistory.map((transaction: any) => (
                                <React.Fragment key={transaction.id}>
                                    <Box
                                        display="flex"
                                        flexDirection="column"
                                        mt={2}
                                        className="messageBox"
                                    >
                                        <Box mb={1}>
                                            <Typography className="messageTextPaymentType">
                                                {`Payment Type : ${transaction.attributes.payment_type === "subscription"
                                                        ? "Subscription Re-new"
                                                        : "Online Purchase"
                                                    }`}
                                            </Typography>
                                        </Box>

                                        <Box>
                                            <Box
                                                display="flex"
                                                flexDirection={{ xs: "column", sm: "row" }}
                                                alignItems="center"
                                                justifyContent="space-between"
                                                flexWrap="wrap"
                                                mb={2}
                                            >
                                                <Box display="flex" alignItems="center" mb={{ xs: 1, sm: 0 }} >
                                                    <Typography className="messageTextPaymentPlan">
                                                        {transaction.attributes.payment_type === "subscription"
                                                            ? "Plan :"
                                                            : "Item :"}
                                                    </Typography>
                                                    <Typography
                                                        className="messagePaymentPlanBasic"
                                                        style={{ marginLeft: "8px" }}
                                                    >
                                                        {transaction.attributes.payable_name}
                                                    </Typography>
                                                </Box>

                                                <Box display="flex" alignItems="center" mb={{ xs: 1, sm: 0 }} mx={2}>
                                                    <Typography className="messageTextPaymentPlan">
                                                        {configJSON.planPaymentDate}
                                                    </Typography>
                                                    <Typography
                                                        className="messagePaymentPlanBasic"
                                                        style={{ marginLeft: "8px" }}
                                                    >
                                                        {new Date(transaction.attributes.payment_date).toLocaleDateString()}
                                                    </Typography>
                                                </Box>

                                                <Box display="flex" alignItems="center" mb={{ xs: 1, sm: 0 }} mx={2}>
                                                    <Typography className="messageTextPaymentPlan">
                                                        {configJSON.planAmountPaid}
                                                    </Typography>
                                                    <Typography
                                                        className="messagePaymentPlanBasic"
                                                        style={{ marginLeft: "8px" }}
                                                    >
                                                        ${transaction.attributes.amount.toFixed(2)}
                                                    </Typography>
                                                </Box>

                                                <Box display="flex" alignItems="center" mx={2}>
                                                    <Typography className="messageTextPaymentPlan">
                                                        {configJSON.planTransactionID}
                                                    </Typography>
                                                    <Typography
                                                        className="messagePaymentPlanBasic"
                                                        style={{ marginLeft: "8px" }}
                                                    >
                                                        {transaction.attributes.transaction_id}
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Box>

                                    <Box className="pageTitlesCardThre" />
                                </React.Fragment>
                            ))}
                        </Box>






                        <WebFooter handleNavigation={this.handleNavigation} />
                    </Wrapper>
                </ThemeProvider>
            </>
            //Merge Engine End DefaultContainer
        );
        // Customizable Area End
    }
}

// Customizable Area Start
const Wrapper = styled('div')(({ theme }) => ({
    "& .rightPanelWrapper": {
        maxHeight: "100vh",
        overflowY: "auto",
        boxSizing: "border-box",
    },
    "& .container": {
        minHeight: '100vh',
    },
    "& .containerBoxs": {
        padding: "50px 72px",
        [theme.breakpoints.down('md')]: {
            padding: "40px 24px",
        },
        [theme.breakpoints.down('sm')]: {
            padding: "20px 16px",
        },
    },
    "& .containerBoxsss": {
        padding: "20px 72px",
        marginBottom: "50px",
        [theme.breakpoints.down('md')]: {
            padding: "40px 24px",
        },
        [theme.breakpoints.down('sm')]: {
            padding: "20px 16px",
        },
    },
 
    "& .messageTextPaymentType": {
        fontSize: "18px",
        fontFamily: "Lato",
        color: "#9176C4",
        fontWeight: 600,
        lineHeight: "28px",
        [theme.breakpoints.down('sm')]: {
            fontSize: "18px",
            lineHeight: "24px",
        },
    },
    "& .messageTextPaymentPlan": {
        fontSize: "18px",
        fontFamily: "Lato",
        fontWeight: 500,
        lineHeight: "28px",
        marginTop: "24px",
        color: "#94A3B8",
        [theme.breakpoints.down('sm')]: {
            fontSize: "18px",
            marginTop: "16px",
        },
    },
    " & .messagePaymentPlanBasic ": {
        fontSize: "18px",
        fontFamily: "Lato",
        fontWeight: 500,
        lineHeight: "28px",
        marginTop: "24px",
        color: "#0F172A",
        [theme.breakpoints.down('sm')]: {
            fontSize: "18px",
            marginTop: "16px",
        },
    },

    "& .containPaymentPlan": {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        [theme.breakpoints.down('sm')]: {
            flexDirection: "column",
        },
    },
    "& .pageTitlesCardThre": {
        display: "flex",
        flexDirection: "column",
        marginTop: '14px',
        borderBottom: '2px dotted rgb(71, 85, 105)',
    },
}));


// Customizable Area End
