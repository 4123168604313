import React from "react";
// Customizable Area Start
import {
    Box,
    Button,
    Typography,
    styled,
    Drawer,
    IconButton,
    Avatar,
    createTheme,
    ThemeProvider,
    AppBar,
    Menu,
    MenuItem,
    withStyles,
  } from "@material-ui/core";
  import MenuIcon from "@material-ui/icons/Menu";
  import { logo } from "../../../blocks/signuplogin/src/assets";
  import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
  import LinearProgress from '@material-ui/core/LinearProgress';

  const BorderLinearProgress = withStyles(() => ({
    root: {
      height: 5,
      borderRadius: 5,
    },
    colorPrimary: {
      backgroundColor: "rgba(226, 232, 240, 1)",
    },
    bar: {
      borderRadius: 5,
      backgroundColor: 'rgb(172, 204, 134)',
    },
  }))(LinearProgress);

  const theme = createTheme({
    overrides: {
      MuiTypography: {
        h1: {
          color: "#0F172A",
          fontFamily: "Quattrocento",
          fontSize: "24px",
          fontWeight: 700,
          lineHeight: "32px",
        },
        h4: {
            fontFamily: "Lato",
            fontSize: "18px",
            fontWeight: 500,
            lineHeight: "26px",
        },
        h5: {
          fontFamily: "Lato",
          fontSize: "16px",
          fontWeight: 600,
          lineHeight: "24px",
        },
        h6: {
          marginBottom: "20px",
          color: "#64748B",
          fontFamily: "Lato",
          fontSize: '16px',
          fontWeight: 400,
        },
        subtitle2: {
          fontFamily: "Lato",
          fontSize: "16px",
          fontWeight: 500,
          lineHeight: "22px",
        },
      },
      MuiButton: {
        contained: {
          backgroundColor: "#A190C2",
          color: "#fff",
          textTransform: "none",
          fontFamily: "Lato",
          fontSize: '16px',
          fontWeight: 600,
          lineHeight: "24px",
        },
        text: { 
            color: "#9176C4", 
            textTransform: "none", 
            fontFamily: "Lato", 
            fontSize: '16px', 
            fontWeight: 600,   
            lineHeight: "24px",
        },
      },
      MuiMenuItem: {
        root: {
            height: "54px",
        },
      },
      MuiIconButton: {
        root: {
            padding: 0,
        }
      }
    },
});

const profileDropDownMenu = [
    {
        id: "1",
        name: "My Profile",
        path: "MyProfile",
        icon: () => (
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12.12 12.7805C12.05 12.7705 11.96 12.7705 11.88 12.7805C10.12 12.7205 8.71997 11.2805 8.71997 9.51047C8.71997 7.70047 10.18 6.23047 12 6.23047C13.81 6.23047 15.28 7.70047 15.28 9.51047C15.27 11.2805 13.88 12.7205 12.12 12.7805Z" stroke="#0F172A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M18.74 19.3805C16.96 21.0105 14.6 22.0005 12 22.0005C9.40001 22.0005 7.04001 21.0105 5.26001 19.3805C5.36001 18.4405 5.96001 17.5205 7.03001 16.8005C9.77001 14.9805 14.25 14.9805 16.97 16.8005C18.04 17.5205 18.64 18.4405 18.74 19.3805Z" stroke="#0F172A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="#0F172A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
        ),
    },
    {
        id: "2",
        name: "Delegated Members",
        path: "DelegateMembersEdit",
        icon: () => (
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9.16006 10.87C9.06006 10.86 8.94006 10.86 8.83006 10.87C6.45006 10.79 4.56006 8.84 4.56006 6.44C4.56006 3.99 6.54006 2 9.00006 2C11.4501 2 13.4401 3.99 13.4401 6.44C13.4301 8.84 11.5401 10.79 9.16006 10.87Z" stroke="#0F172A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M16.41 4C18.35 4 19.91 5.57 19.91 7.5C19.91 9.39 18.41 10.93 16.54 11C16.46 10.99 16.37 10.99 16.28 11" stroke="#0F172A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M4.15997 14.56C1.73997 16.18 1.73997 18.82 4.15997 20.43C6.90997 22.27 11.42 22.27 14.17 20.43C16.59 18.81 16.59 16.17 14.17 14.56C11.43 12.73 6.91997 12.73 4.15997 14.56Z" stroke="#0F172A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M18.3401 20C19.0601 19.85 19.7401 19.56 20.3001 19.13C21.8601 17.96 21.8601 16.03 20.3001 14.86C19.7501 14.44 19.0801 14.16 18.3701 14" stroke="#0F172A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
        ),
    },
    {
        id: "3",
        name: "Subscription",
        path: "Subscription",
        icon: () => (
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M2 12.6094H19" stroke="#0F172A" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M19 10.2808V17.4308C18.97 20.2808 18.19 21.0008 15.22 21.0008H5.78003C2.76003 21.0008 2 20.2508 2 17.2708V10.2808C2 7.58078 2.63 6.71078 5 6.57078C5.24 6.56078 5.50003 6.55078 5.78003 6.55078H15.22C18.24 6.55078 19 7.30078 19 10.2808Z" stroke="#0F172A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M22 6.73V13.72C22 16.42 21.37 17.29 19 17.43V10.28C19 7.3 18.24 6.55 15.22 6.55H5.78003C5.50003 6.55 5.24 6.56 5 6.57C5.03 3.72 5.81003 3 8.78003 3H18.22C21.24 3 22 3.75 22 6.73Z" stroke="#0F172A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M5.25 17.8105H6.96997" stroke="#0F172A" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M9.10986 17.8105H12.5499" stroke="#0F172A" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
        ),
    },
    {
        id: "4",
        name: "Transaction History",
        path: "TransactionHistory",
        icon: () => (
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M22 6V8.42C22 10 21 11 19.42 11H16V4.01C16 2.9 16.91 2 18.02 2C19.11 2.01 20.11 2.45 20.83 3.17C21.55 3.9 22 4.9 22 6Z" stroke="#0F172A" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M2 7V21C2 21.83 2.93998 22.3 3.59998 21.8L5.31 20.52C5.71 20.22 6.27 20.26 6.63 20.62L8.28998 22.29C8.67998 22.68 9.32002 22.68 9.71002 22.29L11.39 20.61C11.74 20.26 12.3 20.22 12.69 20.52L14.4 21.8C15.06 22.29 16 21.82 16 21V4C16 2.9 16.9 2 18 2H7H6C3 2 2 3.79 2 6V7Z" stroke="#0F172A" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M6 9H12" stroke="#0F172A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M6.75 13H11.25" stroke="#0F172A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
        ),
    },
    {
        id: "5",
        name: "My Orders",
        path: "MyOrders",
        icon: () => (
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M7.5 7.66952V6.69952C7.5 4.44952 9.31 2.23952 11.56 2.02952C14.24 1.76952 16.5 3.87952 16.5 6.50952V7.88952" stroke="#0F172A" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M9.00007 22H15.0001C19.0201 22 19.7401 20.39 19.9501 18.43L20.7001 12.43C20.9701 9.99 20.2701 8 16.0001 8H8.00007C3.73007 8 3.03007 9.99 3.30007 12.43L4.05007 18.43C4.26007 20.39 4.98007 22 9.00007 22Z" stroke="#0F172A" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M15.4955 12H15.5045" stroke="#0F172A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M8.49451 12H8.50349" stroke="#0F172A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
        ),
    },
    {
        id: "6",
        name: "My Locations",
        path: "MyLocation",
        icon: () => (
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M11.9999 13.4295C13.723 13.4295 15.1199 12.0326 15.1199 10.3095C15.1199 8.58633 13.723 7.18945 11.9999 7.18945C10.2768 7.18945 8.87988 8.58633 8.87988 10.3095C8.87988 12.0326 10.2768 13.4295 11.9999 13.4295Z" stroke="#0F172A" stroke-width="1.5" />
                <path d="M3.61995 8.49C5.58995 -0.169998 18.42 -0.159997 20.38 8.5C21.53 13.58 18.37 17.88 15.6 20.54C13.59 22.48 10.41 22.48 8.38995 20.54C5.62995 17.88 2.46995 13.57 3.61995 8.49Z" stroke="#0F172A" stroke-width="1.5" />
            </svg>
        ),
    },
    {
        id: "7",
        name: "Logout",
        path: "Logout",
        icon: () => (
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8.8999 7.56023C9.2099 3.96023 11.0599 2.49023 15.1099 2.49023H15.2399C19.7099 2.49023 21.4999 4.28023 21.4999 8.75023V15.2702C21.4999 19.7402 19.7099 21.5302 15.2399 21.5302H15.1099C11.0899 21.5302 9.2399 20.0802 8.9099 16.5402" stroke="#0F172A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M15.0001 12H3.62012" stroke="#0F172A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M5.85 8.65039L2.5 12.0004L5.85 15.3504" stroke="#0F172A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
        ),
    },
    {
        id: "8",
        name: "Delete Account",
        path: "DeleteAccount",
        icon: () => (
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M21 5.98047C17.67 5.65047 14.32 5.48047 10.98 5.48047C9 5.48047 7.02 5.58047 5.04 5.78047L3 5.98047" stroke="#DC2626" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M8.5 4.97L8.72 3.66C8.88 2.71 9 2 10.69 2H13.31C15 2 15.13 2.75 15.28 3.67L15.5 4.97" stroke="#DC2626" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M18.8499 9.14062L18.1999 19.2106C18.0899 20.7806 17.9999 22.0006 15.2099 22.0006H8.7899C5.9999 22.0006 5.9099 20.7806 5.7999 19.2106L5.1499 9.14062" stroke="#DC2626" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M10.3301 16.5H13.6601" stroke="#DC2626" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M9.5 12.5H14.5" stroke="#DC2626" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
        ),
    },
];

import WebHeaderController, {
    configJSON,
} from "./WebHeaderController.web";
import GenericModal from "../../../components/src/GenericModal";
import { DialogWrapper } from "../../../blocks/customform/src/LegalInformation.web";
// Customizable Area End

export default class WebHeader extends WebHeaderController {
  constructor(props: any) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  };

  // Customizable Area Start
  // Customizable Area End

  render() {
    const {
      drawerOpen,
      isAuthenticated,
      profileData,
      anchorEl,
      navMenu,
    } = this.state;

    return (
      <>
      <ThemeProvider theme={theme}>
        <AppBar position="sticky" elevation={0} className="headerWrapper">
        <Main>
          <LeftContainer>
            <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
                <IconButton onClick={() => this.toggleDrawer()}>
                    <MenuIcon />
                </IconButton>
            </Box>
            <img className="logoImg" src={logo} alt="logo" onClick={() => this.handleNavigation(navMenu[0].path)}/>
          </LeftContainer>
            <Box sx={{ display: { xs: 'none', md: 'flex' }}}>
              <Menus>
                {navMenu.map((item: any) => (
                  <NavMenuItem key={item.id} onClick={() => this.handleMenuClick(item.path)} data-test-id={`handleMenuClick${item.id}`}>
                    <Typography
                      variant="h5"
                      className={this.activeTab(item.path) ? "activeMenuText" : "menuText"}
                      onClick={() => this.handleNavigation(item.path)}
                    >
                      {item.name}
                    </Typography>
                  </NavMenuItem>
                ))}
              </Menus>
            </Box>
            <Box>
              {isAuthenticated ? (
                <LoggedInSection>
                  <RightContent>
                    <Typography data-test-id="reportADeath" onClick={() => this.handleNavigation("ReportDeath")} variant="h5" className="textBtn">Report a death</Typography>
                  </RightContent>
                  <Box 
                    data-test-id="profileWrapper"
                    className="profileWrapper" 
                    onClick={(event) => this.handleProfileClick(event)}>
                  <Avatar
                    style={{ cursor: "pointer" }}
                    src={profileData?.photo_url}
                    onClick={() => this.handleNavigation("Customisableuserprofiles")}
                  />
                  <Typography variant="h4" style={{color: "#0F172A"}} data-test-id="userDetail">
                    {profileData?.full_name ? profileData?.full_name : profileData?.email}
                  </Typography>
                  <ExpandMoreIcon style={{ color: "#292D32" }}/>
                  </Box>
                    <Menu
                        data-test-id="dropdownMenu"
                        elevation={0}
                        getContentAnchorEl={null}
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={() => this.handleDropdownMenuClose()}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                          }}
                          transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                          }}
                          PaperProps={{
                            style: {
                              borderRadius: '10px',
                              padding: '10px',
                              boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
                            },
                          }}
                    >
                        { profileDropDownMenu.map((item: any) => (
                            <MenuItem
                                data-test-id="dropdownMenuItem"
                                key={item.name} 
                                onClick={() => this.handleMenuItemClick(item.path)}>
                                <Box display="flex" alignItems="center" gridGap={8}>
                                    <IconButton>{item.icon()}</IconButton>
                                    <Typography
                                        data-test-id="menuItemLabel"
                                        align="center" 
                                        variant="subtitle2"
                                        style={{ color:  item.name === "Delete Account" ? "#DC2626" : "#0F172A" }}
                                    >
                                        {item.name}
                                    </Typography>
                                </Box>
                            </MenuItem>
                        ))}
                    </Menu>
                </LoggedInSection>
              ) : (
                <Buttons>
                  <RightContent>
                    <Typography data-test-id="reportDeath" onClick={() => this.handleNavigation("ReportDeath")} variant="h5" className="textBtn"> Report a death</Typography>
                  </RightContent>
                  <Button 
                    className="loginTextBtn" 
                    variant="text" 
                    onClick={() => this.handleNavigation("Signuplogin")}
                    data-test-id="loginBtn"
                   >
                    {configJSON.loginBtnText}
                  </Button>
                  <Button
                    variant="contained"
                    className="signBtn"
                    onClick={() => this.handleNavigation("Signup")}
                  >
                    {configJSON.signupBtnText}
                  </Button>
                </Buttons>
              )}
            </Box>
            <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
                <Drawer anchor="left" open={drawerOpen} onClose={() => this.toggleDrawer()}>
              <DrawerContent>
                {navMenu.map((item: any) => (
                  <NavMenuItem key={item.id} onClick={() => this.handleMenuClick(item.path)}>
                    <Typography
                      variant="h5"
                      style={{ textDecoration: "none" }}
                      className={this.activeTab(item.path) ? "activeMenuText" : "menuText"}
                      onClick={() => this.handleNavigation(item.path)}
                    >
                      {item.name}
                    </Typography>
                  </NavMenuItem>
                ))}
              </DrawerContent>
            </Drawer>
            </Box>
        </Main>
        { this.props.progress && (
          <BorderLinearProgress variant="determinate" value={this.props.progress} />
          )}
        </AppBar>
      </ThemeProvider>
      <GenericModal open={this.state.openDialog} data-test-id="confirmDelete" onClose={this.handleClose}>
                    <ThemeProvider theme={theme}>
                        <DialogWrapper>
                            <Box className="alignCenter">
                                <Typography align="center" variant="h1" data-test-id="passwordUpdatedMsg">Delete Account?</Typography>
                                <Typography align="center" variant="h6" style={{ color: "#64748B", fontWeight: 400 }}>
                                    Are you sure you want to delete your account permanently
                                </Typography>
                            </Box>
                            <Box className="btnWrapper">
                                <Button
                                    fullWidth
                                    style={{ width: "100%",  backgroundColor: "#DC2626"}}
                                    className="containedBtn"
                                    variant="contained"
                                    data-test-id="deleteBtn"
                                    onClick={() => this.handleDeleteBtnClick()}
                                >
                                    Delete
                                </Button>
                            </Box>
                        </DialogWrapper>
                    </ThemeProvider>
                </GenericModal>
                <GenericModal open={this.state.logoutDialog} data-test-id="confirmLogout" onClose={this.handleCancel}>
                    <ThemeProvider theme={theme}>
                        <DialogWrapper>
                            <Box className="alignCenter">
                                <Typography align="center" variant="h1" >{configJSON.logout}?</Typography>
                                <Typography align="center" variant="h6" style={{ color: "#64748B", fontWeight: 400, fontSize: '18px' }}>
                                    {configJSON.logoutDes}
                                </Typography>
                            </Box>
                            <Box className="dialogBtnsWrapper">
                            <Button
                                    fullWidth
                                    variant="outlined"
                                    className="outlinedBtn"
                                    onClick={this.handleCancel}
                                >
                                    {configJSON.cancel}
                                </Button>
                                <Button
                                    fullWidth
                                    className="containedBtn"
                                    data-test-id="logoutBtn"
                                    variant="contained"
                                    onClick={() => this.logout()}
                                >
                                    {configJSON.logout}
                                </Button>
                            </Box>
                        </DialogWrapper>
                    </ThemeProvider>
                </GenericModal>
      </>
    );
  }
};
  
  const Main = styled(Box)({
    display: "flex",
    justifyContent: "space-between",
    backgroundColor: "white",
    height: "92px",
    borderBottom: "1px solid #00000014",
    alignItems: "center",
    padding: "0px 72px",
    boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
    "@media (max-width:900px)": {
      padding: "0px 16px",
    },
    "& .loginTextBtn": {
      width: "70px",
      height: "33px",
      padding: "7px 12px",
      borderLeft:"1px solid #9176C4",
      borderRadius: "0px",
      color: "#9176C4",
      "@media (max-width:500px)": {
        width: "40px",
        height: "33px",
        fontSize: "14px",
      },
    },
    "& .signBtn": {
      width: "120px",
      height: "44px",
      borderRadius: "100px",
      "@media (max-width:500px)": {
        width: "90px",
        height: "38px",
        fontSize: "12px",
      },
    },
  });
  
  const LeftContainer = styled(Box)({
    display: "flex",
    alignItems: "center",
    gap: "16px",
    "& .logoImg": {
      width: "77px",
      height: "42px",
    },
    "@media (max-width:500px)": {
        "& .logoImg": {
            width: "50px",
            height: "27px",
          },
      },
  });
  
  const Menus = styled(Box)({
    display: "flex", 
    gap: "32px",
  });
  
  const NavMenuItem = styled(Box)({
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    gap: "6px",
    "& .menuText": {
      color: "#334155",
      textDecoration: "none",
      fontWeight: "400 !important",
    },
    "& .activeMenuText": {
      color: "#9176C4",
      textDecoration: "underline",
      textUnderlinePosition: "under",
    },
  });
  
  const Buttons = styled(Box)({
    display: "flex",
    alignItems: "center",
    gap: "20px",
    "@media (max-width:500px)": {
        gap: "8px",
    }
  });
  
  const DrawerContent = styled(Box)({
    width: "250px",
    padding: "20px",
    display: "flex",
    flexDirection: "column",
    gap: "20px",
  });
  
  const LoggedInSection = styled("div")({
    display: "flex",
    gap: 24,
    alignItems: "center",
    "& .profileWrapper": {
        width: "239px",
        display: "flex",
        gap: "8px",
        justifyContent: "center",
        alignItems: "center",
        borderLeft: "1px solid #9176C4",
        paddingLeft: "12px",
    },
    "@media (max-width:500px)": {
        gap: "5px !important",
    },
  });

  const RightContent =styled(Box)({
    "& .textBtn": {
      color: "#0F172A",
      cursor: "pointer"
    },
    "@media (max-width:500px)": {
        fontSize: "12px !important",
    }
  });