// Customizable Area Start
import React from "react";
import BlogDetailsController, { configJSON, Props } from "./BlogDetailsController.web";
import WebHeader from "../../landingpage/src/WebHeader.web";
import WebFooter from "../../../components/src/Footer.web";
import { styled, ThemeProvider, createTheme } from "@material-ui/core/styles";
import { Avatar, Box, Button, Grid, Typography } from "@material-ui/core";
import BreadcrumbStepper from "../../../components/src/BreadcrumbStepper.web";
import moment from "moment";
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

export const theme = createTheme({
  overrides: {
    MuiTypography: {
      h1: {
        fontFamily: "Lato",
        lineHeight: "63px",
        fontWeight: 800,
        fontSize: "48px",
        color: "#0F172A"
      },
      h2: {
        fontFamily: "Open sans",
        fontWeight: 700,
        fontSize: "24px",
        lineHeight: "28.8px",
        color: "#000000",
      },
      h3: {
        fontFamily: "Lato",
        fontSize: "18px",
        color: "#94A3B8",
        lineHeight: "26px",
        fontWeight: 600,
        margin: "20px 12px"
      },
      h4: {
        fontSize: "16px",
        fontFamily: "Roboto",
        lineHeight: "24px",
        color: "#000000",
        fontWeight: 700,
        display: '-webkit-box',
        WebkitLineClamp: 2,
        WebkitBoxOrient: 'vertical',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
      h5: {
        fontFamily: "Work sans",
        fontSize: "24px",
        fontWeight: 600,
        lineHeight: "28px",
        color: "#181A2A",
      },
      h6: {
        color: "#1E293B",
        fontSize: "14px",
        lineHeight: "20px",
        fontWeight: 500,
        fontFamily: "Work sans",
        marginLeft: "10px"
      },
      subtitle1: {
        color: "#000000",
        fontWeight: 400,
        lineHeight: "24.6px",
        fontFamily: "Open sans",
        fontSize: "16px",
      },
      subtitle2: {
        fontSize: '14px',
        fontFamily: "Roboto",
        fontWeight: 600,
        color: "#000000",
        lineHeight: "21px"
      },
    },
    MuiButton: {
      root: {
        textTransform: "none",
        fontSize: '16px',
        fontFamily: "Lato",
        fontWeight: 600
      },
      contained: {
        backgroundColor: "#F1FDE3",
        padding: "6px 12px",
        color: "#79B432",
        fontSize: "14px",
        lineHeight: "22px",
        boxShadow: "none",
        marginRight: "10px"
      },
      text: {
        color: "#9176C4",
        fontWeight: 500,
        lineHeight: "24px",
        width: "auto",
        height: "auto",
        padding: 0,
        fontSize: '14px',
        textDecoration: 'underline',
        textUnderlineOffset: '4px',
      }
    },
  },
});

export default class BlogDetails extends BlogDetailsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    const { blogDetail, relatedBlog } = this.state
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <BlogDetailsWrapper>
          <WebHeader navigation={this.props.navigation} />
          <Grid container className="detailsContainer" direction="column">
            <BreadcrumbStepper isMap={true} steps={this.state.detailsBreadCrumb} onStepClick={(path) => this.handleNavigation(path)} />
            {blogDetail && <>
              <Box className="blogsInnerContainer">
                <Button variant="contained">
                  {blogDetail.attributes.blog_category.name}
                </Button>
                <Typography variant="subtitle2">
                  {blogDetail.attributes.reading_time}
                </Typography>
              </Box>
              <Typography variant="h1">
                {blogDetail.attributes.title}
              </Typography>
              <img src={blogDetail?.attributes?.banner_url} className="detailsImage" />
              <Box className="blogsInnerContainer">
                {blogDetail.attributes.author_name && <>
                  <Avatar className="authorAvatar" src={blogDetail.attributes.author_image ?? ""} alt={blogDetail.attributes.author_name} />
                </>}
                <Typography variant="h5">
                  {blogDetail.attributes.author_name}
                  <br />
                  <span className="publishDate">
                    {moment(blogDetail.attributes.created_at).format("DD MMM YYYY")}
                  </span>
                </Typography>
              </Box>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={8} className="reportContainer" style={{ paddingLeft: '12px' }}>
                  <Typography variant="subtitle1"
                    dangerouslySetInnerHTML={{ __html: blogDetail.attributes.post }}
                    className="postDetails"
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={4} className="reportContainer" style={{ paddingRight: '12px' }}>
                  <Typography variant="h2">
                    {configJSON.relatedBlogs}
                  </Typography>
                  {relatedBlog.length > 0 ?
                    relatedBlog.map((_data) => (
                      <Grid spacing={2} className="blogList" container>
                        <Grid item xs={3} md={4}>
                          <img src={_data?.attributes?.banner_url} className="blogsImage" />
                        </Grid>
                        <Grid item xs={9} md={8}>
                          <Box className="relatedBlogDetails">
                            <Button className="categoryButton">
                              {_data.attributes.blog_category.name}
                            </Button>
                            <Typography variant="subtitle2" style={{ fontSize: "12px", lineHeight: "18px" }}>
                              {_data.attributes.reading_time}
                            </Typography>
                          </Box>
                          <Box className="relatedBlogDetails">
                            {_data.attributes.author_name && <>
                              <Avatar style={{ width: "28px", height: "28px" }} src={_data.attributes.author_image ?? ""} alt={_data.attributes.author_name} />
                              <Typography variant="h6">
                                {_data.attributes.author_name}
                              </Typography>
                            </>}
                            <span className="eclipse" />
                            <Typography className="publishDate" style={{ fontSize: '12px' }}>
                              {moment(_data.attributes.created_at).format("MMM DD,YYYY")}
                            </Typography>
                          </Box>
                          <Box className="relatedBlogDetails" display="flex" flexDirection="column" style={{ alignItems: "flex-start" }}>
                            <Typography variant="h4">
                              {_data.attributes.title}
                            </Typography>
                          </Box>
                          <Grid container alignItems="center">
                            <Button variant="text" data-test-id="blogDetailsPage" onClick={() => this.handleNavigation("BlogDetails", _data.attributes.id)}>
                              {configJSON.readMore}
                            </Button>
                            <ChevronRightIcon className="readMoreIcons" style={{
                              color: "#9176C4"
                            }} />
                          </Grid>
                        </Grid>
                      </Grid>
                    )) :
                    <Typography variant="h3">
                      {configJSON.noFound}
                    </Typography>
                  }
                </Grid>

              </Grid>
            </>
            }
          </Grid>
          <WebFooter handleNavigation={this.handleNavigation} />
        </BlogDetailsWrapper>
      </ThemeProvider>

      // Customizable Area End
    );
  }
}
export const BlogDetailsWrapper = styled("div")(({ theme }) => ({
  "& .detailsContainer": {
    padding: '56px 46px'
  },
  "& .blogsInnerContainer": {
    display: "flex",
    alignItems: "center",
    padding: "15px 0"
  },
  "& .detailsImage": {
    width: "100%",
    aspectRatio: 2.94 / 1,
    borderRadius: "8px",
    margin: "35px 0 20px"
  },
  "& .publishDate": {
    color: "#696A75",
    fontSize: "16px",
    lineHeight: "20px",
    fontWeight: 400,
    fontFamily: "Work sans"
  },
  "& .reportContainer": {
    padding: "24px",
  },
  "& .blogsImage": {
    width: '100%',
    aspectRatio: 1.16,
    borderRadius: "8px"
  },
  readMoreIcons: {
    fontSize: '16px',
    marginLeft: 10
  },
  "& .blogList": {
    padding: "20px 0px"
  },
  "& .categoryButton": {
    fontSize: "12px",
    padding: "4px 8px",
    color: "#79B432",
    lineHeight: "18px",
    backgroundColor: "#F1FDE3",
    marginRight: "7px",
    textDecoration: "none"
  },
  "& .relatedBlogDetails": {
    display: "flex",
    alignItems: "center",
    padding: '3px 0'
  },
  "& .eclipse": {
    width: "4px",
    height: "4px",
    backgroundColor: "#CEC3E3",
    borderRadius: "50%",
    margin: '0 5px'
  },
  "& .authorAvatar": {
    width: "64px",
    height: "64px",
    marginRight: "10px"
  }
}))
// Customizable Area End
