import React from "react";
// Customizable Area Start
import AboutAchievementsController, {
    configJSON,
    Props,
    IHaveAchievement
} from "./AboutAchievementsController.web";
import {
    Box,
    Grid,
    Typography,
    Button,
    FormLabel,
    RadioGroup,
    FormControlLabel,
    styled,
} from "@material-ui/core";
import { Field, FieldArray, Form, Formik } from "formik";
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import { StyledRadio } from "./LegalInformation.web";
import CustomInput from "../../../components/src/CustomInput";
import ErrorMessage from "../../../components/src/ErrorMessage";
import MediaUpload from "../../../components/src/MediaUpload.web";
import MyLocationTwoToneIcon from '@material-ui/icons/MyLocationTwoTone';

const styledMyLocationTwoToneIcon = styled(MyLocationTwoToneIcon)({
    fill: "#A190C2",
});

// Customizable Area End

export default class AboutAchievements extends AboutAchievementsController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        // Customizable Area Start
        const {
            achievement,
        } = this.state;
        return (
            <Box className="formWrapper">
                <Formik
                    initialValues={achievement}
                    validationSchema={this.validationSchema}
                    onSubmit={(values) => {
                        this.handleAboutAchievementsInformationsFormSubmit(values)
                    }}
                    enableReinitialize={true}
                    data-test-id="AboutAchievementsForm"
                >
                    {({ values, setFieldValue }) => (
                        <Form>
                            <Box className="formSectionBackground">
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <FormLabel component="label" className="formRadioLabel">
                                            {configJSON.wouldYouLikeToAddAchievements}
                                        </FormLabel>
                                        <RadioGroup
                                            data-test-id="haveAchievements"
                                            aria-label="have_legal_representative"
                                            name={configJSON.name}
                                            value={values.have_achievement}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                setFieldValue("have_achievement", e.target.value);
                                            }
                                            }
                                            className="radioGroup"
                                        >
                                            <FormControlLabel
                                                value="1"
                                                control={<StyledRadio />}
                                                label="Yes"
                                                className="radioLabel"
                                            />
                                            <FormControlLabel
                                                value="0"
                                                control={<StyledRadio />}
                                                label="No"
                                                className="radioLabel"
                                            />
                                        </RadioGroup>
                                    </Grid>
                                </Grid>
                                {
                                    values.have_achievement === "1" && <FieldArray name="achievement_details_attributes" data-test-id="fieldArray">
                                        {({ remove, push }) => (
                                            values.achievement_details_attributes.map((_values: IHaveAchievement, index: number) => {
                                                return (
                                                    <Grid container spacing={3}>
                                                        <Grid item xs={12} className="fieldArrayHeaderWrapper">
                                                            <Typography data-test-id="fieldArrayTitle" variant="h6">{configJSON.achievements}</Typography>
                                                            {this.getAchievementList(index, values.achievement_details_attributes.length) ?
                                                                <Button
                                                                    className="addLettersBtn"
                                                                    data-test-id="addTattooBtn"
                                                                    onClick={() => push({
                                                                        name: "",
                                                                        loction: "",
                                                                        duration: "",
                                                                        files: []
                                                                    })}
                                                                >
                                                                    <AddCircleOutlineIcon className="circleOutlineIcon" />
                                                                    {configJSON.addMoreAchievements}
                                                                </Button>
                                                                :
                                                                <Button
                                                                    data-test-id="removeBtn"
                                                                    className="addLettersBtn"
                                                                    onClick={() => remove(index)}>
                                                                    <RemoveCircleOutlineIcon className="circleOutlineIcon" />
                                                                    {configJSON.removeBtnText}
                                                                </Button>}
                                                        </Grid>
                                                        <Grid item lg={4} xs={12} sm={6}>
                                                            <FormLabel component="label">
                                                                {configJSON.achievement}<span style={{ color: "red" }}>*</span>
                                                            </FormLabel>
                                                            <Grid item xs={12} >
                                                                <Field
                                                                    data-test-id="achievementName"
                                                                    className="inputField"
                                                                    name={configJSON.achievement}
                                                                    as={CustomInput}
                                                                    size="small"
                                                                    placeholder={configJSON.achievementPlaceholder}
                                                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                                        setFieldValue(`achievement_details_attributes.${index}.title`, e.target.value)
                                                                    }}
                                                                    value={_values.title}
                                                                />
                                                                <ErrorMessage name={`achievement_details_attributes.${index}.title`} />
                                                            </Grid>
                                                        </Grid>
                                                        <Grid item lg={4} xs={12} sm={6}>
                                                            <FormLabel component="label">
                                                                {configJSON.location}<span style={{ color: "red" }}>*</span>
                                                            </FormLabel>
                                                            <Grid item xs={12} >
                                                                <Field
                                                                    data-test-id="achievementLocation"
                                                                    className="inputField"
                                                                    name={configJSON.location}
                                                                    as={CustomInput}
                                                                    size="small"
                                                                    placeholder={configJSON.locationPlaceholder}
                                                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                                        setFieldValue(`achievement_details_attributes.${index}.loction`, e.target.value)
                                                                    }}
                                                                    startIcon={styledMyLocationTwoToneIcon}
                                                                    value={_values.loction}
                                                                />
                                                                <ErrorMessage name={`achievement_details_attributes.${index}.loction`} />
                                                            </Grid>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <FormLabel component="label">
                                                                {configJSON.achievementDescription}
                                                            </FormLabel>
                                                            <Grid item xs={12} >
                                                                <Field
                                                                    data-test-id="achievementDescription"
                                                                    className="multilineInput"
                                                                    name={configJSON.achievementDescription}
                                                                    as={CustomInput}
                                                                    size="small"
                                                                    multiline
                                                                    placeholder={configJSON.achievementDescriptionPlaceholder}
                                                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                                        setFieldValue(`achievement_details_attributes.${index}.description`, e.target.value)
                                                                    }}
                                                                    value={_values.description}
                                                                />
                                                                <ErrorMessage name={`achievement_details_attributes.${index}.description`} />
                                                            </Grid>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <MediaUpload
                                                                data-test-id="mediaUploadForAchievement"
                                                                files={_values.files}
                                                                onUpload={(event: React.ChangeEvent<HTMLInputElement>) => this.handleAchievementFile(event, setFieldValue, index, _values.files)}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                )
                                            })
                                        )}
                                    </FieldArray>
                                }
                            </Box>
                            <Box className="btnWrapper">
                                <Button
                                    variant="outlined"
                                    className="btnSize"
                                    onClick={() => this.props.handleNext("CustomForm")}
                                    data-test-id="skipForAchievement"
                                >
                                    {configJSON.skipForNowBtnText}
                                </Button>
                                <Button
                                    variant="contained"
                                    data-test-id="saveAchievement"
                                    type="submit"
                                    className="btnSize"
                                >
                                    {configJSON.saveAndNext}
                                </Button>
                            </Box>
                        </Form>
                    )}
                </Formik>
            </Box>
        );
        // Customizable Area End
    }
}


// Customizable Area Start
// Customizable Area End