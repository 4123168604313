import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import { getStorageData } from "../../../framework/src/Utilities";
import { sendAPIRequest, urlToFile } from "../../../components/src/Utils";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { IStep } from "./LegalDataEditController.web";

export const configJSON = require("./config");

export interface Props {
    id: string;
    navigation: any
}

export interface IMedia {
    file_id: number;
    file_name: string;
    content_type: string;
    file_size: number;
    url: string;
}

export interface S {
    familyDetails: IFamilyDetails
    isEditFamily: boolean
    familyDetailsSteps: IStep[]
    successFamilyDialog: boolean
    maxFamilyMemories: number
    maxFamilyMemoriesUpload: number
    maxFestiveMemories: number
}

export interface IFavouriteMemoriesAttributes {
    title: string;
    files: File[];
};

export interface IFestiveMemoriesAttributes {
    celebration_type: string;
    files: File[];
};

export interface IFamilyDetails {
    closest_name: string;
    reason_for_special: string;
    about: string;
    have_favourite_memory: string;
    have_festive_memory: string;
    description: string;
    favourite_memories_attributes: IFavouriteMemoriesAttributes[];
    festive_memories_attributes: IFestiveMemoriesAttributes[];
};

export interface SS {

}

export default class FamilyDetailsEditController extends BlockComponent<Props,
    S,
    SS
> {
    callGetFamilyAPIID: string = ""
    callSetFamilyAPIID: string = ""
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),

        ];
        this.state = {
            familyDetails: {
                closest_name: "",
                reason_for_special: "",
                about: "",
                have_favourite_memory: "0",
                have_festive_memory: "0",
                description: "",
                favourite_memories_attributes: [{
                    title: "",
                    files: [],
                }],
                festive_memories_attributes: [{
                    celebration_type: "",
                    files: [],
                }],
            },
            isEditFamily: false,
            familyDetailsSteps: [
                { label: 'Home', path: 'HomePage' },
                { label: 'My Data', path: 'HomePage' },
                { label: 'Family Details', path: 'FamilyDetailsEdit' },
            ],
            successFamilyDialog: false,
            maxFamilyMemories: 3,
            maxFamilyMemoriesUpload: 3,
            maxFestiveMemories: 3
        }
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        runEngine.debugLog("Message Recived", message);
        const apiRequestCallId = message.getData(
            getName(MessageEnum.RestAPIResponceDataMessage)
        );
        const responseJSON = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );

        if (apiRequestCallId === this.callGetFamilyAPIID) {
            if (responseJSON.data) {
                const {
                    closest_name,
                    reason_for_special,
                    about,
                    have_favourite_memory,
                    have_festive_memory,
                    description,
                    favourite_memories,
                    festive_memories,

                } = responseJSON.data.attributes;

                let familyDetails = {
                    have_favourite_memory: this.checkValue(have_favourite_memory),
                    have_festive_memory: this.checkValue(have_festive_memory),
                    closest_name,
                    reason_for_special,
                    about,
                    description,
                    favourite_memories_attributes: this.setFavouriteMemories(favourite_memories),
                    festive_memories_attributes: this.setFestiveMemories(festive_memories),

                }

                this.setState({ familyDetails })
            }
        }
        if (apiRequestCallId === this.callSetFamilyAPIID) {
            if (responseJSON.data) {
                this.setState({ successFamilyDialog: true })
            } else {
                this.handleErrorMessage(responseJSON.errors)
            }
        }
        // Customizable Area End
    }
    handleFamilyDetailsUpload = async (event: React.ChangeEvent<HTMLInputElement>, setFieldValue:
        {
            (field: string,
                value: any,
                shouldValidate?: boolean | undefined): void;
            (arg0: string, arg1: string): void;
        },
        index: number,
        prevFiles: File[],
        section: string) => {
        if (event.target.files) {
            const hobbiesFile = Array.from(event.target.files);
            let files = [...prevFiles, ...hobbiesFile]
            let maxAllowed = section === "favourite_memories_attributes" ? this.state.maxFamilyMemoriesUpload : this.state.maxFestiveMemories
            if(files.length> maxAllowed){
                let errorMessage = configJSON.maxFileError.replace("${numberOfFile}",maxAllowed)
                toast.error(errorMessage)
                return false
            }
            setFieldValue(`${section}.${index}.files`, files)
        }
    };

    setFavouriteMemories = (favourites: IFavouriteMemoriesAttributes[]) => {
        let favouritesData = favourites?.length > 0 ?
            favourites.map((favourite: IFavouriteMemoriesAttributes) => ({
                title: favourite.title,
                files: favourite.files
            })) : [{
                title: "",
                files: [],
            }]
        return favouritesData
    }

    getMaxFavMem = (index:number, length: number) =>{
        return index===0 && length < this.state.maxFamilyMemories
    }

    setFestiveMemories = (festives: IFestiveMemoriesAttributes[]) => {
        let festivesData = festives?.length > 0 ?
            festives.map((festive: IFestiveMemoriesAttributes) => ({
                celebration_type: festive.celebration_type,
                files: festive.files
            })) : [{
                celebration_type: "",
                files: []
            }]
        return festivesData
    }
    closeSuccessFamilyDialog = () => {
        this.getFamilyDetailsData()
        this.setState({ successFamilyDialog: false, isEditFamily: false });
    }
    handleErrorMessage = (errors: { [key: string]: string }[]) => {
        if (errors.length > 0) {
            errors.forEach(errorObj => {
                Object.entries(errorObj).forEach(([key, message]) => {
                    let errorMessage = key === "token" ? message : key.replace("_", " ") + " " + message
                    toast.error(errorMessage)
                });
            });
        }
    }

    setEditForm = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.preventDefault()
        this.setState((prevState) => ({
            isEditFamily: true,
            familyDetailsSteps: [...prevState.familyDetailsSteps, { label: 'Edit Details', path: 'FamilyDetailsEdit' }],
        }))
    }

    async componentDidMount() {
        super.componentDidMount()
        this.getFamilyDetailsData()
        this.setMaxDocumentUpload()
    }

    setMaxDocumentUpload = async() =>{
        let subscriptionData = await getStorageData("active_subscription");
        let parsedSubscriptionData = JSON.parse(subscriptionData)?.features
        if(parsedSubscriptionData){
        this.setState({maxFamilyMemories: parseInt(parsedSubscriptionData.max_memories),
            maxFamilyMemoriesUpload: parseInt(parsedSubscriptionData.max_memory_uploads),
            maxFestiveMemories: parseInt(parsedSubscriptionData.max_festival_memories)})
            }
    }

    checkValue = (value: string) => {
        return value === "Yes" ? '1' : '0'
    }

    getFamilyDetailsData = async () => {
        const token = await getStorageData("token");

        this.callGetFamilyAPIID = sendAPIRequest(
            configJSON.getFamilyDetailsEndPoint,
            {
                method: configJSON.validationApiMethodType,
                headers: {
                    token,
                },
            }
        );
    }

    getClassName = () => {
        return this.state.isEditFamily ? "formSectionBackground" : ""
    }

    getVaraintName = () => {
        return this.state.isEditFamily ? "h3" : "h2"
    }

    getTitle = () => {
        return !this.state.isEditFamily ? "Media" : ""
    }

    getFileLength = (length: number) => {
        return !this.state.isEditFamily && length === 0
    }

    validateFamilyDetails = () => {
        if (this.state.isEditFamily) {
            return (
                Yup.object().shape({
                    favourite_memories_attributes: Yup.array().when(['have_favourite_memory'], {
                        is: (have_favourite_memory) => have_favourite_memory === "1",
                        then: Yup.array().of(Yup.object().shape({
                            title: Yup.string().nullable().required("Please enter memory title"),
                        })),
                        otherwise: Yup.array().of(Yup.object().nullable()),
                    }),
                    festive_memories_attributes: Yup.array().when(['have_festive_memory'], {
                        is: (have_festive_memory) => have_festive_memory === "1",
                        then: Yup.array().of(Yup.object().shape({
                            celebration_type: Yup.string().nullable().required("Please enter celebration type"),
                        })),
                        otherwise: Yup.array().of(Yup.object().nullable()),
                    }),
                })
            )
        } else {
            return Yup.object().shape({});
        }
    }

    appendFormDataFiles = async (formData: FormData, section: string, stateData: IFavouriteMemoriesAttributes[] | IFestiveMemoriesAttributes[]) => {
        for (const data of stateData) {
            for (const [keyName, value] of Object.entries(data)) {
                if (value) {
                    if (keyName === 'files' && data.files?.length > 0) {
                        for (const file of data.files) {
                            const blobFile = await this.convertToBlobFile(file as File | IMedia);
                            formData.append(`${section}[][${keyName}][]`, blobFile);
                        }
                    } else {
                        formData.append(`${section}[][${keyName}]`, value as string);
                    }
                }
            }
        }
    };

    handleFamilDetailsForm = async (values: IFamilyDetails) => {
        if (this.state.isEditFamily) {
            const token = await getStorageData("token");
            const formData = new FormData();
            Object.entries(values).forEach(([keyName, value]) => {
                if (keyName !== "favourite_memories_attributes" && keyName !== "festive_memories_attributes") {
                    formData.append(`family[${keyName}]`, value);
                }
            });

            await this.appendFormDataFiles(formData, "family[favourite_memories_attributes]", values.favourite_memories_attributes)
            await this.appendFormDataFiles(formData, "family[festive_memories_attributes]", values.festive_memories_attributes)

            this.callSetFamilyAPIID = sendAPIRequest(
                configJSON.getFamilyDetailsEndPoint,
                {
                    method: configJSON.exampleAPiMethod,
                    headers: {
                        token,
                    },
                    body: formData,
                }
            );
        }
    }

    convertToBlobFile = async (file: File | IMedia) => {
        if ('content_type' in file) {
            return await urlToFile(file.url, file.file_name);
        } else {
            return file as Blob;
        }
    };


    handleNavigation = (route: string) => {
        const message = new Message(getName(MessageEnum.NavigationMessage));
        message.addData(getName(MessageEnum.NavigationTargetMessage), route);
        message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(message);
    };

}