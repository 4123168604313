import React from "react";
// Customizable Area Start
import AboutPhysicalEditController, {
    configJSON,
    Props,
    ITattoo
} from "./AboutPhysicalEditController.web";
import {
    Box,
    Grid,
    Typography,
    Button,
    FormLabel,
    Switch,
} from "@material-ui/core";
import { Field, FieldArray, Form, Formik } from "formik";
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import CustomInput from "../../../components/src/CustomInput";
import ErrorMessage from "../../../components/src/ErrorMessage";
import MediaUpload from "../../../components/src/MediaUpload.web";

// Customizable Area End

export default class AboutPhysicalEdit extends AboutPhysicalEditController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        // Customizable Area Start
        const {
            physical,
            isEdit,
        } = this.state;
        return (
            <Box className="formWrapper">
                <Formik
                    initialValues={physical}
                    validationSchema={this.validationSchema}
                    onSubmit={(values) => {
                        this.handleAboutPhysicalDataFormSubmit(values)
                    }}
                    enableReinitialize={true}
                    data-test-id="AboutPhysicalForm"
                >
                    {({ values, setFieldValue }) => (
                        <Form>
                            <Box className="formSectionBackground">
                                <Grid container spacing={3}>
                                    <Grid item xs={12} className="fieldArrayHeaderWrapper">
                                        <Typography data-test-id="fieldArrayTitle" variant={this.state.isEdit ? "h3" : "h2"}>{configJSON.physicalHeading}</Typography>
                                    </Grid>
                                    <Grid item lg={3} md={4} xs={12} sm={6}>
                                        <FormLabel component="label">
                                            {configJSON.height}
                                        </FormLabel>
                                        <Grid container spacing={2}>
                                            <Grid item xs={7} >
                                                <Field
                                                    className="inputField"
                                                    data-test-id="heightFt"
                                                    name="height"
                                                    as={CustomInput}
                                                    disabled={!isEdit}
                                                    size="small"
                                                    placeholder="Ft"
                                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                        setFieldValue(`height_in_feet`, e.target.value)
                                                    }}
                                                    value={values.height_in_feet}
                                                />
                                                <ErrorMessage name={`height_in_feet`} />
                                            </Grid>
                                            <Grid item xs={5} >
                                                <Field
                                                    className="inputField"
                                                    data-test-id="heightInch"
                                                    name="inch"
                                                    disabled={!isEdit}
                                                    as={CustomInput}
                                                    size="small"
                                                    placeholder="Inch"
                                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                        setFieldValue(`height_in_inch`, e.target.value)
                                                    }}
                                                    value={values.height_in_inch}
                                                />
                                                <ErrorMessage name={`height_in_inch`} />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item lg={3} md={4} xs={12} sm={6}>
                                        <FormLabel component="label">
                                            {configJSON.eyeColor}
                                        </FormLabel>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} >
                                                <Field
                                                    className="inputField"
                                                    data-test-id="eyeColor"
                                                    name="eyeColour"
                                                    disabled={!isEdit}
                                                    as={CustomInput}
                                                    size="small"
                                                    placeholder="Enter your eye colour"
                                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                        setFieldValue(`eye_colour`, e.target.value)
                                                    }}
                                                    value={values.eye_colour}
                                                />
                                                <ErrorMessage name={`eye_colour`} />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item lg={3} md={4} xs={12} sm={6}>
                                        <FormLabel component="label">
                                            {configJSON.hairColor}
                                        </FormLabel>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} >
                                                <Field
                                                    data-test-id="hairColor"
                                                    className="inputField"
                                                    disabled={!isEdit}
                                                    name="hairColour"
                                                    as={CustomInput}
                                                    size="small"
                                                    placeholder="Enter your hair colour"
                                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                        setFieldValue(`hair_colour`, e.target.value)
                                                    }}
                                                    value={values.hair_colour}
                                                />
                                                <ErrorMessage name={`hair_colour`} />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item lg={3} md={4} xs={12} sm={6}>
                                        <FormLabel component="label">
                                            {configJSON.visible}
                                        </FormLabel>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} >
                                                <Field
                                                    data-test-id="visibleMark"
                                                    className="inputField"
                                                    name="visibleMark"
                                                    disabled={!isEdit}
                                                    as={CustomInput}
                                                    size="small"
                                                    placeholder="Example - Birthmark"
                                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                        setFieldValue(`visible_mark`, e.target.value)
                                                    }}
                                                    value={values.visible_mark}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Box className="haveSpecialDayWrapper">
                                            <Typography align="center" className="haveSpecialDayLabel">
                                                {configJSON.tattoAllowed}
                                            </Typography>
                                            <Switch
                                                data-test-id="haveTattoo"
                                                color="primary"
                                                disabled={!isEdit}
                                                checked={values.have_any_tattoo}
                                                onChange={(event: any) =>
                                                    this.handleSwitchChange(event.target.checked, setFieldValue)
                                                }
                                                inputProps={{ 'aria-label': 'controlled' }}
                                            />
                                        </Box>
                                    </Grid>
                                </Grid>
                                {
                                    values.have_any_tattoo && <FieldArray name="tattoo_details_attributes" data-test-id="fieldArray">
                                        {({ remove, push }) => (
                                            values.tattoo_details_attributes.map((_values: ITattoo, index: number) => {
                                                return (
                                                    <Grid container spacing={3}>
                                                        <Grid item xs={12} className="fieldArrayHeaderWrapper">
                                                            <Typography data-test-id="fieldArrayTitle" variant={this.state.isEdit ? "h3" : "h2"}>{configJSON.tattooDetails}</Typography>
                                                            {(index === 0 && isEdit) &&
                                                                <Button
                                                                    className="addLettersBtn"
                                                                    data-test-id="addTattooBtn"
                                                                    onClick={() => push({ body_part: '', files: [] })}
                                                                >
                                                                    <AddCircleOutlineIcon className="circleOutlineIcon" />
                                                                    {configJSON.addMoreTattoo}
                                                                </Button>
                                                            }
                                                            {(index !== 0 && isEdit) && (       
                                                                <Button
                                                                    data-test-id="removeBtn"
                                                                    className="addLettersBtn"
                                                                    onClick={() => remove(index)}>
                                                                    <RemoveCircleOutlineIcon className="circleOutlineIcon" />
                                                                    {configJSON.removeBtnText}
                                                                </Button>
                                                            )}
                                                        </Grid>
                                                        <Grid item lg={3} md={4} xs={12} sm={6}>
                                                            <FormLabel component="label">
                                                                {configJSON.bodyPart}<span style={{ color: "red" }}>*</span>
                                                            </FormLabel>
                                                            <Grid container spacing={2}>
                                                                <Grid item xs={12} >
                                                                    <Field
                                                                        data-test-id="bodyPart"
                                                                        className="inputField"
                                                                        name="visibleMark"
                                                                        as={CustomInput}
                                                                        disabled={!isEdit}
                                                                        size="small"
                                                                        placeholder="Enter body part name"
                                                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                                            setFieldValue(`tattoo_details_attributes.${index}.body_part`, e.target.value)
                                                                        }}
                                                                        value={_values.body_part}
                                                                    />
                                                                    <ErrorMessage name={`tattoo_details_attributes.${index}.body_part`} />
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <MediaUpload
                                                                data-test-id="mediaUploadForTattoo"
                                                                files={_values.files}
                                                                readonly={!isEdit}
                                                                title={!isEdit ? "Media" : ""}
                                                                onUpload={(event: React.ChangeEvent<HTMLInputElement>) => this.handleFileUpload(event, setFieldValue, index, _values.files)}
                                                            />
                                                            {!isEdit && _values.files.length === 0 && (
                                                                <Box>
                                                                    <Typography variant="subtitle1" className="dataNotAvailable">
                                                                        {configJSON.NoMediaFound}
                                                                    </Typography>
                                                                </Box>
                                                            )}
                                                        </Grid>
                                                    </Grid>
                                                )
                                            })
                                        )}
                                    </FieldArray>
                                }
                            </Box>
                            <Box className="btnWrapper">
                                <Button
                                    variant="outlined"
                                    className="btnSize"
                                    onClick={() => this.props.handleNext("CustomForm")}
                                    data-test-id="backBtnPhysical"
                                >
                                    {configJSON.backBtnText}
                                </Button>
                                <Button
                                    className="btnSize"
                                    variant="contained"
                                    data-test-id="saveAbout"
                                    type="submit"
                                >
                                    {isEdit ? configJSON.saveChangesBtnText : configJSON.editDetailsBtnText}
                                </Button>
                            </Box>
                        </Form>
                    )}
                </Formik>
            </Box>
        );
        // Customizable Area End
    }
}


// Customizable Area Start
// Customizable Area End