Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "NavigationMenu";
exports.labelBodyText = "NavigationMenu Body";

exports.btnExampleTitle = "CLICK ME";
exports.profileCreationReason = "Profile Creation Reason";
exports.addyYourPartnerTitle = "Add your partner as Delegate Member 1";
exports.healthAndFitness = "Health and Fitness";
exports.areYouFitAndWell = "Are you currently fit and well?";
exports.issueFieldLabel = "Issue";
exports.partnerDetails = "Partnership Details";
exports.partnershipStatusFieldLabel = "Partnership Status";
exports.reasonFieldLabel = "Reason";
exports.addressTitle = "This address is where we will send your Delegate Mee user card";
exports.firstNameLabel = "First Name";
exports.middleNameLabel = "Middle Name (optional)";
exports.lastNameLabel = "Last Name";
exports.nationality = "Nationality :";
exports.nationaliti = "Nationality";
exports.genderLabel = "Gender";
exports.genderNameLabel = "Enter gender";
exports.addressLine1 = "Address line 1";
exports.addressLine2 = "Address line 2";
exports.landmarkLabel = "Landmark (if required)";
exports.townCityLabel = "Town/City";
exports.countryLabel = "Country";
exports.postCodeLabel = "Post Code";
exports.emailIdLabel = "Email ID";
exports.phoneNumber = "Phone Number";
exports.dateOfBirthLabel = "DOB";
exports.ageLabel = "Age";
exports.gender = "Gender :";
exports.emailAddess = "Email Address :";
exports.phoneNo = "Phone Number :";
exports.dateBirth = "Date of Birth :";
exports.age = "Age :";
exports.address = "Address :";
exports.DelegatedMemberone ="Delegated Member 1";
exports.DelegatedMembertwo ="Delegated Member 2";
exports.changePasswordBtnText = "Change Password ";
exports.editProfileBtn = "Edit profile ";
exports.editDelegateBtn = "Edit Delegate ";
exports.profileCreationResion = "Reason :";
exports.healthFitness = "Issue :";
exports.partnershipStatus = "Partnership Status :";
exports.partnershipFullname = "Full Name :";
exports.middelName = "Middle Name :";
exports.lastName = "Last Name :";
exports.openDrawerText = "Open Drawer";
exports.userName = "Jordan Ramsay";
exports.userDesignation = "Miami Herica";
exports.dialogMessageText = "Your changes have been saved successfully!";
exports.logout = "Logout";
exports.cancelBtnText = "Cancel";
exports.saveChangesProfileBtnText = "Save Changes";
exports.jsonApiContentType = "application/json";
exports.getMenuItemsEndPoint = "navmenu/navigation_menu";
exports.getApiMethodType = "GET";
exports.httpGetMethod = "GET";
exports.httpPostMethod = "POST";
exports.httpPutMethod = "PUT";
exports.loginAPIEndPoint = "bx_block_login/logins";
exports.getNationalitiesApiEndPoint = 
"bx_block_customisableuserprofiles/nationalities";
exports.getLoggedInUserDetailsApiEndPoint = "account_block/accounts/logged_user";
exports.getUpsertUserProfileApiEndPoint = "bx_block_customisableuserprofiles/user_profiles";
exports.updateCustomizableProfileEndPoint = "bx_block_profile/profile/update_user_profile";
exports.getCustomizableProfileFieldsEndPoint ="bx_block_profile/profile/custom_user_profile_fields";
exports.getCustomizableProfileEndPoint = "bx_block_profile/profile/current_user_profile";
exports.getTransactionHistoryApiEndPoint = "bx_block_menu_ordering/transaction_histories";

//shop page
exports.pageTitleText = "Shop";

//show product page
exports.popularProductsText = "Popular Products";
exports.buyNowBtnText = "Buy Now";
exports.shopBtn = "Shop";
exports.getDelegateMembersApiEndPoint = 
"bx_block_customisableuserprofiles/delegate_members";
exports.addDelegateMemberApiEndPoint = 
"bx_block_customisableuserprofiles/delegate_members";
exports.editDelegateMemberApiEndPoint = 
"bx_block_customisableuserprofiles/delegate_members";
exports.confirmDetailsApiEndPoint = 
"bx_block_customisableuserprofiles/delegate_members/confirm";
exports.getNationalitiesApiEndPoint = 
"bx_block_customisableuserprofiles/nationalities";


// Change Password
exports.changePasswordTitle = "Change Password";
exports.currentPasswordFieldLabel = "Current Password";
exports.newPasswordFieldLabel = "New Password";
exports.confirmPasswordFieldLabel = "Confirm Password";
exports.changePasswordBtnText = "Change Password";


exports.paymentType = "Payment Type : ";
exports.plan = "Plan : ";
exports.planPaymentDate = "Payment Date : ";
exports.planAmountPaid  = "Amount Paid : ";
exports.planTransactionID  = "Transaction ID : ";

// Delegate Member 
exports.changePicture = "Change Picture";
exports.removePicture = "Remove Picture";
exports.delegateMemberAddressDescription = 
"This address must be for your Delegated Member. We will post them a personal access card to keep safe. Don't worry, they still will not be able to access your account useless we have proof of death";
exports.securityPhraseLabel = "Security Phrase";
exports.nextBtnText = "Next";
exports.securityPhraseInfo = 
"DON NOT SHARE this with anyone. Your security phrase will be ‘foiled’ behind scratch panel on your delegates security card. This is all part of our security/retrieval process.";
exports.addDelegateMemberTitle = "Delegated Member";
exports.delegateMemberInfo = "Delegate Users are the only people who can access your information in case something happens to you.";
exports.delegateMemberSubtitle = 
"Delegated Members will be sent access cards. We will also make them aware of your profile creation. Adding two Delegated Members is mandatory.";

exports.delegateUser = "Delegate User";
exports.confirmDetailsBtnText = "Confirm Details";

exports.youAreAllSet = "You are all Set!";
exports.youAreAllSetDescription = "Before getting started, please select the desired subscription plan to start using the Delegate Mee app.";
exports.continueBtnText = "Continue";

exports.getDelegateMembersApiEndPoint = 
"bx_block_customisableuserprofiles/delegate_members";
exports.addDelegateMemberApiEndPoint = 
"bx_block_customisableuserprofiles/delegate_members";
exports.confirmDetailsApiEndPoint = 
"bx_block_customisableuserprofiles/delegate_members/confirm";
exports.getNationalitiesApiEndPoint = 
"bx_block_customisableuserprofiles/nationalities";
exports.getLoggedInUserDetailsApiEndPoint = 
"account_block/accounts/logged_user";

exports.getApiMethodType = "GET";
exports.deleteApiMethodType = "DELETE";

// Subscription
exports.getSubscriptionPlanApiEndPoint = "bx_block_custom_user_subs/user_subscriptions/active_plan";
exports.cancelSubscriptionPlanApiEndPoint = "bx_block_custom_user_subs/user_subscriptions/";
exports.getPreviousSubscriptionPlanApiEndPoint = "bx_block_custom_user_subs/user_subscriptions";

exports.yourSubscriptionTitle = "Your Subscription";
exports.activeTagText = "ACTIVE";
exports.cancelledTagText = "CANCELLED";
exports.subscriptionPlanLabel = "Subscription Plan :";
exports.paymentFrequencyLabel = "Payment Frequency :";

exports.PaymentMethodTitle = "Payment Method";
exports.methodLabel = "Method :";
exports.stripeText = "Stripe";
exports.nextBillingDateLabel = "Next Billing Date :";

exports.upgradePlanTitle = "Upgrade your plan";
exports.upgradePlanDescription = "To enjoy more benefits of DelegateMe, upgrade your plan. More data can be saved along with increased memories upload, family, friends and much more.";
exports.upgradePlanBtnText = "Upgrade Plan";
exports.cancelPlanBtnText = "Cancel Subscription";

exports.planBenefitsTitle = "Plan Benefits";
exports.moreBenefits = "+6 more benefits";
exports.learnMoreBtnText = "Learn more";
// Customizable Area End